import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/clientMob.css"
import PopupClient from '../PC/PopupClient'
import { UserContext } from '../UserContext';


const ClientMob = (props) => {
	const [selectedClientPopupMob] = useState(null)
	const [clientPopUP, setClientPopup] = useState(false);
	const [clientPopUPData, setClientPopupData] = useState();


	const [client, setClient] = useState(UserContext)
	const [clientData, setClientData] = useState([])

	useEffect(() => {
		if (client) {
			GetClient()
		}
	}, [client, clientPopUP, selectedClientPopupMob,props.isAddPopupVisibleMob])

	const GetClient = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/client/getClient";
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setClientData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}



	return (
		<div id="client_page_mob">
			<img id="over_all_bg_client_page_mob" src="over_all_bg_client_page_mob.png" srcset="over_all_bg_client_page_mob.png 1x" />

			<div id="client_page_scroll">
			{clientData.filter(rw=> (rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.companyInfo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.gstNo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.country.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.city.toLowerCase().includes(props.searchText.toLowerCase()) || rw.state.toLowerCase().includes(props.searchText.toLowerCase())  || rw.contactPersonName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
					<div style={{ position: "relative", display: 'inline-flex' }}>
						<div id="user_card_client_page_mob" onClick={() => { setClientPopup(true); setClientPopupData(rec) }}>


							<svg class="card_over_all_bg_client_page_m">
								<rect id="card_over_all_bg_client_page_m" rx="10" ry="10" x="0" y="0" width="93vw" height="103">
								</rect>
							</svg>
							<img id="card_bg_design_client_page_mob" src="card_bg_design_client_page_mob.png" srcset="card_bg_design_client_page_mob.png 1x" />
							<svg class="pfp_behind_circle_client_page_" style={{ fill: rec.active === true ? "rgba(119,238,170,1)" : "White" }}>
								<ellipse id="pfp_behind_circle_client_page_" rx="45" ry="45" cx="45" cy="45">
								</ellipse>
							</svg>
							<img id="profile_pic_card_client_page_m" src={
									rec.profilePicUrl && rec.profilePicUrl.length
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								} />

							<div id="Mohammed_Affan_cient_page_mob">
								<span>{rec.companyName}</span>
							</div>
							<img title="Compant Name" id="manager_lbl_client_mob_page" src="manager_lbl_client_page.png" srcset="manager_lbl_client_page.png 1x" />

							<div id="address_lbl_client_page_mob">
								<span>{rec.city},{rec.country}</span>
							</div>
						</div>
					</div>
				))}

			</div>
			<PopupClient isOpen={props.isAddPopupVisibleMob && selectedClientPopupMob === null} closePopup4={() => { props.closeAddPopupMob(false) }} />
			<PopupClient isOpen={clientPopUP} data={clientPopUPData} closePopup4={(value) => { setClientPopup(value) }} />
		</div>
	)
}

export default ClientMob