import React ,{useContext,useEffect,useState} from 'react'
import "../../Styles/Mob/taskBarChartMob.css"
import { UserContext } from '../UserContext'
import PopUpTask from '../PC/PopUpTask';


const TaskBarChartMob = (props) => {

	const { user } = useContext(UserContext);
	const [displayTask, setDisplayTask] = useState("none")

    useEffect(()=>{

    },[displayTask])

  return (
    <div id='task_bar_graph_chart'>
        <svg class="task_1_box_dash_mob">
                    <rect id="task_1_box_dash_mob" rx="0" ry="0" x="0" y="0" width="17" height={((parseInt(props.data.plannedHours || 0))/parseInt(props.maxHours))*150}>
                    </rect>
                </svg>
               
            <button onClick={() => { if ((user.usertype === "staff" && user.useraccess >= 5) || props.data.assignedToId === user._id || (user && user.usertype === "staff" && user.useraccess >= 3 && (props.teamData.filter(rw => rw.staffUserId === user._id).length) > 0)) { setDisplayTask("Edit") } else alert("Access Denied") }} >

        <marquee scrollamount="3" id="Task_Name1_1_dash_mob">
                    <span>{props.data.task}</span>
                </marquee>
                </button>
                <svg class="blue_1_client_dash_mob" style={{ fill:props.data.status === "Unassigned" ? "#999999" :props.data.status === "Assigned" ? "#FFCC00" : props.data.status === "Work in Progress" ? "#3399FF" : props.data.status === "On Hold" ? "#FF9900" : props.data.status === "Completed" ? "#00CC66" : props.data.status === "Cancelled" ? "#FF0000" : "" ,bottom:"0px"}}>
                    <rect id="blue_1_client_dash_mob" rx="0" ry="0" x="0" y="0" width="17" height={((parseInt(props.data.billedHours || 0))/parseInt(props.maxHours))*150}>
                    </rect>
                </svg>
                <div id="n__blue_client_dash_mob" style={{top:(147 - ((parseInt(props.data.billedHours || 0))/parseInt(props.maxHours))*150) +"px"}}>
                    <span>{props.data.billedHours}</span>
                </div>
                <div id="n__purple_1_client_dash_mob"  style={{top:(147 - ((parseInt(props.data.plannedHours || 0))/parseInt(props.maxHours))*150) + "px"}}>
                    <span>{props.data.plannedHours}</span>
                </div>
			<PopUpTask display={displayTask} data={props.data} teamData={props.teamData} closePopup={() => {setDisplayTask("none");props.refresh()}} project={props.project} />

    </div>
  )
}

export default TaskBarChartMob