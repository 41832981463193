import React, { useContext } from 'react'
import "../../Styles/PC/nav.css"
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext'


const Navbar = (props) => {

	const { user, setUser } = useContext(UserContext);

	const handlelogout = () => {
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	}


	return (
		<div>

			<div>
				
				<div id="small_nav_left_project" className='slide-right' >
				
					<div id="Group_2"  >
						<svg className="side_nav_icon_border" >
							<rect id="side_nav_icon_border" rx="28" ry="28" x="0" y="0" width="112" height="87vh"  onClick={() => props.onFormSwitch('navext')}>
							</rect>
						</svg>
						<div id="over_all_grp" >
				
							<img id="side_logo_icon" src={
									user.imageId && user.imageId.length > 10
										? "https://drive.google.com/uc?export=view&id=" + user.imageId
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }}  />
							
							<Link to={"/dashboard"}>
								<div id="dash_bdr_grp_client" className='highlight'  title='DashBoard'>
									{/* <svg className="dash_bdr">
								<rect id="dash_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
								</rect>
							</svg> */}
									<img id="dashboard_Icon_I_client" src="dashboard_icon_client.png" srcset="dashboard_icon_client.png 1x" />

								</div>
							</Link>

							<Link to={"/timesheet"}>
								<div id="time_line_" className='highlight'  title='Time Sheet'>

									<img id="time_line_icon" src="time_line_icon.png" srcset="time_line_icon.png 1x" />

								</div>
							</Link>
							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 
							<Link to={"/companies"}>
								<div id="dash_bdr_grp" className='highlight'  title='Companies'>
									{/* <svg className="dash_bdr">
								<rect id="dash_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
								</rect>
							</svg> */}
									<img id="dashboard_Icon_I" src="dashboard_Icon_I.png" srcset="dashboard_Icon_I.png 1x" />

								</div>
							</Link>
							:""}
							

							<Link to={"/projects"}>
								<div id="com_grp" className='highlight' title='Project'>

									<img id="community_icon_I" src="community_icon_I.png" srcset="community_icon_I.png 1x" />

								</div>
							</Link>

							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 
							<Link to={"/clients"}>
								<div id="connection_grp_client" className='highlight' title='Clients '>

									<img id="my_connections_icon_I_client" src="clientstaff.png" srcset="clientstaff.png 1x" />

								</div>
							</Link>
							:""}
							<Link to={"/staff"}>
								<div id="connection_grp" className='highlight' title='Staff'>

									<img id="my_connections_icon_I" src="my_connections_icon_I.png" srcset="my_connections_icon_I.png 1x" />

								</div>
							</Link>
							
							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 
							<Link to={"/users"}>
								<div id="offering_grp" className='highlight'  title='Users'>

									<img id="offerings_icon_I" src="offerings_icon_I.png" srcset="offerings_icon_I.png 1x" />

								</div>
							</Link>
							:""}
							<div id="logout_grp" onClick={() => handlelogout()}  title='Log Out'>
								<img id="logout_icon_I" src="logout_icon_I.png" srcset="logout_icon_I.png 1x" />

							</div>
							
							

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar