import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Mob/timesheetMob.css"
import TimeSheetCardMob from './TimeSheetCardMob'
import BilledHoursCardMob from './BilledHoursCardMob'
import { UserContext } from '../UserContext'
import TimeSheetPopup from './TimeSheetPopup'


const TimeSheetMob = (props) => {

    const [timeSheetDates, setTimeSheetDates] = useState([])
    const [showCards, setShowCards] = useState([])
    const [activeStaffData, setActiveStaffData] = useState([])
    const { user } = useContext(UserContext);

    const [timeSheetData, setTimeSheetData] = useState([])
    // const [addTimeSheet, setAddTimeSheet] = useState(false)




    useEffect(() => {
        if (timeSheetData) {
            const unique = arr => arr.map(row => row.taskDate);
            const dates = [...new Set(unique(timeSheetData))]
            setTimeSheetDates(dates)
            var visibleCards = timeSheetData.map((cr, idx) => { return { idx, visible: true } })
            setShowCards(visibleCards)
        }
    }, [timeSheetData])

    useEffect(() => {
        // console.log(timeSheetDates)
    }, [timeSheetDates, showCards,props.isAddPopupVisibleMob])

    useEffect(() => {

        if (user) {
            GetActiveStaff()
        }
    }, [user])

    useEffect(() => {
        if (user) {

            GetTimeSheet()
        }
    }, [user])

    const GetTimeSheet = () => {
        if (user) {
            let apiURL = process.env.REACT_APP_API_URL + "/timesheet" + ((user && user.usertype === "staff") ? "/getTimeSheet" : (user && user.usertype === "client") ? "/getTimeSheetByProject/" + user._id : "");
            let apiParams = {
                method: "GET",

            };

            fetch(apiURL, apiParams)
                .then((res) => (res.status === 200 ? res.json() : res.text()))
                .then((repos) => {
                    if (repos.data) {

                        setTimeSheetData(repos.data)

                    } else alert(repos.message || repos);
                })
                .catch((err) => alert(err));

        }
    }

    const GetActiveStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/staff/getActiveStaff";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setActiveStaffData(repos.data)

                    // if (repos.data[0]) {
                    //     setStaffUserId(repos.data[0]._id)
                    // }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }






    let sortedDates = timeSheetData.sort((a, b) => Date.parse(b.taskDate) - Date.parse(a.taskDate));
    console.log(sortedDates)
    // let sortedCars1 = timeSheetDates.sort((a, b) => Date.parse(a.taskDate) - new Date(b.taskDate));
    // console.log(sortedCars1)
    // console.log(timeSheetDates)

    var collapseDate = (idx) => {
        var cards = showCards;
        cards[idx].visible = !cards[idx].visible;
        setShowCards([...cards])
    }
    // let sortedCars2 = cars.sort((a, b) => new Date(a.initialRegistration).getTime() - new Date(b.initialRegistration).getTime());


    return (
        <div id="time_sheet_page">
            <img id="bg_design_mob_mob_1" src="bg_design_mob_mob_1.png" srcset="bg_design_mob_mob_1.png 1x" />
            <div id="timesheet_scroll">
                {timeSheetDates.map((recDate, idx) =>
                    // <div style={{ display: "block", textAlign: "center",position:"relative" }}>

                    <div id="card_1_area_mob">
                        <div id="top_mob_1">
                            <div id="n_lbl_mob_1">
                                <span>{recDate.toString().slice(0, 10)}</span>
                            </div>
                            <img id="rightmob_1drop_btn_" src={showCards[idx].visible === true ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => collapseDate(idx)} />

                            <svg class="long_line_mob_1" viewBox="0 0 220.611 1">
                                <path id="long_line_mob_1" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="left_ar_mob_1" viewBox="0 0 14 1">
                                <path id="left_ar_mob_1" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_mob">
                            {/* <BilledHoursCardMob/> */}
                            {/* <TimeSheetCardMob/> */}
                            {/* && rw.project.toLowerCase().includes(props.searchText.toLowerCase()) || rw.task.toLowerCase().includes(props.searchText.toLowerCase()) || rw.module.toLowerCase().includes(props.searchText.toLowerCase()) || rw.category.toLowerCase().includes(props.searchText.toLowerCase()) || rw.billedToName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.status.toLowerCase().includes(props.searchText.toLowerCase()) */}
                            {/* timeSheetData.filter(rw=> (rw.project.toLowerCase().includes(props.searchText.toLowerCase()) || rw.task.toLowerCase().includes(props.searchText.toLowerCase()) || rw.module.toLowerCase().includes(props.searchText.toLowerCase()) || rw.category.toLowerCase().includes(props.searchText.toLowerCase()) || rw.billedToName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.status.toLowerCase().includes(props.searchText.toLowerCase()))) */}
                            {console.log(timeSheetData.filter(rw =>(rw.taskDate === recDate)))}
                            <div id="billedcard_timesheetcard_">
                                {showCards[idx].visible ? <>
                                    {user && activeStaffData && <BilledHoursCardMob billedHours={activeStaffData.map(stf => { return { name: stf.name, hours: timeSheetData.filter(bill => stf._id === bill.billedToId && bill.taskDate.substring(0, 10) === recDate.toString().slice(0, 10)).reduce((a, b) => a + b.billedHours, 0) } })} />}
                                    {timeSheetData.filter(dt =>(dt.taskDate === recDate)).filter(rw =>(rw.project.toLowerCase().includes(props.searchText.toLowerCase()) || rw.task.toLowerCase().includes(props.searchText.toLowerCase()) || rw.module.toLowerCase().includes(props.searchText.toLowerCase()) || rw.category.toLowerCase().includes(props.searchText.toLowerCase()) || rw.billedToName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.status.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) =>
                                        <TimeSheetCardMob data={rec} />
                                    )}
                                </>
                                    : <div style={{ height: "30px" }}></div>}
                            </div>
                        </div>
                    </div>
                    // </div>
                )}
            </div>
            <TimeSheetPopup isOpen={props.isAddPopupVisibleMob} closePopup={()=> props.closeAddPopupMob()} getTimesheet= {()=> GetTimeSheet()}/>
        </div>

    )
}

export default TimeSheetMob