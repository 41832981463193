import React,{useContext} from 'react'
import "../../Styles/Mob/menuMob.css"
import { Link,useLocation } from 'react-router-dom'
import { UserContext } from '../UserContext'


const MenuMob = () => {

  const { user, setUser } = useContext(UserContext);

	let location = useLocation()

    const handlelogout = () => {
        localStorage.clear();
        setUser(null);
        window.location.href = "/";
      }

    return (
        <div id="menu">
            <div id="bottom_menu_mon_project_assign">
                <svg class="bg_of_menu">
                    <rect id="bg_of_menu" rx="22.5" ry="22.5" x="0" y="0" width="100vw" height="45">
                    </rect>
                </svg>
                {/* <svg class="highlight_blue_icon_">
                    <rect id="highlight_blue_icon_" rx="22.5" ry="22.5" x="0" y="0" width="84" height="45">
                    </rect>
                    
                </svg> */}

                <Link to={"/dashboardmob"} className='highlight_blue_icon_' style={{backgroundColor:location.pathname === "/dashboardmob" ? "rgba(162,202,255,1)" : "" }}>
                    <img id="dashboardmob_img" src="dashboard_icon_client.png" srcset="dashboard_icon_client.png 1x" />
                </Link>
                <Link to={"/timesheetmob"} className='highlight_blue_icon_' style={{backgroundColor:location.pathname === "/timesheetmob" ? "rgba(162,202,255,1)" : "" }}>
                <img id="timeline_btn_img" src="timeline_btn_img.png" srcset="timeline_btn_img.png 1x" />
                </Link>
                
                <Link to={"/projectmob"} className='highlight_blue_icon_' style={{backgroundColor:location.pathname === "/projectmob" ? "rgba(162,202,255,1)" : "" }}>
                <img id="project_page_icon" src="project_page_icon.png" srcset="project_page_icon.png 1x" />
                </Link>

                <Link to={"/staffmob"} className='highlight_blue_icon_' style={{backgroundColor:location.pathname === "/staffmob" ? "rgba(162,202,255,1)" : "" }}>
                <img id="staff_page_icon" src="staff_page_icon.png" srcset="staff_page_icon.png 1x" />
                </Link>

                <div className='highlight_blue_icon_'>
                <img id="logout_icon_I_mob_new" src="logout_icon_I.png" srcset="logout_icon_I.png 1x" onClick={() => handlelogout()} title='Log Out' />
                </div>
               
            </div>
        </div>
    )
}

export default MenuMob