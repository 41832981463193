import React, { useState, useContext } from 'react'
import "../../Styles/PC/taskBarChart.css"
import PopUpTask from './PopUpTask'
import { UserContext } from '../UserContext'



const TaskBarChart = (props) => {

	const { user } = useContext(UserContext);
	const [displayTask, setDisplayTask] = useState("none")
	// const[showData,setShowData]=useState({})
	return (
		<div id="Column_Chart_nubers_grp">
			{/* {console.log(props.data)} */}
			<svg class="task_1_box" >
				<rect id="task_1_box" rx="0" ry="0" x="0" y="0" width="25" height={((parseInt(props.data.plannedHours || 0)) / parseInt(props.maxHours)) * 200}>
				</rect>
			</svg>
			<button onClick={() => { if ((user.usertype === "staff" && user.useraccess >= 5) || props.data.assignedToId === user._id || (user && user.usertype === "staff" && user.useraccess >= 3 && (props.teamData.filter(rw => rw.staffUserId === user._id).length) > 0)) { setDisplayTask("Edit") } else alert("Access Denied") }} >
				<marquee scrollamount="3" id="Task_Name1_1">
					<span>{props.data.task}</span>
				</marquee>
				{/* {console.log (( ((parseInt(props.data.billedHours || 0)) / parseInt(props.maxHours)) * 200) + "px" )} */}
			</button>
			<svg class="blue_1_client_dashabord" style={{ fill: props.data.status === "Unassigned" ? "#999999" : props.data.status === "Assigned" ? "#FFCC00" : props.data.status === "Work in Progress" ? "#3399FF" : props.data.status === "On Hold" ? "#FF9900" : props.data.status === "Completed" ? "#00CC66" : props.data.status === "Cancelled" ? "#FF0000" : "", bottom: "0px" }}>
				{/* {console.log(props.data.billedHours,props.maxHours)} */}
				<rect id="blue_1_client_dashabord" rx="0" ry="0" x="0" y="0" width="25" height={((parseInt(props.data.billedHours || 0)) / parseInt(props.maxHours)) * 200}>
				</rect>
			</svg>
			<div id="n__blue_client_dashabord" style={{ top: (210 - ((parseInt(props.data.billedHours || 0)) / parseInt(props.maxHours)) * 200) + "px" }}>
				<span>{props.data.billedHours}</span>
			</div>
			<div id="n__purple_1_client_dashabord" style={{ top: (210 - ((parseInt(props.data.plannedHours || 0)) / parseInt(props.maxHours)) * 200) + "px" }}>
				<span>{props.data.plannedHours}</span>
			</div>



			<PopUpTask display={displayTask} data={props.data} teamData={props.teamData} closePopup={() => {setDisplayTask("none"); props.refresh()}} project={props.project} />
		</div>
	)
}

export default TaskBarChart