import React,{useState,useContext} from 'react'
import "../../Styles/PC/login.css"
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom'
import {useNavigate} from "react-router-dom";

const Login = () => {
    var navigate = useNavigate();
const [email,setEmail] = useState("")
const [password, setPass] = useState("")

let emailSubmit = (e) => {
    setEmail(e.target.value)
}

let passSubmit = (e1) => {
    setPass(e1.target.value)
}



// bzbvb
const { user, setUser} = useContext(UserContext);

    const redirect = () =>{
		if(email.length === 0){
			return	alert("Please Enter Email!!");
			}
		if(password.length === 0){
			return	alert("Please Enter Password!!");
			}
        const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
        var apiParam = {
          method: 'POST',
          headers: { email: email, password: password },
          credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
				console.log(repos.data)
              localStorage.setItem("loggedintoken", repos.data);
              localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
              setUser(JSON.parse(atob(repos.data.split(".")[1])))
          
              navigate("/dashboard");
            } else alert(JSON.stringify(repos));
          })
          .catch((err) => alert(JSON.stringify(err)));
       };
// xznbnm

    return (
        <div>

            <div id="pc_login__1">
                <img id="emile-perron-xrVDYZRGdw4-unspl" src="" srcset="emile-perron-xrVDYZRGdw4-unspl.png 1x" />

                {/* <!-- <img id="view" src="view.png" srcset="view.png 1x"> --> */}

                <img id="Group_1" src="" srcset="Group_1.png 1x" />

               
                <div id="mid_overall">
                    <svg className="bg_dummy">
                        <rect id="bg_dummy" rx="17" ry="17" x="0" y="0" width="392" height="413">
                        </rect>
                    </svg>
                    <img id="logo" src="" srcset="logo.png 1x" />

                    <div id="SIGN_IN">
                        <span>Project Assignments</span>
                    </div>
                    {/* <!-- <div id="Sign_in_with_email">
                                <span>Sign in with email</span>
                            </div> --> */}
                    <input tabIndex={1} placeholder="Email" className="username" type="email" value={email} onChange={emailSubmit}/>
                    
                    <button id="btn" tabIndex={3} onClick={()=>redirect()}>
                        <svg className="Rectangle_3_r">
                            <linearGradient id="Rectangle_3_r" spreadMethod="pad" x1="0.5" x2="0.5" y1="1" y2="-0.936">
                                <stop offset="0" stop-color="#4ce26f" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="#569a66" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="Rectangle_3_r" rx="6" ry="6" x="0" y="0" width="305" height="45">
                            </rect>
                        </svg>
                        <div id="Sign_in" >
                            <span >Sign in</span>
                        </div>
                    </button>
                    <input tabIndex={2} type='password' placeholder="Password" className="password"  value={password} onChange={passSubmit}/>
                  
                  
                    {/* <Link to="/forgotpassword">
                        <div id="Forgot_Password">
                            <span>Forgot Password</span>
                        </div>
                    </Link> */}


                </div>
            </div>
        </div>
    )
}

export default Login