import *as React from 'react'
import "../../Styles/PC/staff.css"
import Switch from '@mui/material/Switch';
import { useState, useEffect,useContext } from "react"
import PopupStaff from './PopupStaff';
import { UserContext } from '../UserContext';




const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const StaffPage = (props) => {

    // const [addStaffPopup2, setAddStaffPopup2] = useState(null);

    // const[cardPopup,setCardPopup] = useState(false)      
	// const [popUpData,setPopUpData] = useState()    
	const {user, setUser} = useContext(UserContext)

    const [displayStaff, setDisplayStaff] = useState("none")
	const [showStaffData, setStaffShowData] = useState({});


    
    const [staffData, setStaffData] = useState([])

    useEffect(() => {
        
            GetStaff()
        
    }, [props.isAddPopupVisible ,displayStaff])
    
    useEffect(() => {
        setDisplayStaff(props.isAddPopupVisible === true ? "Add" : displayStaff === true ? "Edit" : "none")
	}, [props.isAddPopupVisible])


    const GetStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/staff/getStaff";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    return (
        <div>
            <div id="staff_page">
                <img id="over_all_bg_page" src="over_all_bg_page.png" srcset="over_all_bg_page.png 1x" />
            </div>
            <div>



                <div id="staff_page_scroll">
                    {staffData.filter(rw=> (rw.staffName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.staffSkills.toLowerCase().includes(props.searchText.toLowerCase()) || rw.staffMobile.toString().includes(props.searchText.toLowerCase())|| rw.staffDesignation.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec) => (
                        <div style={{ position: "relative", display: 'inline-flex' }}>

                            <div id="user_card_staff_page" onClick={() => {setDisplayStaff("Edit");setStaffShowData(rec)}}>
                              {/* {console.log(rec)} */}
                                <svg class="card_over_all_bg_staff_page">
                                    <rect id="card_over_all_bg_staff_page" rx="10" ry="10" x="0" y="0" width="174" height="227">
                                    </rect>
                                </svg>
                                <img id="card_bg_design_staff_page" src="card_bg_design_staff_page.png" srcset="card_bg_design_staff_page.png 1x" />

                                <div id="pfp_grp_staff_page">
                                    <svg class="pfp_behind_circle_staff_page">
                                        <ellipse id="pfp_behind_circle_staff_page" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{fill:rec.staffActive===true?"rgba(119,238,170,1)":"White"}}>
                                        </ellipse>
                                    </svg>
                                   { console.log(user)}
                                    <img id="profile_pic_card_staff_page" src={
									rec.profilePicUrl && rec.profilePicUrl.length > 10
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								} />

                                </div>
                                <img title="Staff Experience" id="exp_staff_page" src="exp_staff_page.png" srcset="exp_staff_page.png 1x" />

                                <div id="yr_staff_page">
                                    <span> {parseInt( rec.staffExpirienceMonths/12)} Years {parseInt( rec.staffExpirienceMonths%12)} Months</span>
                                </div>
                                <img title="Mobile Number" id="iphone-staff_page" src="iphone-staff_page.png" srcset="iphone-staff_page.png 1x" />

                                <div id="staff_page_mobile_number">
                                    <span>{rec.staffMobile}</span>
                                </div>
                                <img title="Staff Designation" id="manager_lbl_staff_page" src="manager_lbl_staff2_page.png" srcset="manager_lbl_staff2_page.png 1x" />

                                <div id="Admin_lbl_staff_page">
                                    <span>{rec.staffDesignation}</span>
                                </div>
                                <img title="Staff Skill" id="remark_lbl_staff_page" src="remark_lbl_staff_page.png" srcset="remark_lbl_staff_page.png 1x" />

                                <div id="Remark_lbl_staff_page_bm">
                                    <span>{rec.staffSkills}</span>
                                </div>
                                <div id="Mohammed_Affan_lbl_staff_page">
                                    <span>{rec.staffName}</span>
                                </div>
                                
                            </div>

                        </div>
                    ))}
                </div>


            </div>


            {/* <button id="add_btn_staff_page" onClick={() => setAddStaffPopup2(true)}>
                <svg class="add_bg_staff_page">
                    <rect id="add_bg_staff_page" rx="8" ry="8" x="0" y="0" width="97" height="36">
                    </rect>
                </svg>
                <svg class="Icon_ionic-staff_page" viewBox="6.75 6.75 18 18">
                    <path id="Icon_ionic-staff_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                    </path>
                </svg>
                <div id="Active_lbl_staff_page" onClick={() => setAddStaffPopup2(true)}>
                    <span>Add Staff</span>
                </div>
            </button> */}
            {/* <PopupStaff isOpen={cardPopup} data={popUpData} closePopup2={(value) => setCardPopup(value)} /> */}
            {/* <PopupStaff isOpen={addStaffPopup2} closePopup2={(value) => setAddStaffPopup2(value)} /> */}
            <PopupStaff display={displayStaff} data={showStaffData} closePopup2={() => {props.closeAddPopup();setDisplayStaff("none")}} />

        </div>
    )
}

export default StaffPage