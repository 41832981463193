import React, { useState, useContext, useEffect } from 'react'
import Navbar from './Navbar'
import NavExt from "./NavExt"
import zIndex from '@mui/material/styles/zIndex'
import Footer from './Footer'
import SearchBar from './SearchBar'
import LogoName from './LogoName'
// import AddButton from './AddButton'
import { Switch, useLocation } from 'react-router-dom';
import { UserContext } from '../UserContext';
import ResetButton from './ResetButton'



const ToggleNavbar = (props) => {

  let location = useLocation()
  const { user, setUser } = useContext(UserContext);

  const [currentform1, setCurrentform1] = useState('navbar')

  let toggleform2 = (formName2) => {
    setCurrentform1(formName2);
  }

  
  return (

    <div>
      {currentform1 === "navbar" ? <Navbar onFormSwitch={toggleform2} /> : <NavExt onFormSwitch={toggleform2} />}
      <Footer />
      <SearchBar openAddPopup={()=>props.openAddPopup()} isAddVisible={props.isAddVisible} searchText={props.searchText} setSearchText={(value)=>props.setSearchText(value)}/>
      
      <LogoName />
      <ResetButton/>
    </div>
  )
}

export default ToggleNavbar

