import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal';
import "../../Styles/PC/popupClient.css"
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };


const PopupClient = (props) => {

    const [companyName, setCompanyName] = useState("")
    const [companyInfo, setCompanyInfo] = useState("")
    const [gstNo, setGstNo] = useState("")
    const [address, setAddress] = useState("")
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [pin, setPin] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [contactPersonMobile, setContactPersonMobile] = useState("")
    const [contactPersonDesignation, setContactPersonDesignation] = useState("")
    const [accountPersonName, setAccountPersonName] = useState("")
    const [accountPersonDesignation, setAccountPersonDesignation] = useState("")
    const [accountPersonMobile, setAccountPersonMobile] = useState(0)
    const [active, setActive] = useState(true)
    const [clientId, setClientId] = useState("")

    const [profilePic, setProfilePic] = useState("")
    const [profileImage, setProfileImage] = useState("")




    useEffect(() => {

        setClientId(props.data ? props.data._id : "")
        setCompanyName(props.data ? props.data.companyName : "")
        setCompanyInfo(props.data ? props.data.companyInfo : "")
        setGstNo(props.data ? props.data.gstNo : "")
        setAddress(props.data ? props.data.address : "")
        setCountry(props.data ? props.data.country : "")
        setState(props.data ? props.data.state : "")
        setCity(props.data ? props.data.city : "")
        setPin(props.data ? props.data.pin : "")
        setContactPersonName(props.data ? props.data.contactPersonName : "")
        setContactPersonMobile(props.data ? props.data.contactPersonMobile : "")
        setContactPersonDesignation(props.data ? props.data.contactPersonDesignation : "")
        setAccountPersonName(props.data ? props.data.accountPersonName : "")
        setAccountPersonDesignation(props.data ? props.data.accountPersonDesignation : "")
        setAccountPersonMobile(props.data ? props.data.accountPersonMobile : 0)
        setActive(props.data ? props.data.active : false)
        setProfileImage(props.data ? props.data.profilePicUrl : "")


    }, [props.data, props.isOpen])

    const addClient = () => {
        if (companyName.length === 0 || companyName.length === "") {
            return alert("Please Enter  Company Name!!")
        }

        if (country.length === 0 || country.length === "") {
            return alert("Please Select  Country")
        }
        if (state.length === 0 || state.length === "") {
            return alert("Please Select  State")
        }
        if (city.length === 0 || city.length === "") {
            return alert("Please Enter  City")
        }

        if (contactPersonName.length === 0 || contactPersonName.length === "") {
            return alert("Please Enter contact Person  Name!!")
        }
        if (contactPersonMobile.length === 0 || contactPersonMobile.length === "") {
            return alert("Please Enter the Contact Person Mobile!!")
        }



        let apiURL = process.env.REACT_APP_API_URL + "/client/addClient";
        let apiParams = {
            method: "POST",
            headers: {
                companyname: companyName,
                companyinfo: companyInfo,
                gstno: gstNo,
                address: address,
                country: country,
                state: state,
                city: city,
                pin: pin,
                contactpersonname: contactPersonName,
                contactpersonmobile: contactPersonMobile,
                contactpersondesignation: contactPersonDesignation,
                accountpersonname: accountPersonName,
                accountpersondesignation: accountPersonDesignation,
                accountpersonmobile: accountPersonMobile,
                active: active,

            },
        };
        // alert(JSON.stringify(apiParams))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup4(false)
                    alert("Client  Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    // const handleClear = () => {
    //    return(
    //         setCompanyName("") , 
    //         setCompanyInfo(""),
    //         setGstNo(""),
    //         setAddress(""),
    //         setCountry(""),
    //         setState(""),
    //         setCity(""),
    //         setPin(""),
    //         setContactPersonName(""),
    //         setContactPersonMobile(""),
    //         setContactPersonDesignation(""),
    //         setAccountPersonDesignation(""),
    //         setAccountPersonMobile("")
    //    )


    // }

    const updateClient = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/client/editClient";
        let apiParams = {
            method: "POST",
            headers: {
                clientid: clientId,
                companyname: companyName,
                companyinfo: companyInfo,
                gstno: gstNo,
                address: address,
                country: country,
                state: state,
                city: city,
                pin: pin,
                contactpersonname: contactPersonName,
                contactpersonmobile: contactPersonMobile,
                contactpersondesignation: contactPersonDesignation,
                accountpersonname: accountPersonName,
                accountpersondesignation: accountPersonDesignation,
                accountpersonmobile: accountPersonMobile,
                active: active,

            },
        };
        // alert(JSON.stringify(apiParams))

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup4(false)
                    alert("Client Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const guardarArchivo = async (e) => {
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = function (e) { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {

                        // alert("Image Uploaded Successfully!!") //See response

                        SaveProfilePic(data)
                    }).catch(e => console.log(e)) // Or Error in console
            }
        }

    }
    const SaveProfilePic = (data) => {

        const apiurl = process.env.REACT_APP_API_URL + "/client/addClientProfilePic/" + props.data._id;
        let apiParams = {
            method: "POST",
            headers: {
                profilepicurl: data.url,


            }
        }
        fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("Image Uploaded Successfully");
                }
            })
            .catch(e => console.log(e))
    }

    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProfilePic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            guardarArchivo(e);
        }


    }





    return (
        <div>

            <Modal

                open={props.isOpen}
                onClose={() => props.closePopup4(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflow: "scroll", textAlign: "center" }}


            >




                <div id="pup_up_add_client">
                    <svg class="over_all_client_page_1">
                        <rect id="over_all_client_page_1" rx="15" ry="15" x="0" y="0" width="361" height="897">
                        </rect>
                    </svg>
                    <svg class="top_bg_sClient_page_1_">
                        <linearGradient id="top_bg_sClient_page_1_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                            <stop offset="0" stop-color="#5ee278" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="top_bg_sClient_page_1_" rx="15" ry="15" x="0" y="0" width="361" height="94">
                        </rect>
                    </svg>
                    <div id="Client_pfp_add_client_pfp">
                        <svg class="pfp_behind_circle_bg_Client_Co">
                            <ellipse id="pfp_behind_circle_bg_Client_Co" rx="50" ry="50" cx="50" cy="50" style={{ fill: active === true ? "rgba(119,238,170,1)" : "White" }}>
                            </ellipse>
                        </svg>
                        {/* <img id="Client_Company_Name__pfp_img" src="Client_Company_Name__pfp_img.png" srcset="Client_Company_Name__pfp_img.png 1x" /> */}
                        <label for="imageProfilepc">
                            <input
                                tabIndex={17}
                                id="imageProfilepc"
                                type="file"
                                name="imageProfilepc"
                                hidden
                                accept="image/*"
                                onChange={(e) => profilePicUpload(e)}
                            />
                            {profilePic.length === 0 ? (
                                <img
                                    id="Client_Company_Name__pfp_img"
                                    width="100"
                                    height="100"
                                    src={
                                        profileImage && profileImage.length
                                            ? "https://drive.google.com/uc?export=view&id=" +
                                            profileImage.split('/')[5]
                                            :
                                            "default_image.png"
                                    }
                                    style={{ borderRadius: "75px", border: "1px solid black" }}
                                />
                            ) : (
                                <img
                                    id="Client_Company_Name__pfp_img"
                                    width="100"
                                    height="100"
                                    src={profilePic ? profilePic : "default_image.png"}
                                    style={{ borderRadius: "75px", border: "1px solid black" }}
                                />
                            )}
                        </label>

                    </div>
                    <div id="client_active_grp_">
                        <div id="active_lbl_client">
                            <span>Active</span>
                        </div>
                        <Switch {...label} tabIndex={18} size="small" className='switch_client' checked={active} onChange={(e) => { setActive(e.target.checked) }} />
                    </div>
                    <button id="add_client__btn_grp" onClick={() => { props.data ? updateClient() : addClient() }}>
                        <svg class="addd_bdr_btn_client_">
                            <rect id="addd_bdr_btn_client_" rx="8" ry="8" x="0" y="0" width="123" height="41">
                            </rect>
                        </svg>
                        <div id="Add_pup_opClient__lbl" >
                            <span tabIndex={15} >{props.data ? "Save" : "Add"}</span>
                        </div>
                    </button>
                    <img id="close_pup_op_client" tabIndex={16} src="close_pup_op_client.png" srcset="close_pup_op_client.png 1x" onClick={() => props.closePopup4(false)} />

                    <div id="scroll_add_client">
                        <div id="Client_Company_Name_name_grp_p">
                            <div id="Client_Company_Name_name__lbl_">
                                <span>Company Name</span>
                            </div>
                            <input tabIndex={1} placeholder="Company Name" class="Client_Company_Name_name__inpu" value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} />

                        </div>
                        <div id="client_Company_Information_nam">
                            <div id="client_Company_Information_nam_br">
                                <span>Company Information</span>
                            </div>
                            <textarea tabIndex={2} placeholder="Company Information" class="client_Company_Information_nam_bs" value={companyInfo} onChange={(e) => { setCompanyInfo(e.target.value) }} />

                        </div>
                        <div id="client_gst_no_grp">
                            <div id="gst_no_CPN_skill_lbl_pfp">
                                <span>GST No.</span>
                            </div>
                            <input tabIndex={3} placeholder="GST No." class="gst_no_skill_input_pfp" value={gstNo} onChange={(e) => { setGstNo(e.target.value) }} />

                        </div>
                        <div id="client_address_exp_grp">
                            <div id="client_address_exp_lbl_pfp">
                                <span>Address</span>
                            </div>
                            <textarea tabIndex={4} placeholder="Address" class="client_addres__exp_input_pfp" value={address} onChange={(e) => { setAddress(e.target.value) }} />


                        </div>
                        <div id="Contact_Person_Designation_pro">
                            <div id="client_pup_opContact_Person_D">
                                <span>Contact Person Designation</span>
                            </div>
                            <input tabIndex={11} placeholder="Contact Person Designation" class="client__pup_op_Contact_Person" value={contactPersonDesignation} onChange={(e) => { setContactPersonDesignation(e.target.value) }} />

                        </div>
                        <div id="Contact_Person_Mobile_client">
                            <div id="Contact_Person_Mobile_pup_op_c">
                                <span>Contact Person Mobile</span>
                            </div>
                            <input tabIndex={10} placeholder="Contact Person Mobile" type='number' class="Contact_Person_Mobile_pup_oppr" value={contactPersonMobile} onChange={(e) => { setContactPersonMobile(e.target.value) }} />

                        </div>
                        <div id="CPM_client_grp">
                            <div id="remark_pup_op_client__lbl">
                                <span>Contact Person Name</span>
                            </div>
                            <input tabIndex={9} placeholder="Contact Person Name" class="remark_pup_opclient__input" value={contactPersonName} onChange={(e) => { setContactPersonName(e.target.value) }} />

                        </div>
                        <div id="Accounts_Person_Name_pr_client">
                            <div id="client_pup_op_Accounts_Person_Name">
                                <span>Accounts Person Name</span>
                            </div>
                            <input tabIndex={11} placeholder="Accounts Person Name" class="project__pup_opAccounts_Person_Name" value={accountPersonName} onChange={(e) => { setAccountPersonName(e.target.value) }} />

                        </div>

                        <div id="Accounts_Person_Designation_pr_client">
                            <div id="client_pup_op_Accounts_Person">
                                <span>Accounts Person Designation</span>
                            </div>
                            <input tabIndex={12} placeholder="Accounts Person Designation" class="project__pup_opAccounts_Person" value={accountPersonDesignation} onChange={(e) => { setAccountPersonDesignation(e.target.value) }} />

                        </div>
                        <div id="client_gst_no_grp_cb">
                            <div id="gst_no_CPN_skill_lbl_pfp_cc">
                                <span>Country</span>
                            </div>
                            <select tabIndex={5} placeholder="GST No." class="gst_no_skill_input_pfp_cd" value={country} onChange={(e) => { setCountry(e.target.value) }}>
                                <rect id="gst_no_skill_input_pfp_cd" rx="0" ry="0" x="0" y="0" width="146" height="40">
                                </rect>
                                <option value="0">----Select----</option>
                                {/* <option value="India">India</option> */}
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Albania">Albania</option>
                                <option value="Algeria">Algeria</option>
                                <option value="American Samoa">American Samoa</option>
                                <option value="Andorra">Andorra</option>
                                <option value="Angola">Angola</option>
                                <option value="Anguilla">Anguilla</option>
                                <option value="Antartica">Antarctica</option>
                                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                <option value="Argentina">Argentina</option>
                                <option value="Armenia">Armenia</option>
                                <option value="Aruba">Aruba</option>
                                <option value="Australia">Australia</option>
                                <option value="Austria">Austria</option>
                                <option value="Azerbaijan">Azerbaijan</option>
                                <option value="Bahamas">Bahamas</option>
                                <option value="Bahrain">Bahrain</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Barbados">Barbados</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Benin">Benin</option>
                                <option value="Bermuda">Bermuda</option>
                                <option value="Bhutan">Bhutan</option>
                                <option value="Bolivia">Bolivia</option>
                                <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                <option value="Botswana">Botswana</option>
                                <option value="Bouvet Island">Bouvet Island</option>
                                <option value="Brazil">Brazil</option>
                                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                <option value="Brunei Darussalam">Brunei Darussalam</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Burkina Faso">Burkina Faso</option>
                                <option value="Burundi">Burundi</option>
                                <option value="Cambodia">Cambodia</option>
                                <option value="Cameroon">Cameroon</option>
                                <option value="Canada">Canada</option>
                                <option value="Cape Verde">Cape Verde</option>
                                <option value="Cayman Islands">Cayman Islands</option>
                                <option value="Central African Republic">Central African Republic</option>
                                <option value="Chad">Chad</option>
                                <option value="Chile">Chile</option>
                                <option value="China">China</option>
                                <option value="Christmas Island">Christmas Island</option>
                                <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                <option value="Colombia">Colombia</option>
                                <option value="Comoros">Comoros</option>
                                <option value="Congo">Congo</option>
                                <option value="Congo">Congo, the Democratic Republic of the</option>
                                <option value="Cook Islands">Cook Islands</option>
                                <option value="Costa Rica">Costa Rica</option>
                                <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                <option value="Croatia">Croatia (Hrvatska)</option>
                                <option value="Cuba">Cuba</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Czech Republic">Czech Republic</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Djibouti">Djibouti</option>
                                <option value="Dominica">Dominica</option>
                                <option value="Dominican Republic">Dominican Republic</option>
                                <option value="East Timor">East Timor</option>
                                <option value="Ecuador">Ecuador</option>
                                <option value="Egypt">Egypt</option>
                                <option value="El Salvador">El Salvador</option>
                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                <option value="Eritrea">Eritrea</option>
                                <option value="Estonia">Estonia</option>
                                <option value="Ethiopia">Ethiopia</option>
                                <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                <option value="Faroe Islands">Faroe Islands</option>
                                <option value="Fiji">Fiji</option>
                                <option value="Finland">Finland</option>
                                <option value="France">France</option>
                                <option value="France Metropolitan">France, Metropolitan</option>
                                <option value="French Guiana">French Guiana</option>
                                <option value="French Polynesia">French Polynesia</option>
                                <option value="French Southern Territories">French Southern Territories</option>
                                <option value="Gabon">Gabon</option>
                                <option value="Gambia">Gambia</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Germany">Germany</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Gibraltar">Gibraltar</option>
                                <option value="Greece">Greece</option>
                                <option value="Greenland">Greenland</option>
                                <option value="Grenada">Grenada</option>
                                <option value="Guadeloupe">Guadeloupe</option>
                                <option value="Guam">Guam</option>
                                <option value="Guatemala">Guatemala</option>
                                <option value="Guinea">Guinea</option>
                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                <option value="Guyana">Guyana</option>
                                <option value="Haiti">Haiti</option>
                                <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                <option value="Holy See">Holy See (Vatican City State)</option>
                                <option value="Honduras">Honduras</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Hungary">Hungary</option>
                                <option value="Iceland">Iceland</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Iran">Iran (Islamic Republic of)</option>
                                <option value="Iraq">Iraq</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Jamaica">Jamaica</option>
                                <option value="Japan">Japan</option>
                                <option value="Jordan">Jordan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kenya">Kenya</option>
                                <option value="Kiribati">Kiribati</option>
                                <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                <option value="Korea">Korea, Republic of</option>
                                <option value="Kuwait">Kuwait</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Lao">Lao People's Democratic Republic</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Lebanon" selected>Lebanon</option>
                                <option value="Lesotho">Lesotho</option>
                                <option value="Liberia">Liberia</option>
                                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                <option value="Liechtenstein">Liechtenstein</option>
                                <option value="Lithuania">Lithuania</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Macau">Macau</option>
                                <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                <option value="Madagascar">Madagascar</option>
                                <option value="Malawi">Malawi</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maldives">Maldives</option>
                                <option value="Mali">Mali</option>
                                <option value="Malta">Malta</option>
                                <option value="Marshall Islands">Marshall Islands</option>
                                <option value="Martinique">Martinique</option>
                                <option value="Mauritania">Mauritania</option>
                                <option value="Mauritius">Mauritius</option>
                                <option value="Mayotte">Mayotte</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Micronesia">Micronesia, Federated States of</option>
                                <option value="Moldova">Moldova, Republic of</option>
                                <option value="Monaco">Monaco</option>
                                <option value="Mongolia">Mongolia</option>
                                <option value="Montserrat">Montserrat</option>
                                <option value="Morocco">Morocco</option>
                                <option value="Mozambique">Mozambique</option>
                                <option value="Myanmar">Myanmar</option>
                                <option value="Namibia">Namibia</option>
                                <option value="Nauru">Nauru</option>
                                <option value="Nepal">Nepal</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="Netherlands Antilles">Netherlands Antilles</option>
                                <option value="New Caledonia">New Caledonia</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Nicaragua">Nicaragua</option>
                                <option value="Niger">Niger</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Niue">Niue</option>
                                <option value="Norfolk Island">Norfolk Island</option>
                                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                <option value="Norway">Norway</option>
                                <option value="Oman">Oman</option>
                                <option value="Pakistan">Pakistan</option>
                                <option value="Palau">Palau</option>
                                <option value="Panama">Panama</option>
                                <option value="Papua New Guinea">Papua New Guinea</option>
                                <option value="Paraguay">Paraguay</option>
                                <option value="Peru">Peru</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Pitcairn">Pitcairn</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Puerto Rico">Puerto Rico</option>
                                <option value="Qatar">Qatar</option>
                                <option value="Reunion">Reunion</option>
                                <option value="Romania">Romania</option>
                                <option value="Russia">Russian Federation</option>
                                <option value="Rwanda">Rwanda</option>
                                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                <option value="Saint LUCIA">Saint LUCIA</option>
                                <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                <option value="Samoa">Samoa</option>
                                <option value="San Marino">San Marino</option>
                                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                <option value="Saudi Arabia">Saudi Arabia</option>
                                <option value="Senegal">Senegal</option>
                                <option value="Seychelles">Seychelles</option>
                                <option value="Sierra">Sierra Leone</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                <option value="Slovenia">Slovenia</option>
                                <option value="Solomon Islands">Solomon Islands</option>
                                <option value="Somalia">Somalia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                <option value="Span">Spain</option>
                                <option value="SriLanka">Sri Lanka</option>
                                <option value="St. Helena">St. Helena</option>
                                <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                <option value="Sudan">Sudan</option>
                                <option value="Suriname">Suriname</option>
                                <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                <option value="Swaziland">Swaziland</option>
                                <option value="Sweden">Sweden</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Syria">Syrian Arab Republic</option>
                                <option value="Taiwan">Taiwan, Province of China</option>
                                <option value="Tajikistan">Tajikistan</option>
                                <option value="Tanzania">Tanzania, United Republic of</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Togo">Togo</option>
                                <option value="Tokelau">Tokelau</option>
                                <option value="Tonga">Tonga</option>
                                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                <option value="Tunisia">Tunisia</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Turkmenistan">Turkmenistan</option>
                                <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                <option value="Tuvalu">Tuvalu</option>
                                <option value="Uganda">Uganda</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                <option value="Uruguay">Uruguay</option>
                                <option value="Uzbekistan">Uzbekistan</option>
                                <option value="Vanuatu">Vanuatu</option>
                                <option value="Venezuela">Venezuela</option>
                                <option value="Vietnam">Viet Nam</option>
                                <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                <option value="Western Sahara">Western Sahara</option>
                                <option value="Yemen">Yemen</option>
                                <option value="Serbia">Serbia</option>
                                <option value="Zambia">Zambia</option>
                                <option value="Zimbabwe">Zimbabwe</option>


                            </select>
                        </div>
                        <div id="client_gst_no_grp_ce">
                            <div id="gst_no_CPN_skill_lbl_pfp_cf">
                                <span>States</span>
                            </div>
                            <select tabIndex={6} placeholder="GST No." class="gst_no_skill_input_pfp_cg" value={state} onChange={(e) => { setState(e.target.value) }}>
                                <rect id="gst_no_skill_input_pfp_cg" rx="0" ry="0" x="0" y="0" width="146" height="40">
                                </rect>
                                <option value="0">----Select----</option>

                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chandigarh">Chandigarh</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                <option value="Daman and Diu">Daman and Diu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Lakshadweep">Lakshadweep</option>
                                <option value="Puducherry">Puducherry</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                <option value="Jharkhand">Jharkhand</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Kerala">Kerala</option>
                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>

                            </select>
                        </div>
                        <div id="client_gst_no_grp_ch">
                            <div id="gst_no_CPN_skill_lbl_pfp_ci">
                                <span>City / Town</span>
                            </div>
                            <input tabIndex={7} placeholder="City / Town" class="gst_no_skill_input_pfp_cj" value={city} onChange={(e) => { setCity(e.target.value) }} />

                        </div>
                        <div id="client_gst_no_grp_ck">
                            <div id="gst_no_CPN_skill_lbl_pfp_cl">
                                <span>PIN</span>
                            </div>
                            <input tabIndex={8} placeholder="PIN" type='number' class="gst_no_skill_input_pfp_cm" value={pin} onChange={(e) => { setPin(e.target.value) }} />

                        </div>
                        <div id="Accounts_Person_Mobile_client">
                            <div id="Accounts_Person_Mobile_pup_op_client">
                                <span>Accounts Person Mobile</span>
                            </div>
                            <input tabIndex={13} placeholder="Accounts Person Mobile" type='number' class="Accounts_Person_Mobile__pup_op" value={accountPersonMobile} onChange={(e) => { setAccountPersonMobile(e.target.value) }} />

                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default PopupClient