import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from '../UserContext'

import "../../Styles/Mob/cat_mod_team.css"
import Team from '../PC/Team';

const Cat_Mod_Team = (props) => {
    const { user } = useContext(UserContext);

    const [category, setCategory] = useState("")
    const [editRowNum, setEditRowNum] = useState(-1)
    const [editRowNumModule, setEditRowNumModule] = useState(-1)
    const [active, setActive] = useState(true)
    const [categoryData, setCategoryData] = useState([])

	const [module, setModule] = useState("")
    const [moduleGet, setModuleGet] = useState(UserContext)
	const [moduleData, setModuleData] = useState([])


    const [teamData, setTeamData] = useState([])
    const [display, setDisplay] = useState("none")
	const [selectedStaff, setSelectedStaff] = useState({});



    useEffect(() => {
        if (props.project._id, user) {

            GetTeam()
        }
    }, [props.project, display, user])

    useEffect(() => {
        if (props.project._id) {
            GetCategory()

        }
    }, [props.project])

    useEffect(() => {
		if (moduleGet) {
			GetModule()
		}
	}, [])

    const addCategory = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/category/addCategory";
        let apiParams = {
            method: "POST",
            headers: {

                projectid: props.project._id,
                projectname: props.project.projectName,
                createdbyid: user._id,
                createdbyname: user.name,
                category: category,
                active: active,

            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    alert("Added Successfully!!");

                } else alert(repos.message || repos);
                GetCategory()

            })
            .catch((err) => alert(err));

    }
    const GetCategory = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/category/getCategories/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setCategoryData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const saveCategory = (categoryId, category, active) => {
        let apiURL = process.env.REACT_APP_API_URL + "/category/editCategory";
        let apiParams = {
            method: "POST",
            headers: {
                categoryid: categoryId,
                category: category,
                active: active,
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    alert("Details Updated Successfully!!");

                    GetCategory()

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const addModule = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/addModule";
		let apiParams = {
			method: "POST",
			headers: {
				projectid: props.project._id,
				projectname: props.project.projectName,
				createdbyid: user._id,
				createdbyname: user.name,
				module: module,
				active: active,
			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Added Successfully!!");


				} else alert(repos.message || repos);
				GetModule()
			})
			.catch((err) => alert(err));

	}

	const GetModule = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/getModules/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setModuleData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	const saveModule = (moduleId, module, active) => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/editModule";
		let apiParams = {
			method: "POST",
			headers: {
				moduleid: moduleId,
				module: module,
				active: active,
			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Details Updated Successfully!!");


				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

    const GetTeam = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/team/GetTeam/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    // alert(	((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
                    // if(((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
                    //  {
                    // 	props.showAddButton(false)
                    // }
                    // else{
                    // 	props.showAddButton(true)
                    // }

                    setTeamData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    const handleTextChange = (text, index) => {
        var newArray = categoryData;
        newArray[index].category = text;

        setCategoryData([...newArray])
    }
    const handleDelete = (i) => {
        const deleteVal = [...categoryData]
        deleteVal.splice(i, 1)
        setCategoryData(deleteVal)

    }

    const handleModule = (text, index) => {
		var newArray = moduleData;
		newArray[index].module = text;

		setModuleData([...newArray])
	}
	const moduleDelete = (i) => {
		const deleteVal2 = [...moduleData]
		deleteVal2.splice(i, 1)
		setModuleData(deleteVal2)
	}



    return (

        <div id="side_component__2">

            <div id="over_all_grp_right_compo_mob"  >
                <div id="categories_overall_card_mob" >
                    <img id="cmt_bck_btn" src="back_btn.png" srcset="back_btn.png 1x" onClick={() => props.closeSide(false)} />

                    <button id="grpTask_page_mob">
                        <svg class="bgTask_page_mob">
                            <rect id="bgTask_page_mob" rx="15" ry="15" x="0" y="0" width="197" height="200">
                            </rect>
                        </svg>
                        <div id="categories_Task_page_mob">
                            <span>Categories</span>
                        </div>
                        {((editRowNum < categoryData.length - 1 || editRowNum === -1) && ((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client"))) ?

                            <svg class="Icon_ionic-minus_mob_Task_page" viewBox="6.75 6.75 18 18" onClick={() => { setCategoryData([...categoryData, { category: "", projectId: props.project._id, project: props.project.projectName, active: true }]); setEditRowNum(categoryData.length) }}>
                                <path id="Icon_ionic-minus_mob_Task_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                                </path>
                            </svg>
                            : ""}
                    </button>
                    <div id="Scroll_doc_mob">
                        {
                            categoryData.map((rec, idx) => (
                                <div>
                                    <div>{idx !== editRowNum ?
                                        <div id="ocumentation_grp_cat_mob_egory">
                                            <div id="add_minus_Task_page_mob">
                                                <span>{rec.category}</span>
                                            </div>
                                            {(user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client") ?
                                                <>
                                                    <div id="minus_Task_pag_mob">
                                                        <svg class="add_mob_bg_minus_Task_page" onClick={() => { saveCategory(rec._id, rec.category, false); handleDelete(idx) }}>
                                                            <rect id="add_mob_bg_minus_Task_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                            </rect>
                                                        </svg>
                                                        <svg class="Icon_mob_ionic-minus_Task_page" viewBox="6.75 11.167 10.193 1.359" onClick={() => { saveCategory(rec._id, rec.category, false); handleDelete(idx) }}>
                                                            <path id="Icon_mob_ionic-minus_Task_page" d="M 16.94312858581543 12.52609539031982 L 12.52609539031982 12.52609539031982 L 11.16703319549561 12.52609539031982 L 6.75 12.52609539031982 L 6.75 11.16703319549561 L 11.16703319549561 11.16703319549561 L 12.52609539031982 11.16703319549561 L 16.94312858581543 11.16703319549561 L 16.94312858581543 12.52609539031982 Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <div id="edit_grp_Task_page_mob">
                                                        <svg class="add_bg_mob_edit_grp">
                                                            <rect id="add_bg_mob_edit_grp" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                            </rect>
                                                        </svg>
                                                        <img id="pencil_mob_1_edit_grp" src="pencil_mob_1_edit_grp.png" srcset="pencil_mob_1_edit_grp.png 1x" onClick={() => { setEditRowNum(idx) }} />

                                                    </div>
                                                </>
                                                : ""}
                                        </div>
                                        :
                                        <div id="input_categ_mobories_grp">
                                            <div id="cancel_grp_Tas_mobk_page">
                                                <svg class="add_bgcancel_mob" onClick={() => { setCategoryData([...categoryData.filter(rw => rw.categoryId)]); setEditRowNum(-1) }}>
                                                    <rect id="add_bgcancel_mob" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <svg class="Icon_ioniccancel_mob" viewBox="6.75 6.75 14.056 14.056" onClick={() => { setCategoryData([...categoryData.filter(rw => rw.categoryId)]); setEditRowNum(-1) }}>
                                                    <path id="Icon_ioniccancel_mob" d="M 20.80647850036621 14.71532344818115 L 14.71532344818115 14.71532344818115 L 14.71532344818115 20.80647850036621 L 12.84115600585938 20.80647850036621 L 12.84115600585938 14.71532344818115 L 6.75 14.71532344818115 L 6.75 12.84115600585938 L 12.84115505218506 12.84115600585938 L 12.84115505218506 6.75 L 14.71532344818115 6.75 L 14.71532344818115 12.84115505218506 L 20.80647850036621 12.84115505218506 L 20.80647850036621 14.71532344818115 Z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div id="save_grp_bdr_Task_mob_page">
                                                <svg class="save_bdr_Ta_mob_sk_page">
                                                    <rect id="save_bdr_Ta_mob_sk_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                    </rect>
                                                </svg>
                                                <img id="diskette_2_mob_Task_page" src="diskette_2_mob_Task_page.png" srcset="diskette_2_mob_Task_page.png 1x" onClick={() => { (setEditRowNum(-1)); rec._id ? saveCategory(rec._id, category, active) : addCategory() }} />

                                            </div>
                                            <input placeholder="Category" type='text' class="edit_documentat_mobion" value={rec.category} onChange={(e) => { (setCategory(e.target.value)); handleTextChange(e.target.value, idx) }} />

                                        </div>
                                    }</div>
                                </div>
                            ))
                        }
                    </div>

                </div>
                <div id="Modules_over_all_gr_mobp_task_">
                    <button id="module_mob">
                        <svg class="bdr_module_mob_Task_page">
                            <rect id="bdr_module_mob_Task_page" rx="15" ry="15" x="0" y="0" width="197" height="200">
                            </rect>
                        </svg>
                        {(editRowNumModule < moduleData.length - 1 || editRowNumModule === -1) && ((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && parseInt(user.useraccess) >= 3 && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client")) ?

                            <svg class="add_btn_T_mobask_page" viewBox="6.75 6.75 18 18" onClick={() => { setModuleData([...moduleData, { module: "", projectId: props.project._id, project: props.project.projectName, active: true }]); setEditRowNumModule(moduleData.length) }} >
                                <path id="add_btn_T_mobask_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                                </path>
                            </svg>
                            : ""}
                        <div id="module_mob_lbl_Task_page">
                            <span>Modules</span>
                        </div>
                    </button>
                    <div id="Scroll_mof_mob">
                        {moduleData.filter(rw => rw.active === true).map((rec, idx) => {

                            return (
                                <div>{idx !== editRowNumModule ?
                                    <div id="documentation_grp_module_mob">
                                        <div id="documentation_lbl__mob">
                                            <span>{rec.module}</span>
                                        </div>
                                        {(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client") ? 
											<>
                                        <div id="minus_grp_mob">
                                            <svg class="bdr_minus_mob_Task_page" onClick={() => { saveModule(rec._id, rec.module, false); moduleDelete(idx) }}>
                                                <rect id="bdr_minus_mob_Task_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <svg class="minus_p_mobath_" viewBox="6.75 11.167 10.193 1.359" onClick={() => { saveModule(rec._id, rec.module, false); moduleDelete(idx) }}>
                                                <path id="minus_p_mobath_" d="M 16.94312858581543 12.52609539031982 L 12.52609539031982 12.52609539031982 L 11.16703319549561 12.52609539031982 L 6.75 12.52609539031982 L 6.75 11.16703319549561 L 11.16703319549561 11.16703319549561 L 12.52609539031982 11.16703319549561 L 16.94312858581543 11.16703319549561 L 16.94312858581543 12.52609539031982 Z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div id="edit_btn_grp_mob">
                                            <svg class="bdr_mob_edit_option">
                                                <rect id="bdr_mob_edit_option" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <img id="pencil_mob_1_Task_page" src="pencil_mob_1_Task_page.png" srcset="pencil_mob_1_Task_page.png 1x" onClick={() => setEditRowNumModule(idx)}/>

                                        </div>
                                        </>
											: ""}
                                    </div>
                                    :
                                    <div id="input_grp_modules_mob">
                                        <div id="cancel_btn_grp_mob">
                                            <svg class="add_bg_cli_mobent_page" onClick={() => {setModuleData([...moduleData.filter(rw=>rw.moduleIdId)]);setEditRowNumModule(-1)}}>
                                                <rect id="add_bg_cli_mobent_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <svg class="Icon_ionic-cleintpage_mob" viewBox="6.75 6.75 14.056 14.056" onClick={() => {setModuleData([...moduleData.filter(rw=>rw.moduleIdId)]); setEditRowNumModule(-1)}}>
                                                <path id="Icon_ionic-cleintpage_mob" d="M 20.80647850036621 14.71532344818115 L 14.71532344818115 14.71532344818115 L 14.71532344818115 20.80647850036621 L 12.84115600585938 20.80647850036621 L 12.84115600585938 14.71532344818115 L 6.75 14.71532344818115 L 6.75 12.84115600585938 L 12.84115505218506 12.84115600585938 L 12.84115505218506 6.75 L 14.71532344818115 6.75 L 14.71532344818115 12.84115505218506 L 20.80647850036621 12.84115505218506 L 20.80647850036621 14.71532344818115 Z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div id="save_btn_grp__mob">
                                            <svg class="bdr_save_btn__mob">
                                                <rect id="bdr_save_btn__mob" rx="5" ry="5" x="0" y="0" width="20" height="20">
                                                </rect>
                                            </svg>
                                            <img id="diskette_2_mob" src="diskette_2_mob.png" srcset="diskette_2_mob.png 1x" onClick={() => { setEditRowNumModule(-1); rec._id ? saveModule(rec._id, module, active) : addModule() }} />

                                        </div>
                                        <input placeholder="Module" class="text_box_modul_mobe_task_page" value={rec.module} onChange={(e) => { (setModule(e.target.value)); (handleModule(e.target.value, idx)); handleModule(e.target.value, idx) }}  />

                                    </div>
                                }
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div id="Modules_over_all_grp_task_page_mob">
                    <button id="module__mob">
                        <svg class="bdr_module_Task_page_mob">
                            <rect id="bdr_module_Task_page_mob" rx="15" ry="15" x="0" y="0" width="197" height="200">
                            </rect>
                        </svg>
						{(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0)|| (user && user.usertype === "client") ? 

                        <svg class="add_btn_Task_page_mob" viewBox="6.75 6.75 18 18" onClick={() => setDisplay("Add")} style={{ cursor: "pointer" }}>
                            <path id="add_btn_Task_page_mob" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                            </path>
                        </svg>
                        : ""}
                        <div id="module_lbl_Task_page_mob">
                            <span>Team</span>
                        </div>
                    </button>
					{props.project && <Team display={display} project={props.project} selectedStaff={selectedStaff} staffData={props.staffData} closePopupTeam={() => setDisplay("none")}  teamData={teamData}/>}

                    <div id="Scroll_team_cards_mob">
                    {(teamData || []).map((rec) => (

                        <div id="team_grp_module_mob" onClick={() =>  {setDisplay("Edit"); setSelectedStaff(rec)}}>
                            <svg class="team_bdr__mob">
                                <rect id="team_bdr__mob" rx="5" ry="5" x="0" y="0" width="193" height="64">
                                </rect>
                            </svg>
                            <div id="documentation__moblbl_team_sta"> 
                                <span>{rec.staffName}</span>
                            </div>
                            <div id="documentation_lbl_mob_role_tea">
                                <span>{rec.role}</span>
                            </div>
                            <div id="documentation_lbl_d_mobate_tea">
                                <span>{rec.startDate.toString().slice(0, 10)} {rec.endDate.toString().slice(0, 10)}</span>
                            </div>

                        </div>
                      ))}  
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Cat_Mod_Team