import React from "react";
import PieChart2 from "./PieChart2";

const Pie = (props) => {
  return (
    <div>
      {/* <h1>My App</h1> */}
      <PieChart2 data={props.data}/>
    </div>
  );
};

export default Pie;
