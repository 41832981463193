import React from 'react'
import MenuMob from './MenuMob'
import TopBarMob from './TopBarMob'
import TopUsers from './TopUsers'

const MobileMain = (props) => {
  return (
    <div>
        <MenuMob/>
        <TopBarMob openAddPopupMob={()=>props.openAddPopupMob()} isAddVisible={props.isAddVisible} searchText={props.searchText} setSearchText={(value)=>props.setSearchText(value)}/>
        <TopUsers/>
    </div>
  )
}

export default MobileMain