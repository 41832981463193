import React ,{ useEffect, useState ,useContext}from 'react'
import Modal from '@mui/material/Modal';
import "../../Styles/PC/resetPass.css"
import { UserContext } from '../UserContext';


const ResetPass = (props) => {
	const { user, setUser } = useContext(UserContext);

    const[oldPass,setOldPass]= useState("")
    const[newPass,setNewPass]= useState("")
    const[newCnfrmPass,setNewCnfrmPass]= useState("")

    const resetPassword = () => {
       if(newPass !== newCnfrmPass){
           return alert("Invalid Password")
       }
        let apiURL = process.env.REACT_APP_API_URL + "/users/resetPassword/"+ user._id;
        let apiParams = {
            method: "POST",
            headers: {
                oldpass:oldPass,
                newpass:newPass
            },
        };
        // alert(JSON.stringify(apiParams))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                   
                    alert("Password Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    return (
        <div>
            <Modal

                open={props.isOpen}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflowY: "scroll" }}

            >
                <div id="chamge_pass">
                    <div id="overall_grp_change_pass">
                        <svg class="top_bg_change_pass_c">
                            <linearGradient id="top_bg_change_pass_c" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                                <stop offset="0" stop-color="#a2caff" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="top_bg_change_pass_c" rx="15" ry="15" x="0" y="0" width="361" height="60">
                            </rect>
                        </svg>
                        <img id="close_pup_op_change_passs" src="close_pup_op_change_passs.png" srcset="close_pup_op_change_passs.png 1x" onClick={()=> props.closePopup(false)}/>

                        <div id="Change_Password_lbl_">
                            <span>Change Password</span>
                        </div>
                        <input placeholder="Current Passowrd" type="password" class="old_pass_input" value={oldPass} onChange={(e)=>setOldPass(e.target.value)}/>

                        <input placeholder="New Passowrd" type="password" class="new_pass_input" value={newPass} onChange={(e)=> setNewPass(e.target.value)}/>

                        <input placeholder="Confirm Passowrd" type="password" class="confirm_new_pass_input" value={newCnfrmPass} onChange={(e)=> setNewCnfrmPass(e.target.value)}/>

                        <button id="btn_change_password" onClick={()=> resetPassword()}>
                            <svg class="change_btn_">
                                <rect id="change_btn_" rx="5" ry="5" x="0" y="0" width="131" height="39">
                                </rect>
                            </svg>
                            <div id="Change_lbl_">
                                <span>Change</span>
                            </div>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ResetPass