import React from "react";
// import { PieChart  } from "react-minimal-pie-chart";
import { PieChart } from 'react-minimal-pie-chart';

const PieChart2 = (props) => {
  

  return (
    <div>
      <PieChart
        data={props.data}
        label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
      />
    </div>
  );
};

export default PieChart2;
