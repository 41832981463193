import React,{useState} from 'react'
import ResetPass from './ResetPass'
import "../../Styles/PC/resetButton.css"


const ResetButton = () => {
    const[resetPass,setResetPass]= useState(false)


    
  return (
    <div>
        <img src="resetpassword.png" id='reset_password' onClick={()=>setResetPass(true)} />

<ResetPass isOpen={resetPass} closePopup={(value)=>setResetPass(value)}/> 
    </div>
  )
}

export default ResetButton