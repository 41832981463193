import React, { useContext }  from 'react'
import "../../Styles/PC/ext.css"
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext'




const NavExt = (props) => {
const { user, setUser } = useContext(UserContext);

	return (

		<div id="extend_nav_left_project" onClick={() => props.onFormSwitch('navbar')}>
			<div id="Group_3" className='slide-right'  >
				<svg className="side_nav_bar_border" >
					<rect id="side_nav_bar_border" rx="28" ry="28" x="0" y="0" width="246" height="87vh">
					</rect>
				</svg>
				<div id="over_all_grp_expand" >
					<img id="side_logo_icon_expand" src={
									user.imageId && user.imageId.length > 10
										? "https://drive.google.com/uc?export=view&id=" + user.imageId
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }}  />


					<Link to={"/dashboard"}>
						<div id="dash_bdr_grp_expand_client" className='highlight2' title='DashBoard'>

							<div id="Dashboard_side_lbl_pc">
								<span>DashBoard</span>
							</div>
							<img id="dashboard_Icon_I_1" src="dashboard_icon_client.png" srcset="dashboard_icon_client.png 1x" />

						</div>
					</Link>

					<Link to={"/timesheet"}>
						<div id="offering_grp_expand_timesheet" className='highlight2' title='TimeSheet'>
							<div id="Offerings_side_lbl_pc">
								<span>TimeSheet</span>
							</div>
							<img id="offerings_icon_I_1" src="time_line_icon.png" srcset="time_line_icon.png 1x" />

						</div>
					</Link>
					{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && user.useraccess  === 2 && user.usertype === "staff" ) ? 

					<Link to={"/companies"}>
						<div id="dash_bdr_grp_expand" className='highlight2' title='Companies'>

							<div id="Dashboard_side_lbl_pc">
								<span>Companies</span>
							</div>
							<img id="dashboard_Icon_I_1" src="dashboard_Icon_I_1.png" srcset="dashboard_Icon_I_1.png 1x" />

						</div>
					</Link>
:""}
					<Link to={"/projects"}>
						<div id="com_grp_expand" className='highlight2' title='Projects'>
							<div id="Communities_side_lbl_pc">
								<span>Projects</span>
							</div>
							<img id="community_icon_I_1" src="community_icon_I_1.png" srcset="community_icon_I_1.png 1x" />

						</div>
					</Link>
					{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && user.useraccess  === 2 && user.usertype === "staff" ) ? 

					<Link to={"/clients"}>
						<div id="com_grp_expand_clients" className='highlight2' title='Clients'>
							<div id="Communities_side_lbl_pc_clients">
								<span>Clients</span>
							</div>
							<img id="community_icon_I_1_clients" src="clientstaff.png" srcset="clientstaff.png 1x" />

						</div>
					</Link>
:""}
					<Link to={"/staff"}>
						<div id="connection_grp_expand" className='highlight2' title='Staff'>
							<div id="My_Connection_side_lbl_pc">
								<span>Staff</span>
							</div>
							<img id="my_connections_icon_I_1" src="my_connections_icon_I_1.png" srcset="my_connections_icon_I_1.png 1x" />

						</div>
					</Link>
					{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user && user.useraccess  === 2 && user.usertype === "staff" ) ? 
					<Link to={"/users"}>

						<div id="offering_grp_expand" className='highlight2' title='User'>
							<div id="Offerings_side_lbl_pc">
								<span>Users</span>
							</div>
							<img id="offerings_icon_I_1" src="offerings_icon_I_1.png" srcset="offerings_icon_I_1.png 1x" />

						</div>
					</Link>
					:""}
					<div id="offering_grp_expand_" title='Log Out'>
						<div id="Offerings_side_lbl_pc_">
							<span>Log Out</span>
						</div>
						<img id="logout_icon_I" src="logout_icon_I.png" srcset="logout_icon_I.png 1x" />

					</div>
				</div>
			</div>
		</div>


	)
}

export default NavExt