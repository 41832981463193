import React, { useState, useEffect ,useContext} from 'react'
import "../../Styles/Mob/projectMob.css"
import ProjectPopup from '../PC/ProjectPopup'
import { UserContext } from '../UserContext';
import TaskPageMob from './TaskPageMob';


const ProjectMob = (props) => {

	// const [popUpData,setPopUpData] = useState()
	// const [selectedProjectMob] = useState(null)
	// const [project, setProject] = useState(UserContext)

	const [projectData, setProjectData] = useState([])
	const { user, setUser } = useContext(UserContext);

	const [selectedProject, setSelectedProject] = useState(null)
	const [showAddButton, setShowAddButton] = useState(true)



	useEffect(() => {
		if (user) {
			GetProject()
		props.setTaskPage(selectedProject && showAddButton)

		}
	}, [user,props.isAddPopupVisibleMob,selectedProject,showAddButton])

	const GetProject = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/project" + ((user && user.usertype === "staff" && user.useraccess >= 3) || (user && user.usertype === "staff" && parseInt(user.useraccess) === 2)  ? "/getProject" : (user && user.usertype === "client") ? "/getProjectByClient/" + user._id : "/getProjectByUser/" + user._id);
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setProjectData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}


	return (
		<div>
 {selectedProject ? <TaskPageMob isAddPopupVisibleMob={props.isAddPopupVisibleMob} searchText={props.searchText} showAddButton={(value)=>setShowAddButton(value)}  closeAddPopup={()=>props.closeAddPopupMob()} project={selectedProject} goBack={()=> {setSelectedProject(null)}}/> : 
 <div id="project_page_mob">
			<img id="over_all_bg_design" src="over_all_bg_design.png" srcset="over_all_bg_design.png 1x" />
			<div id="project_page_mob_scroll">
			{projectData.filter(rw=> (rw.projectName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.projectInfo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.contactPersonMobile.toString().includes(props.searchText.toLowerCase()) || rw.contactPersonName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec,index) => (
						<div style={{ position: "relative", display: 'inline-flex' }}>

				<div id='project_card_mob' onClick={() => setSelectedProject(rec)}>
					<svg class="card_over_all_bg_project_page_">
						<rect id="card_over_all_bg_project_page_" rx="10" ry="10" x="0" y="0" width="93vw" height="103">
						</rect>
					</svg>
					<img id="card_bg_design_project_page_mo" src="card_bg_design_project_page_mo.png" srcset="card_bg_design_project_page_mo.png 1x" />

					<svg class="pfp_behind_circle_project_page_mob" style={{ fill: rec.active === true ? "rgba(119,238,170,1)" : "White" }}>
						<ellipse id="pfp_behind_circle_project_page_mob" rx="45" ry="45" cx="45" cy="45">
						</ellipse>
					</svg>
					<img id="profile_pic_card_project_page_" src={
									rec.projectImageUrl && rec.projectImageUrl.length >10
										? "https://drive.google.com/uc?export=view&id=" +
										rec.projectImageUrl.split('/')[5]
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }} />

					<img title="Compant Name" id="manager_lbl_project_page_mob" src="manager_lbl_project_page_mob.png" srcset="manager_lbl_project_page_mob.png 1x" />

					<div id="Admin_lbl_project_page_mob">
						<span>{rec.companyName}</span>
					</div>
					<div id="Mohammed_Affan_lbl_staff_page_">
						<span>{rec.projectName}</span>
					</div>
					<div id="Start_time_projpage_mob">
						<span>{(rec.startDate).slice(0, 10)}</span>
					</div>
					<div id="date_proj_page_mob">
						<span>{(rec.endDate).slice(0, 10)}</span>
					</div>
					<div id="road_time_grp_mob">
						<svg class="road_bdr_mob">
							<rect id="road_bdr_mob" rx="5" ry="5" x="0" y="0" width="calc(100vw - 175px)" height="25">
							</rect>
						</svg>
						<svg class="proj_road_line_mob" >
							{/* <div id="proj_road_line_mob" >
							</div> */}
						</svg>
						{console.log(window.screen.width)}
						{/* {parseInt((screen.width*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100)) } */}
						<img title="Start Date" id="proj_startpage_mob" src="proj_startpage_mob.png" srcset="proj_startpage_mob.png 1x" style={{left:(parseInt(((window.screen.width - 190)*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100)) > (window.screen.width - 190) ? (window.screen.width - 190) : (parseInt(( (window.screen.width - 190) *(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100))+"px"}}/>
						<img title="End Date" id="finish_img_pro_page_mob" src="finish_img_pro_page_mob.png" srcset="finish_img_pro_page_mob.png 1x" />

					</div>
				</div>
				</div>
					))}
			</div>
			<ProjectPopup isOpen={props.isAddPopupVisibleMob && selectedProject === null} closePopup3={() => props.closeAddPopupMob(false)} />
		</div>
}
		</div>
		
	)
}

export default ProjectMob