import React, { useEffect ,useContext} from 'react'
import "../../Styles/Mob/taskCardMob.css"
import { UserContext } from '../UserContext'


const TaskCardMob = (props) => {
 
    const { user } = useContext(UserContext);

     
    useEffect(()=>{

    },[props])

  return (
    

         <div id="task_page__card_1_mob_1"  onClick={()=> { if ((user.usertype === "staff" && user.useraccess >= 5) || props.task.assignedToId === user._id || (user && user.usertype === "staff" && user.useraccess >= 3 && (props.teamData.filter(rw=> rw.staffUserId === user._id).length) > 0)) {props.setDisplayTask(); props.setShowData()} else alert("Access Denied") }}>
            
                        <svg class="card_over_all_bg_task_page_mob">
                            <rect id="card_over_all_bg_task_page_mob" rx="10" ry="10" x="0" y="0" width="174" height="182">
                            </rect>
                        </svg>
                        <img id="task_name_bg_card_1_task_page_" src="task_name_bg_card_1_task_page_.png" srcset="task_name_bg_card_1_task_page_.png 1x" />

                        <img title="Compant Name" id="developemtn_img_task_page_mob_" src="developemtn_img_task_page_mob_.png" srcset="developemtn_img_task_page_mob_.png 1x" />

                        <div id="development_lbl_task_page_mob_">
                            <span>{props.task.category}</span>
                        </div>
                        <div id="task_name_task_page_mob_1">
                            <span>{props.task.task}</span>
                        </div>
                        <img title="Compant Name" id="img_22_task_page_mob_1" src="img_22_task_page_mob_1.png" srcset="img_22_task_page_mob_1.png 1x" />

                        <div id="n_2_hrs_lbltaskmob_1">
                            <span>{props.task.plannedHours} Hours</span>
                        </div>
                        <img title="Compant Name" id="time_2_img_task_mob_1" src="time_2_img_task_mob_1.png" srcset="time_2_img_task_mob_1.png 1x" />

                        <div id="time_2_task_page_mob_1">
                            <span>{props.task.billedHours}</span>
                        </div>
                        <svg class="work_in_progress_bdr_task_page_mob" style={{ fill: props.task.status === "Unassigned" ? "#999999" : props.task.status === "Assigned" ? "#FFCC00" : props.task.status === "Work in Progress" ? "#3399FF" : props.task.status === "On Hold" ? "#FF9900" : props.task.status === "Completed" ? "#00CC66" : props.task.status === "Cancelled" ? "#FF0000" : "" }}>
                            <rect id="work_in_progress_bdr_task_page_mob" rx="10.5" ry="10.5" x="0" y="0" width="132" height="21">
                            </rect>
                        </svg>
                        <div id="work_in_progress_lbl_task_page">
                            <span>{props.task.status}</span>
                        </div>
                        <img title="Compant Name" id="assigned_to_pfp_task_page_mob_" src="assigned_to_pfp_task_page_mob_.png" srcset="assigned_to_pfp_task_page_mob_.png 1x" />

                        <div id="assigned_to_lbl_task_page__mob">
                            <span>{props.task.assignedToName}</span>
                        </div>
                    </div>
   
  )
}

export default TaskCardMob