import React, { useEffect, useState, useContext } from 'react'
import "../../Styles/Mob/clientDashBoardMob.css"
import Pie from '../PC/Pie'
import TaskBarChartMob from './TaskBarChartMob'
import ProjectPopup from '../PC/ProjectPopup'
import PopUpTask from '../PC/PopUpTask'
import Team from '../PC/Team'
import { UserContext } from '../UserContext';


const ClientDashBoardMob = (props) => {
    const [tasksData, setTasksData] = useState([])
    const [projectData, setProjectData] = useState([])
    const [staffData, setStaffData] = useState([])


    // const [addStaffPopup, setAddStaffPopup] = useState(false)
    const [addProjectPopUp, setAddProjectPopUp] = useState(false)
    // const [addTaskPopup, setAddTaskPopup] = useState(false)

    const [showUnassigned, setShowUnassigned] = useState(true)
    const [showAssigned, setShowAssigned] = useState(true)
    const [showWip, setShowWip] = useState(true)
    const [showOnHold, setShowOnHold] = useState(true)
    const [showCompleted, setShowCompleted] = useState(true)
    const [showCancelled, setShowCancelled] = useState(true)
    const [visibleTasks, setVisibleTasks] = useState([])

    const [selectedProject, setSelectedProject] = useState({})
    const [display, setDisplay] = useState("none")
    const [selectedStaff, setSelectedStaff] = useState({});
    const [displayTask, setDisplayTask] = useState("none")
    const [teamData, setTeamData] = useState([])
    const { user, setUser } = useContext(UserContext);






    useEffect(() => {
        if (user) {
            GetProject()
        }
    }, [user, addProjectPopUp])

    useEffect(() => {
        if (selectedProject._id) {

            GetStaff(selectedProject._id)
        }
    }, [display])

    useEffect(() => {
        if (selectedProject._id && user) {

            GetTasks(selectedProject._id)
        }
    }, [user, displayTask])

    // useEffect(() => {
    //     // if(selectedProject){
    //     // GetTasks(selectedProject._id)
    //     // }
    // }, [displayTask])

    useEffect(() => {

        if (selectedProject._id) {

            GetTeam(selectedProject._id)
        }
    }, [display, selectedProject._id])

    useEffect(() => {
        var tasks = tasksData;
        if (showUnassigned !== true) {
            tasks = tasks.filter(rw => rw.status !== "Unassigned")
        }
        if (showAssigned !== true) {
            tasks = tasks.filter(rw => rw.status !== "Assigned")
        }
        if (showWip !== true) {
            tasks = tasks.filter(rw => rw.status !== "Work in Progress")
        }
        if (showOnHold !== true) {
            tasks = tasks.filter(rw => rw.status !== "On Hold")
        }
        if (showCompleted !== true) {
            tasks = tasks.filter(rw => rw.status !== "Completed")
        }
        if (showCancelled !== true) {
            tasks = tasks.filter(rw => rw.status !== "Cancelled")
        }
        setVisibleTasks(tasks.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
    }, [showUnassigned, showAssigned, showWip, showOnHold, showCompleted, showCancelled, tasksData])

    const GetProject = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/project" + ((user && user.usertype === "staff" && user.useraccess >= 3) ? "/getProject" : (user && user.usertype === "client") ? "/getProjectByClient/" + user._id : "/getProjectByUser/" + user._id);
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setProjectData(repos.data)


                    if (repos.data.length > 0) {
                        //    console.log(repos.data)
                        GetTasks(repos.data[0]._id)
                        setSelectedProject(repos.data[0])
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetTasks = (projectId) => {
        // alert(projectId)
        let apiURL = process.env.REACT_APP_API_URL + "/task/getTasks/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setTasksData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetStaff = (projectId) => {
        let apiURL = process.env.REACT_APP_API_URL + "/users/getStaffUsers/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetTeam = (projectId) => {
        let apiURL = process.env.REACT_APP_API_URL + "/team/GetTeam/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setTeamData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    // const data = tasksData.map((rec) => {
    //     let planned = rec.plannedHours;
    //     let billed = rec.billedHours
    //     console.log(planned)
    //     console.log(billed)
    // })
    // console.log((data))

    // console.log(tasksData)
    // const max = arr.reduce()
    // console.log(max)
    // console.log(tasksData)
    const max = Math.max(...tasksData.map((rec, rew) => {
        return (
            rec.plannedHours > rew.billedHours ? rec : rew
        )
    }))
    //console.log(max)


    const maxBilled = Math.max(...tasksData.map((rec) =>
        rec.billedHours))
    //console.log(maxBilled)

    const maxPlanned2 = Math.max(...tasksData.map((rec) =>
        rec.plannedHours))
    //console.log(maxPlanned2)

    const maxHours = maxBilled > maxPlanned2 ? maxBilled : maxPlanned2
    //console.log(maxHours)
    // const max ={tasksData.reduce((prev, current) => (prev.plannedHours > current.billedHours) ? prev : current)}
    // console.log(max)

    return (
        <div id="client_dashboard_mob">
            <img id="over_all_bg_design_dash_mob" src="over_all_bg_design_dash_mob.png" srcset="over_all_bg_design_dash_mob.png 1x" />
            <div id="dashboard_overall_scroll">
                {teamData.filter(rw => (rw.staffName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (

                    <div id="assigned_staff_grp_card_dash_m" onClick={() => { setDisplay("Edit"); setSelectedStaff(rec) }}>
                        {/* <svg class="project_left_bdr_over_all_mob">
                    <rect id="project_left_bdr_over_all_mob" rx="10" ry="10" x="0" y="0" width="341" height="127">
                    </rect>
                </svg> */}
                        <svg class="card_overall_bdr_assign_mob">
                            <rect id="card_overall_bdr_assign_mob" rx="10" ry="10" x="0" y="0" width="285" height="84">
                            </rect>
                        </svg>
                        <img id="overall_pfp_bdr_assign_carddas" src="overall_pfp_bdr_assign_carddas.png" srcset="overall_pfp_bdr_assign_carddas.png 1x" />

                        <svg class="pfp_bdr_assign_carddash_mob" style={{fill: rec.active === true ? "rgba(119,238,170,1)" : "White"}}>
                            <ellipse id="pfp_bdr_assign_carddash_mob" rx="37" ry="37" cx="37" cy="37">
                            </ellipse>
                        </svg>
                        <img id="profile_pic_img_assign_carddas" src={
                            rec.profilePicUrl && rec.profilePicUrl.length > 10
                                ? "https://drive.google.com/uc?export=view&id=" +
                                rec.profilePicUrl.split('/')[5]
                                :
                                "default_image.png"
                        }
                            style={{ borderRadius: "75px", border: "1px solid black"  }} />

                        <div id="Mohammed_Affan_lbl_assign_card_mob">
                            <span>{rec.staffName}</span>
                        </div>
                        <svg class="start_end_bdr_assigndash_mob">
                            <rect id="start_end_bdr_assigndash_mob" rx="5" ry="5" x="0" y="0" width="174" height="23">
                            </rect>
                        </svg>
                        <div id="start_end_date_assign_carddash">
                            <span>{rec.startDate.toString().slice(0, 10)}    {rec.endDate.toString().slice(0, 10)}</span>
                        </div>
                        <svg class="orange_bdr_assigned_mob">
                            <rect id="orange_bdr_assigned_mob" rx="0" ry="0" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="blue_bdr_assigneddash_mob">
                            <rect id="blue_bdr_assigneddash_mob" rx="0" ry="0" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="yellow_bdr_assigneddash_mob">
                            <rect id="yellow_bdr_assigneddash_mob" rx="0" ry="0" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="green_bdr_assigned_mob">
                            <rect id="green_bdr_assigned_mob" rx="0" ry="0" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="red_bdr_assigneddash_mob">
                            <rect id="red_bdr_assigneddash_mob" rx="0" ry="0" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <div id="yellow_11_assigneddash_mob">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Assigned").length}</span>
                        </div>
                        <div id="blue_11_assigned_dash_mob">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Work in Progress").length}</span>
                        </div>
                        <div id="orange_11_assigned_dash_mob">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "On Hold").length}</span>
                        </div>
                        <div id="green_11_assigned_dash_mob">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Completed").length}</span>
                        </div>
                        <div id="red_11_assigned_dash_mob">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Cancelled").length}</span>
                        </div>


                    </div>

                ))}
                <div id="Projects_lbl_client_dash_mob">
                    <span>Team</span>
                    
                {(user && user.useraccess >= 5 && user.usertype === "staff") || (user && user.useraccess >= 3 && user.usertype === "staff" && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client") ?
                    
                    <button onClick={() => setDisplay("Add")} style={{ cursor: "pointer" }}>
                        <svg class="project_add_bdr_dash_mob">
                            <rect id="project_add_bdr_dash_mob" rx="3" ry="3" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="project_add_path_dash_mob" viewBox="6.75 6.75 15 15">
                            <path id="project_add_path_dash_mob" d="M 21.75 15.24998474121094 L 15.24998474121094 15.24998474121094 L 15.24998474121094 21.75 L 13.25001621246338 21.75 L 13.25001621246338 15.24998474121094 L 6.75 15.24998474121094 L 6.75 13.25001621246338 L 13.25001525878906 13.25001621246338 L 13.25001525878906 6.75 L 15.24998474121094 6.75 L 15.24998474121094 13.25001525878906 L 21.75 13.25001525878906 L 21.75 15.24998474121094 Z">
                            </path>
                        </svg>
                    </button>
                    :""}
                    {/* <Team/> */}
                    {selectedProject && <Team display={display} selectedStaff={selectedStaff} staffData={staffData} project={selectedProject} closePopupTeam={() => setDisplay("none")} teamData={teamData} />}

                </div>
                <div id="bar_chart_grp_client_dash_mob">
                    {/* <svg class="bar_chart_behind_bdr_dash_mob">
                    <rect id="bar_chart_behind_bdr_dash_mob" rx="10" ry="10" x="0" y="0" width="364.437" height="165">
                    </rect>
                </svg> */}

                    {visibleTasks.filter(rw => (rw.task.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                        <TaskBarChartMob data={rec} maxHours={maxHours} project={selectedProject} teamData={teamData} refresh={()=>GetTasks(selectedProject._id)} />
                    ))}

                    <PopUpTask display={displayTask} closePopup={() => setDisplayTask("none")} project={selectedProject} teamData={teamData}/>


                </div>
                <div id="pie_chart_grp_dash_mob">
                    {/* <img id="pie_chart_client_dash_mob" src="pie_chart_client_dash_mob.png" srcset="pie_chart_client_dash_mob.png 1x" /> */}
                    <Pie data={[
                        { title: 'Unassigned', value: tasksData.filter(i => i.status === "Unassigned").length, color: '#999999', },
                        { title: 'Assigned', value: tasksData.filter(i => i.status === "Assigned").length, color: '#FFCC00' },
                        { title: 'Work In Progress', value: tasksData.filter(i => i.status === "Work in Progress").length, color: '#3399FF' },
                        { title: 'On Hold', value: tasksData.filter(i => i.status === "On Hold").length, color: '#FF9900' },
                        { title: 'Completed', value: tasksData.filter(i => i.status === "Completed").length, color: '#00CC66' },
                        { title: 'Cancelled', value: tasksData.filter(i => i.status === "Cancelled").length, color: '#FF0000' },
                    ]} />

                </div>
                <div id="Total_Tasks_-_100_dash_mob">
                    <span>Total Tasks - </span>
                    <span style={{ fontStyle: "normal", fontWeight: "bold" }}>{tasksData.length}</span>
                </div>
                <div id="scroll_project_name_dash_mob">
                    <div style={{ position: "relative", display: "inline-flex", marginLeft: "10px" }} >

                        {projectData.filter(rw => (rw.projectName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                            <div style={{ backgroundColor: (rec._id === selectedProject._id ? "darkturquoise" : ""), borderRadius: "60px" }}>
                                <div id="project_grp_card_1" onClick={() => { GetTasks(rec._id); setSelectedProject(rec) }}>
                                    <svg class="card_bdr_client_dash_mob">
                                        <rect id="card_bdr_client_dash_mob" rx="27" ry="27" x="0" y="0" width="222" height="58">
                                        </rect>
                                    </svg>
                                    <img id="profile_pfp_img_client_dash_mo" src={
                                    rec.projectImageUrl && rec.projectImageUrl.length > 10
                                        ? "https://drive.google.com/uc?export=view&id=" +
                                        rec.projectImageUrl.split('/')[5]
                                        :
                                        "default_image.png"
                                }
                                    style={{ borderRadius: "75px", border: "3px solid " + (rec.active === true ? "rgba(119,238,170,1)" : "White") }} />
                                    <div id="Project_Name_client_dash_mob">
                                        <span>{rec.projectName}</span>
                                    </div>
                                    <div id="Start_time_projpage_client_das">
                                        <span>{(rec.startDate).toString().slice(0, 10)}</span>
                                    </div>
                                    <div id="date_proj_page_clientdash_mob">
                                        <span>{(rec.endDate).toString().slice(0, 10)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                {(user && user.useraccess >= 3 && user.usertype === "staff") || (user && user.usertype === "client") ?

                    <button onClick={() => setAddProjectPopUp(true)} style={{ cursor: "pointer" }}>
                        <svg class="project_add_bdr__dash_mob">
                            <rect id="project_add_bdr__dash_mob" rx="16" ry="16" x="0" y="0" width="32" height="58">
                            </rect>
                        </svg>
                        <svg class="project_add_path_dash_mob_d" viewBox="6.75 6.75 26.294 26.294">
                            <path id="project_add_path_dash_mob_d" d="M 33.0439453125 21.64987564086914 L 21.64987564086914 21.64987564086914 L 21.64987564086914 33.0439453125 L 18.14407157897949 33.0439453125 L 18.14407157897949 21.64987564086914 L 6.75 21.64987564086914 L 6.75 18.14407157897949 L 18.14406967163086 18.14407157897949 L 18.14406967163086 6.75 L 21.64987564086914 6.75 L 21.64987564086914 18.14406967163086 L 33.0439453125 18.14406967163086 L 33.0439453125 21.64987564086914 Z">
                            </path>
                        </svg>
                    </button>
                    : ""}
                <ProjectPopup isOpen={addProjectPopUp} closePopup3={(value) => setAddProjectPopUp(value)} />


                <div id="unassigned_grp_mob">
                    <div id="Unassigned_lbl_client_dash_mob">
                        <span>Unassigned {tasksData.filter(rw => rw.status === "Unassigned").length}</span>
                    </div>
                    <svg class="unassigned_grey_dash_mob">
                        <rect id="unassigned_grey_dash_mob" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="assigned_grp_mob">
                    <div id="Assigned_lbl_client_dash_mob">
                        <span>Assigned {tasksData.filter(rw => rw.status === "Assigned").length}</span>
                    </div>
                    <svg class="yellow_assigned_client_dash_mo">
                        <rect id="yellow_assigned_client_dash_mo" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="WIP_grp_mob">
                    <div id="Work_in_progress_client_dash_m">
                        <span>Work in progress {tasksData.filter(rw => rw.status === "Work in Progress").length}</span>
                    </div>
                    <svg class="blue_work_in_progress_dash_mob">
                        <rect id="blue_work_in_progress_dash_mob" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="completed_grp_mob">
                    <div id="Completed_lbl_client_dash_mob">
                        <span>Completed {tasksData.filter(rw => rw.status === "Completed").length}</span>
                    </div>
                    <svg class="completed_greeen_dash_mob">
                        <rect id="completed_greeen_dash_mob" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="cancelled_grp_mob">
                    <div id="Cancelled_lbl_client_dash_mob">
                        <span>Cancelled {tasksData.filter(rw => rw.status === "Cancelled").length}</span>
                    </div>
                    <svg class="red_cancelled_client_dash_mob">
                        <rect id="red_cancelled_client_dash_mob" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="on_hold_grp_mob">
                    <div id="On_Hold_lbl_client_dash_mob">
                        <span>On Hold {tasksData.filter(rw => rw.status === "On Hold").length}</span>
                    </div>
                    <svg class="orange_on_hold_dash_mob">
                        <rect id="orange_on_hold_dash_mob" rx="0" ry="0" x="0" y="0" width="10.562" height="10.562">
                        </rect>
                    </svg>
                </div>
                <div id="eye_grp_dash_mob">
                    {(user && user.useraccess >= 5 && user.usertype === "staff") || (user && user.useraccess >= 3 && user.usertype === "staff" && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client") ?

                        <button onClick={() => setDisplayTask("Add")} style={{ cursor: "pointer" }}>
                            <svg class="add_task_bar_graph_dash_mob">
                                <rect id="add_task_bar_graph_dash_mob" rx="3" ry="3" x="0" y="0" width="20" height="20">
                                </rect>
                            </svg>
                            <svg class="task_add_chat_bar_dash_mob" viewBox="6.75 6.75 10 10">
                                <path id="task_add_chat_bar_dash_mob" d="M 16.75 12.41665744781494 L 12.41665744781494 12.41665744781494 L 12.41665744781494 16.75 L 11.08334541320801 16.75 L 11.08334541320801 12.41665744781494 L 6.75 12.41665744781494 L 6.75 11.08334541320801 L 11.08334445953369 11.08334541320801 L 11.08334445953369 6.75 L 12.41665744781494 6.75 L 12.41665744781494 11.08334445953369 L 16.75 11.08334445953369 L 16.75 12.41665744781494 Z">
                                </path>
                            </svg>
                        </button>
                        : ""}
                    <div id="Tasks_lbl_chart_bar_dash_mob">
                        <span>Tasks</span>
                    </div>

                    <img id="eye_unassigned_dash_mob" src={showUnassigned === true ? "eye_unassigned_dash_mob.png" : "eye_unassigned_hidden.png"} onClick={() => setShowUnassigned(!showUnassigned)} />

                    <img id="eye_assigned_dash_mob" src={showAssigned === true ? "eye_assigned_dash_mob.png" : "eye_assigned_hidden.png"} onClick={() => setShowAssigned(!showAssigned)} />

                    <img id="eye_work_in_progress_dash_mob" src={showWip === true ? "eye_work_in_progress_dash_mob.png" : "eye_work_in_progress_hidden.png"} onClick={() => setShowWip(!showWip)} />

                    <img id="eye_on_hold_dash_mob" src={showOnHold === true ? "eye_on_hold_dash_mob.png" : "eye_on_hold_hidden.png"} onClick={() => setShowOnHold(!showOnHold)} />

                    <img id="eye_completed_dash_mob" src={showCompleted === true ? "eye_completed_dash_mob.png" : "eye_completed_hidden.png"} onClick={() => setShowCompleted(!showCompleted)} />

                    <img id="eye_cancelled_dash_mob" src={showCancelled === true ? "eye_cancelled_dash_mob.png" : "eye_cancelled_hidden.png"} onClick={() => setShowCancelled(!showCancelled)} />

                </div>
            </div>
        </div>

    )
}

export default ClientDashBoardMob
