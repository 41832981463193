import React, { useEffect, useContext } from 'react'
import "../../Styles/Mob/billedhoursCardMob.css"
import { UserContext } from '../UserContext'


const BilledHoursCardMob = (props) => {

	const { user } = useContext(UserContext);

	useEffect(() => {

	}, [props.billedHours, user])


	return (
		<div id="time_sheet_bill_card">
			<div id="billed_hrs_card_mob">
				<svg class="card_over_all_bg_task_page_mo__dh">
					<linearGradient id="card_over_all_bg_task_page_mo__dh" spreadMethod="pad" x1="-0.27" x2="1.112" y1="-0.415" y2="1.279">
						<stop offset="0" stop-color="#cee2fe" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="card_over_all_bg_task_page_mo__dh" rx="10" ry="10" x="0" y="0" width="174" height="182">
					</rect>
				</svg>

				<div id="total_build_hrs_timesheet">
					<span>Billed Hours</span>
				</div>

				<div id="Scroll_details_total_hrs_times" >
					{props.billedHours.map((rec) => (

						<div id="affan_grp_timesheet" style={{backgroundColor:rec.hours === 0 && "red",color:rec.hours === 0 && "white"}}>
							<div id="module_timeline_page_name">
								<span>{rec.name}</span>
							</div>
							<div id="module_timeline_page_amount">
								<span style={{ color: (rec.hours > 0 && rec.hours < 8) && "red" }}>{rec.hours}</span>
							</div>
						</div>

					))}
				</div>


				<svg class="divider" viewBox="0 0 158 1">
					<path id="divider" d="M 0 0 L 158 0">
					</path>
				</svg>
			</div>
		</div>

	)
}

export default BilledHoursCardMob