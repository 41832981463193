import React, {useContext, useEffect,  useState } from 'react'
import "../../Styles/Mob/topBarMob.css"
import TopUsers from './TopUsers'
import { UserContext } from '../UserContext';
import { Switch, useLocation } from 'react-router-dom';


const TopBarMob = (props) => {
 
	let location = useLocation()
	const { user, setUser } = useContext(UserContext);

	useEffect(()=>{
		// console.log(window.location.pathname)
			},[user,location.pathname])

  return (
    <div id="top_bar">
{(((props.isAddVisible === true) && (user && user.usertype === "staff" && user.useraccess >= 5 && (window.location.pathname !== "/dashboardmob")))||((props.isAddVisible === true ) && (user && user.usertype === "staff"  && (window.location.pathname === "/projectmob"  || window.location.pathname === "/timesheetmob"))) || (user.usertype === "client" && (window.location.pathname === "/projectmob" || window.location.pathname === "/clientsmob")) || ((props.isAddVisible === true) &&(user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ))) &&  

	<button id="add_btn_top_bar_mob" onClick={props.openAddPopupMob}>
		<svg class="add_bg_top">
			<rect id="add_bg_top" rx="8" ry="8" x="0" y="0" width="36" height="36">
			</rect>
		</svg>
		<svg class="plus" viewBox="6.75 6.75 18 18">
			<path id="plus" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
			</path>
		</svg>
	</button>
	}
	<input placeholder="Search Content" class="search_place_inout_project_asi" value={props.searchText} onChange={(e)=>props.setSearchText(e.target.value)}/>
		
	<img id="search_img_prohect_asignment" src="search_img_prohect_asignment.png" srcset="search_img_prohect_asignment.png 1x"/>
		
</div>
  )
}

export default TopBarMob