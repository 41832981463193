import React from 'react'
import "../../Styles/PC/timeSheetGrid.css"

const TimeSheetGrid = (props) => {
    return (

        <div id="heading_and_card_1_time_sheet_">
            <div id="detIAIL_HEADING_timeline_page">
                <svg class="header_1_timeline_page">
                    <rect id="header_1_timeline_page" rx="15" ry="15" x="0" y="0" width="1159" height="41">
                    </rect>
                </svg>
                <div id="module_lbl_timeline_page">
                    <span>Module</span>
                </div>
                <div id="hours_lbl_timeline_page">
                    <span>Hours</span>
                </div>
                <div id="staff_lbl_timeline_page">
                    <span>Staff</span>
                </div>
                <div id="project_lbl_timeline_page">
                    <span>Project</span>
                </div>
                <div id="date_timeline_page">
                    <span>Date</span>
                </div>
                <div id="status_lbl_timeline_page">
                    <span>Status</span>
                </div>
                <div id="category_timeline_page">
                    <span>Category</span>
                </div>
                <div id="task_lbl_timeline_page">
                    <span>Task</span>
                </div>
            </div>
            

            <div id="Scroll_timesheet_page_scroll">
            {props.timeSheetData.map((rec) =>  {
                
		return(
                <div id="card_1_timeline_page">
                    <svg class="card_1_bg_timeline_page">
                        <rect id="card_1_bg_timeline_page" rx="15" ry="15" x="0" y="0" width="1159" height="41">
                        </rect>
                    </svg>
                    <div id="md_affan_timeline_page">
                        <span>{rec.project}</span>
                    </div>
                    <div id="module_timeline_page">
                        <span>{rec.module}</span>
                    </div>
                    <div id="hours_timeline_page">
                        <span>{rec.billedHours}</span>
                    </div>
                    <div id="date_timeline_page_dn">
                        <span>{(rec.taskDate).toString().slice(0,10)}</span>
                    </div>
                    <svg class="status_color" style={{fill:rec.status === "Work in Progress" ? "#3399FF" : rec.status === "On Hold" ? "#FF9900" :  rec.status === "Completed" ? "#00CC66" :  rec.status === "Cancelled" ? "#FF0000" : "" }}>
                        <rect id="status_color" rx="15" ry="15" x="0" y="0" width="122" height="41">
                        </rect>
                    </svg>
                    <div id="status_timeline_page">
                        <span>{rec.status}</span>
                    </div>
                    <div id="task_date_timeline_page">
                        <span>{rec.task}</span>
                    </div>
                    <div id="documantation_timeline_page">
                        <span>{rec.category}</span>
                    </div>
                    <div id="doc_lbl_timeline_page">
                        <span>{rec.billedToName}</span>
                    </div>
                </div>
                )})}
            </div>
        </div>
    )
}

export default TimeSheetGrid