import React, { useEffect, useState, useContext } from "react";
import "../../Styles/PC/timeSheet.css";
import { UserContext } from "../UserContext";
// import { Switch } from 'antd'
import CardGrid from "./CardGrid";
import Switch from "@mui/material/Switch";
import TimeSheetGrid from "./TimeSheetGrid";
import Item from "antd/es/list/Item";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const TimeSheet = (props) => {
  const [tasksData, setTasksData] = useState([]);
  const { user } = useContext(UserContext);

  const [taskDate, setTaskDate] = useState(
    new Date().getFullYear() +
      "-" +
      parseInt(new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      parseInt(new Date().getDate()).toString().padStart(2, "0")
  );
  const [billedHours, setBilledHours] = useState();
  const [status, setStatus] = useState("");
  const [taskId, setTaskId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [timeSheetData, setTimeSheetData] = useState([]);

  const [switchState, setSwitchState] = useState(true);

  useEffect(() => {
    if (user) {
      GetTasks();
      GetTimeSheet();
    }
  }, [user]);

  const addTimeSheet = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/timesheet/addTimeSheet";
    let apiParams = {
      method: "POST",
      headers: {
        projectid: projectId,
        project: tasksData.filter((rew) => rew.projectId === projectId)[0]
          .project,
        taskid: taskId,
        task:
          taskId === "Leave"
            ? "Leave"
            : tasksData.filter((rec) => rec._id === taskId)[0].task,
        moduleid:
          taskId === "Leave"
            ? "Leave"
            : tasksData.filter((rec) => rec._id === taskId)[0].moduleId,
        module:
          taskId === "Leave"
            ? "Leave"
            : tasksData.filter((rec) => rec._id === taskId)[0].module,
        categoryid:
          taskId === "Leave"
            ? "Leave"
            : tasksData.filter((rec) => rec._id === taskId)[0].categoryId,
        category:
          taskId === "Leave"
            ? "Leave"
            : tasksData.filter((rec) => rec._id === taskId)[0].category,
        taskdate: taskDate,
        billedhours: billedHours,
        billedtoid:
          taskId === "Leave"
            ? user._id
            : tasksData.filter((rec) => rec._id === taskId)[0].assignedToId,
        billedtoname:
          taskId === "Leave"
            ? user.name
            : tasksData.filter((rec) => rec._id === taskId)[0].assignedToName,
        status: taskId === "Leave" ? "Leave" : status,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.message) {
          clearData();
          alert("TimeSheet Added Successfully!!");

          GetTimeSheet();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const GetTimeSheet = () => {
    if (user) {
      let apiURL =
        process.env.REACT_APP_API_URL +
        "/timesheet" +
        (user && user.usertype === "staff"
          ? "/getTimeSheet"
          : user && user.usertype === "client"
          ? "/getTimeSheetByProject/" + user._id
          : "");
      let apiParams = {
        method: "GET",
      };

      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            setTimeSheetData(repos.data);
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    }
  };

  const GetTasks = () => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/task/getTasksByStaff/" + user._id;
    let apiParams = {
      method: "GET",
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setTasksData(repos.data);
          if (repos.data[0]) {
            setTaskId(repos.data[0]._id);
          }
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  // const updateTask = () => {
  //     let apiURL = process.env.REACT_APP_API_URL + "/task/editTask";

  //     let apiParams = {
  //         method: "POST",
  //         headers: {
  //            taskid:props.data._id,
  //            projectid: props.project._id,
  //            project:props.project.projectName,
  //            task: task,
  //            moduleid: moduleId,
  //            module: moduleData.filter((rec) => rec._id === moduleId)[0].module,
  //            categoryid: categoryId,
  //            category: categoryData.filter((rec) => rec._id === categoryId)[0].category,
  //            description: description,
  //            status: status,
  //         //    plannedhours: plannedHours,
  //         //    assignedtoid:staffUserId,
  //         //    assignedtoname:staffData.filter((rec) => rec.staffUserId === staffUserId)[0]?staffData.filter((rec) => rec.staffUserId === staffUserId)[0].staffName:"",

  //         },
  //     };

  //     fetch(apiURL, apiParams)
  //         .then((res) => (res.status === 200 ? res.json() : res.text()))
  //         .then((repos) => {
  //             if (repos.data) {
  //                 alert("Task Details Updated Successfully!!");

  //             } else alert(repos.message || repos);
  //         })
  //         .catch((err) => alert(err));

  // }
  const clearData = () => {
    return setBilledHours(""), setStatus(""), setTaskId(""), setProjectId("");
  };

  return (
    <div id="timeline_page">
      <img
        id="over_all_bg_timeline_page"
        src="over_all_bg_timeline_page.png"
        srcset="over_all_bg_timeline_page.png 1x"
      />

      <div id="add_timesheet_bar">
        <img
          id="calendar_timeline_page"
          src="calendar_timeline_page.png"
          srcset="calendar_timeline_page.png 1x"
        />
        <input
          tabIndex={1}
          type="date"
          max={
            new Date().getFullYear() +
            "-" +
            parseInt(new Date().getMonth() + 1)
              .toString()
              .padStart(2, "0") +
            "-" +
            parseInt(new Date().getDate()).toString().padStart(2, "0")
          }
          value={taskDate}
          onChange={(e) => {
            setTaskDate(e.target.value);
          }}
          class="date_calender"
        />

        <select
          tabIndex={2}
          class="project_drop_down_timeline_page"
          value={projectId}
          onChange={(e) => {
            setProjectId(e.target.value);
            setTaskId("");
          }}
        >
          <option value=""> --Projects--</option>
          {/* {console.log(tasksData &&([...new Set(tasksData.map(rew=>rew.projectId) )]|| []))} */}
          {/* {console.log((tasksData.map(rew=>{return{projectId:rew.projectId,project:rew.project}})))} */}
          {tasksData &&
            ([...new Set(tasksData.map((rew) => rew.projectId))] || []).map(
              (rec) => (
                <option value={rec}>
                  {tasksData &&
                    tasksData.filter((rew) => rew.projectId === rec)[0].project}
                </option>
              )
            )}
        </select>

        <select
          tabIndex={3}
          class="task_drop_down_timeline_page"
          value={taskId}
          onChange={(e) => {
            setTaskId(e.target.value);
            e.target.value === "Leave" && setBilledHours(8);
          }}
        >
          <option value=""> --Tasks--</option>
          <option value="Leave">Leave</option>

          {(
            tasksData && tasksData.filter((rew) => rew.projectId === projectId)
          ).map((rec) => (
            <option value={rec._id}>{rec.task}</option>
          ))}
        </select>

        <input
          tabIndex={4}
          placeholder="Hours"
          type="number"
          class="hours_inout_timeline_page"
          value={billedHours}
          onChange={(e) => setBilledHours(e.target.value)}
        />

        <select
          tabIndex={5}
          class="status_drop_down_timeline_page"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value=""> --Status--</option>
          <option value="Work in Progress">Work in Progress</option>
          <option value="On Hold">On Hold</option>
          <option value="Completed">Completed</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        <button
          tabIndex={6}
          id="add_btn_timeline_page"
          onClick={() => {
            if (
              (status === "" && taskId != "Leave") ||
              (projectId === "" && taskId != "Leave") ||
              taskId === "" ||
              billedHours === "" ||
              !Date.parse(taskDate)
            ) {
              alert("Select all fields!");
            } else {
              addTimeSheet();
              clearData();
            }
          }}
        >
          <svg class="add_bg_timeline_page">
            <rect
              id="add_bg_timeline_page"
              rx="8"
              ry="8"
              x="0"
              y="0"
              width="68"
              height="36"
            ></rect>
          </svg>
          <svg class="Icon_ionic-timeline_page" viewBox="6.75 6.75 18 18">
            <path
              id="Icon_ionic-timeline_page"
              d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z"
            ></path>
          </svg>
          <div id="add_timeline_page">
            <span>Add</span>
          </div>
        </button>
        <button tabIndex={7} id="excel_btn_timeline_page">
          <img src="excel.png" id="excel_bg_timeline_page" />
          {/* <svg class="excel_bg_timeline_page">
                        <rect id="excel_bg_timeline_page" rx="8" ry="8" x="0" y="0" width="73" height="36">
                        </rect>
                    </svg>
                    <svg class="Icon_ionic-timeline_page" viewBox="6.75 6.75 18 18">
                        <path id="Icon_ionic-timeline_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
                        </path>
                    </svg> */}
          <div id="add_timeline_page">
            <span>Export</span>
          </div>
        </button>
        <div id="time_sheet_switch">
          Grid
          <Switch
            tabIndex={7}
            {...label}
            size="small"
            checked={switchState}
            onChange={(e) => {
              setSwitchState(e.target.checked);
            }}
          />
          Card
          {/* <Switch
                            defaultChecked={true}
                            checkedChildren="Card"
                            unCheckedChildren="Grid" 
                            value={switchState}
                            onChange={(e) => {setSwitchState(e.target.value)}}
                        /> */}
        </div>
        <div>
          {switchState === true ? (
            <CardGrid
              timeSheetData={timeSheetData.filter(
                (rw) =>
                  rw.project
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.task
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.module
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.category
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.billedToName
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.status
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase())
              )}
            />
          ) : (
            <TimeSheetGrid
              timeSheetData={timeSheetData.filter(
                (rw) =>
                  rw.project
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.task
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.module
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.category
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.billedToName
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()) ||
                  rw.status
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase())
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeSheet;
