import React from 'react'
import "../../Styles/PC/projectPage.css"
import { useState, useEffect ,useContext} from "react"
import ProjectPopup from './ProjectPopup';
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom'
import TaskPage from './TaskPage';


const ProjectPage = (props) => {

	// const [addProjectPopup, setAddProjectPopup] = useState(false);
	// const [showPopUp,setShowPopUp] = useState(false)
	// const [popUpData,setPopUpData] = useState()

	// const [project, setProject] = useState(UserContext)

	const { user, setUser } = useContext(UserContext);
	// const { showAddButton, setShowAddButton } = useContext(UserContext);


	const [projectData, setProjectData] = useState([])
	
	const [selectedProject, setSelectedProject] = useState(null)
	const [showAddButton, setShowAddButton] = useState(true)

	
	useEffect(() => {
		if(user){
			GetProject()
		props.setTaskPage(selectedProject && showAddButton)
		// console.log(selectedProject,showAddButton)
		
		}
	}, [user,props.isAddPopupVisible ,selectedProject,showAddButton])

	// const GetProject = () => {
	// 	let apiURL = process.env.REACT_APP_API_URL + "/project/getProject";
	// 	let apiParams = {
	// 		method: "GET",

	// 	};

	// 	fetch(apiURL, apiParams)
	// 		.then((res) => (res.status === 200 ? res.json() : res.text()))
	// 		.then((repos) => {
	// 			if (repos.data) {

	// 				setProjectData(repos.data)

	// 			} else alert(repos.message || repos);
	// 		})
	// 		.catch((err) => alert(err));

	// }

	const GetProject = async() => {
		let apiURL = process.env.REACT_APP_API_URL + "/project" + ((user && user.usertype === "staff" && user.useraccess >= 3) || (user && user.usertype === "staff" && parseInt(user.useraccess) === 2)  ? "/getProject" : (user && user.usertype === "client") ? "/getProjectByClient/" + user._id : "/getProjectByUser/" + user._id);
		let apiParams = {
			method: "GET",

		};

		await fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setProjectData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	return (
	<div>
		{selectedProject?
		<TaskPage isAddPopupVisible={props.isAddPopupVisible} searchText={props.searchText} showAddButton={(value)=>setShowAddButton(value)} closeAddPopup={()=>props.closeAddPopup()} project={selectedProject} goBack={()=> {setSelectedProject(null)}}/>:
			<div id="project_page">
				<img id="over_all_bg_project_page" src="over_all_bg_project_page.png" srcset="over_all_bg_project_page.png 1x" />

				{/* <button id="add_btn_project_page" onClick={() => setAddProjectPopup(true)}>
					<svg class="add_bg_project_page">
						<rect id="add_bg_project_page" rx="8" ry="8" x="0" y="0" width="111" height="36">
						</rect>
					</svg>
					<svg class="Icon_ionic-project_page" viewBox="6.75 6.75 18 18">
						<path id="Icon_ionic-project_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
						</path>
					</svg>
					<div id="add_project_lbl_page">
						<span>Add Project</span>
					</div>
				</button> */}



				<div id="project_page_scroll">
					{projectData.filter(rw=> (rw.projectName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.projectInfo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.contactPersonMobile.toString().includes(props.searchText.toLowerCase()) || rw.contactPersonName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
						<div style={{ position: "relative", display: 'inline-flex' }}>

							<div id="card_1_project"  onClick={() => {setSelectedProject(rec)}}>
								<svg class="card_over_all_bg_project_page">
									<rect id="card_over_all_bg_project_page" rx="10" ry="10" x="0" y="0" width="174" height="194">
									</rect>
								</svg>
								<img id="card_bg_design_project_page" src="card_bg_design_staff_page.png" srcset="card_bg_design_staff_page.png 1x" />

								<div id="pfp_grp_project_page">
									<svg class="pfp_behind_circle_project_page">
										<ellipse id="pfp_behind_circle_project_page" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{fill:rec.active===true?"rgba(119,238,170,1)":"White"}}>
										</ellipse>
									</svg>
									<img id="profile_pic_card_project_page" 
								 src={
									rec.projectImageUrl && rec.projectImageUrl.length >10
										? "https://drive.google.com/uc?export=view&id=" +
										rec.projectImageUrl.split('/')[5]
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }} />

								</div>
								<img title="Compant Name" id="manager_lbl_project_page" src="manager_lbl_project_page.png" srcset="manager_lbl_project_page.png 1x" />

								<div id="Admin_lbl_project_page">
									<span>{rec.companyName}</span>
								</div>
								<div id="Mohammed_Affan_lbl_project_page">
									<span>{rec.projectName}</span>
								</div>
								<div id="Start_time_projpage">
									<span>{(rec.startDate).slice(0, 10)}</span>
								</div>
								<div id="date_proj_page">
									<span>{(rec.endDate).slice(0, 10)}</span>
								</div>
								<div id="road_time_grp">
									<svg class="road_bdr">
										<rect id="road_bdr" rx="5" ry="5" x="0" y="0" width="155" height="25">
										</rect>
									</svg>
									<svg class="proj_road_line" viewBox="0 0 140 1">
										<path id="proj_road_line" d="M 0 0 L 140 0">
										</path>
									</svg>

									
								{/* <span>{(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000)}</span> */}
								{/* <span>{parseInt(140*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100}</span> */}
									<img title="Start Date" style={{left:((parseInt((140*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100))>140 ? 140:(parseInt((140*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100))) +"px" , overflow:"hidden"} } id="proj_startpage" src="proj_startpage.png" srcset="proj_startpage.png 1x" />
									


									<img title="End Date" id="finish_img_pro_page" src="finish_img_pro_page.png" srcset="finish_img_pro_page.png 1x" />
								</div>
							</div>
							{/* {console.log(parseInt((140*(100*parseInt((new Date() - new Date(rec.startDate))/86400000))/parseInt((new Date(rec.endDate) - new Date(rec.startDate))/86400000))/100))} */}

						</div>
					))}
				</div>

			</div>
					
		}
			{/* <ProjectPopup isOpen={showPopUp} data={popUpData} closePopup3={(value) => setShowPopUp(value)} /> */}
			<ProjectPopup isOpen={props.isAddPopupVisible && selectedProject===null} closePopup3={() => props.closeAddPopup(false)} />
			
</div>

	)
}

export default ProjectPage