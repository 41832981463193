import React, { useState, useEffect,useContext } from "react";
import Modal from '@mui/material/Modal';
import "../../Styles/PC/popupUser.css"
import Switch from '@mui/material/Switch';
import Loader from "./Loader";
import { UserContext } from '../UserContext';


// import { Select } from "@mui/material";

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const PopupUser = (props) => {

    const [userAccess, setUserAccess] = useState('0')
    const [userType, setUserType] = useState('0')
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [remarks, setRemarks] = useState("")
    const [active, setActive] = useState(true)

    const [userId, setUserId] = useState("")
    const [profilePic, setProfilePic] = useState("")
    const [profileImage, setProfileImage] = useState("")
    const [loading, setLoading] = useState(false)

	const { user, setUser } = useContext(UserContext);





    useEffect(() => {
        //  console.log(props.data)
        if (props.display === "Edit") {
            setUserId(props.data ? props.data._id : "")
            setUserAccess(props.data ? props.data.userAccess : "")
            setUserType(props.data ? props.data.userType : "")
            setName(props.data ? props.data.name : "")
            setMobile(props.data ? props.data.mobile : "")
            setEmail(props.data ? props.data.email : "")
            setRemarks(props.data ? props.data.remarks : "")
            setProfileImage(props.data ? props.data.profilePicUrl : "")
            setActive(props.data ? props.data.active : false)
        }

    }, [props.data])

    const clearData = () => {
        setUserId("")
        setUserAccess("")
        setUserType("")
        setName("")
        setMobile("")
        setEmail("")
        setProfileImage("")
        setRemarks("")
        setProfilePic("")
    }

   
               
       

    // const handleClose=()=>{
    //     clearData();
    //     props.closePopup()

    // }

    const addUser = () => {
        if (name.length === 0 || name.length === "") {
            return alert("Please Enter the Name!!")
        }
        if (mobile.length === 0 || mobile.length > 10) {
            return alert("Please Enter Mobile Number")
        }
        if (email.length === 0 || email.length === "") {
            return alert("Please Enter Your Email ")
        }
        if (userType === 0) {
            return alert("Please Select User Type")
        }
        if (userAccess === 0) {
            return alert("Please Select User Access")
        }


        let apiURL = process.env.REACT_APP_API_URL + "/users/addUser";
        let apiParams = {
            method: "POST",
            headers: {
                name: name,
                mobile: mobile,
                email: email,
                remarks: remarks,
                active: active,
                useraccess: userAccess,
                usertype: userType,

            },
        };
        // alert(JSON.stringify(apiParams))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {

                if (repos.message) {
                    clearData();
                    props.closePopup()

                    alert("User Added Successfully!!");

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));
        //repos.message || repos
    }

    const updateUser = () => {
       
        let apiURL = process.env.REACT_APP_API_URL + "/users/edituser";
        let apiParams = {
            method: "POST",
            headers: {
                userid: userId,
                remarks: remarks,
                active: active,
                name: name,
                mobile: mobile,
                email: email,
                useraccess: userAccess,
                usertype: userType,
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("User Details Updated Successfully!!");

                    clearData();
                    props.closePopup()


                } else alert(repos.message || repos);
            })
            .catch((err) => {
               
                alert(err)
            });

    }

    const resetUserPassword = () => {
       
        let apiURL = process.env.REACT_APP_API_URL + "/users/resetUserPassword";
        let apiParams = {
            method: "POST",
            headers: {
                userid: userId,
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("User Password Updated Successfully!!");

                   
                    props.closePopup()


                } else alert(repos.message || repos);
            })
            .catch((err) => {
               
                alert(err)
            });

    }
    // const staffUpdate = () => {
    //     // console.log(props.data.userAccess)
    //     if (props.data.userType === "staff"  && props.data.userAccess >= 5) {
    //         updateUser() 
    //     }
    //     else {
    //         alert("Access Denied")
    //     }
    // }

    const guardarArchivo = async (e) => {
        setLoading(true)
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = function (e) { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {

                        // alert("Image Uploaded Successfully!!") //See response

                        SaveProfilePic(data)
                    }).catch(e =>{
                        setLoading(false)   
                        console.log(e)}) // Or Error in console
            }
        }

    }
    const SaveProfilePic = (data) => {
        setLoading(true)

        const apiurl = process.env.REACT_APP_API_URL + "/users/addUserProfilePic/" + props.data._id;
        let apiParams = {
            method: "POST",
            headers: {
                profilepicurl: data.url,


            }
        }
        fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setLoading(false)
                    alert("Image Uploaded Successfully");
                    clearData();
                    props.closePopup()
                }
            })
            .catch(e => 
                {setLoading(false)
                console.log(e)})
    }

    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProfilePic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            // console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            guardarArchivo(e);
        }


    }



    return (

        <div>
            <Modal

                open={props.display === "Add" || props.display === "Edit"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflowY: "scroll" }}

            >
            <div>
            <div id="pup_up_add_user_mob">
                    {/* <div id="all_grp_event_page_pc"> */}
                        <svg class="over_all_card_pop_bg__pop_mob">
                            <rect id="over_all_card_pop_bg__pop_mob" rx="15" ry="15" x="0" y="0" width="361" height="649">
                            </rect>
                        </svg>
                        <div id="bg_design_grp_pop_mob">
                            <svg class="bg_design_2_pop_mob" viewBox="1805.416 80.996 361 121.174">
                                <path id="bg_design_2_pop_mob" d="M 2146.951416015625 80.99924468994141 C 2127.3515625 81.30854034423828 1820.697265625 80.99924468994141 1820.697265625 80.99924468994141 C 1820.697265625 80.99924468994141 1812.288818359375 81.34671020507812 1807.982666015625 88.69296264648438 C 1804.782958984375 94.14987182617188 1805.48046875 105.9462585449219 1805.48046875 112.0343322753906 C 1805.48046875 125.8082885742188 2166.416015625 202.1694183349609 2166.416015625 202.1694183349609 L 2166.416015625 100.4651641845703 C 2166.416015625 100.4651641845703 2166.551025390625 80.68994140625 2146.951416015625 80.99924468994141 Z">
                                </path>
                            </svg>
                            <svg class="bg_design_1_pop_mob" viewBox="1805.416 80.996 361 121.174">
                                <path id="bg_design_1_pop_mob" d="M 1824.880981445312 80.99924468994141 C 1844.480346679688 81.30854034423828 2151.134765625 80.99924468994141 2151.134765625 80.99924468994141 C 2151.134765625 80.99924468994141 2159.54296875 81.34671020507812 2163.849609375 88.69296264648438 C 2167.048828125 94.14987182617188 2166.3515625 105.9462585449219 2166.3515625 112.0343322753906 C 2166.3515625 125.8082885742188 1805.416137695312 202.1694183349609 1805.416137695312 202.1694183349609 L 1805.416137695312 100.4651641845703 C 1805.416137695312 100.4651641845703 1805.281127929688 80.68994140625 1824.880981445312 80.99924468994141 Z">
                                </path>
                            </svg>
                        </div>
                        <div id="state_grp_prof_pop_mob">
                            <div id="state_lbl_pfp_pop_mob">
                                <span>Name</span>
                            </div>
                            <input tabIndex={1} placeholder="Name" class="state_input_pfp_pop_mob" value={name} onChange={(e) => { setName(e.target.value) }} />


                        </div>
                        <div id="city_grp_prof_pop_mob">
                            <div id="city_lbl_pfp_pop_mob">
                                <span>Email</span>
                            </div>
                            <input tabIndex={3} placeholder="Email" class="city_input_pfp_pop_mob" value={email} onChange={(e) => { setEmail(e.target.value) }} />


                        </div>
                        <div id="company_grp_pop_mob">
                            <div id="company_lbl_pfp_pop_mob">
                                <span>Mobile</span>
                            </div>
                            <input placeholder="Mobile" class="company_input_pfp_pop_mob" tabIndex={2} value={mobile} onChange={(e) => setMobile(e.target.value)} />

                        </div>
                        <div id="user_type_grp_pop_mob">
                            <div id="user_type_lbl_pfp_pop_mob">
                                <span>User Type</span>
                            </div>
                           
                            <select tabIndex={4} className="user_type_input_pfp_pop_mob" value={userType} onChange={(e) => { setUserType(e.target.value) }}>
                                <option value="0">--- Select ----</option>
                                <option value="client">Client</option>
                                <option value="staff">Staff</option>
                            </select>
                        </div>
                        <div id="latitiude_grp_pop_mob_">
                            <div id="latitude_lbl_pop_mob">
                                <span>Remark</span>
                            </div>
                            {/* <textarea placeholder="Remark" class="latitude_input_pop_mob" /> */}
                            <textarea tabIndex={6} placeholder="Remark" class="latitude_input_pop_mob" value={remarks} onChange={(e) => { setRemarks(e.target.value) }} />

                        </div>
                        <div id="active_lbl_pop_mob">
                            <span>Active</span>
                            <Switch {...label} tabIndex={10} size="small" className='popup_switch' checked={active} onChange={(e) => { setActive(e.target.checked) }} />
                        { props.display === "Edit" &&   props.data.userAccess < 5  &&  (user && parseInt(user.useraccess) !== 2) && <img src="resetpassword.png" id="user_resetpass" onClick={()=> { window.confirm("Are you sure, you want to reset " + props.data.name + "'s password?") && resetUserPassword() }}/>}
                            
                        </div>
                       {user && user.usertype === "staff" && parseInt(user.useraccess) >= 5 && 
                        <button id="change_pass_btn_grp_pop_mob" onClick={() => { props.display === "Edit" ? updateUser() : addUser() }}>
                            <svg class="passs_bdr_btn_pop_mob">
                                <rect id="passs_bdr_btn_pop_mob" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                </rect>
                            </svg>
                            <div id="Change_Password_lbl_pop_mob">
                                <span tabIndex={7} >{props.display}</span>

                            </div>
                        </button>
}
                        <img id="close_pop_up_use_close_mob" src="close_pop_up_use_close_mob.png" srcset="close_pop_up_use_close_mob.png 1x" tabIndex={8}  onClick={() => { props.closePopup(); clearData() }}/>

                        <div id="User_access_grp_pop_mob">
                            <div id="User_access_lbl_pfp_pop_mob">
                                <span>User Access</span>
                            </div>
                            
                            <select tabIndex={5} className="User_access_input_pfp_pop_mob" value={userAccess} onChange={(e) => { setUserAccess(e.target.value) }}>
                                <option value="0">--- Select ----</option>
                                <option value="1">Executive</option>
                                <option value="2">HR</option>
                                <option value="3">Manager</option>
                                <option value="5">Director</option>
                                

                            </select>
                        </div>
                    {/* </div> */}
                    {/* <div style={{position:"relative" }}> */}
                 {
                            loading ?
                             <div className="loading"> <Loader /> </div> : 
                            <div id="circle_img_holder_pop_mob_grp">
                        <svg class="pfp_behind_circle_pop_mob">
                            <ellipse id="pfp_behind_circle_pop_mob" rx="50" ry="50" cx="50" cy="50" style={{ fill: active === true ? "rgba(119,238,170,1)" : "White" }}>
                            </ellipse>
                        </svg>
                       
                       
                                <label for="imageProfilepc">
                                    <input
                                        tabIndex={9}
                                        id="imageProfilepc"
                                        type="file"
                                        name="imageProfilepc"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => profilePicUpload(e)}
                                    />
                                    {profilePic.length === 0 ? (
                                        <img
                                            id="profile_pfp_img_pop_mob"
                                            width="100"
                                            height="100"
                                            src={
                                                profileImage && profileImage.length
                                                    ? "https://drive.google.com/uc?export=view&id=" +
                                                    profileImage.split('/')[5]
                                                    :
                                                    "default_image.png"
                                            }

                                            style={{ borderRadius: "75px", border: "1px solid black" }}
                                        />
                                    ) : (
                                        <img
                                            id="profile_pfp_img_pop_mob"
                                            width="100"
                                            height="100"
                                            src={profilePic ? profilePic : "default_image.png"}
                                            style={{ borderRadius: "75px", border: "1px solid black" }}
                                        />
                                    )}
                                </label>
                        

                    </div>
                    }
                   {/* </div> */}


                </div>
               
            </div>







            </Modal>

        </div>
    );
}
export default PopupUser

//     < svg class="Rectangle_33" >
//         <rect id="Rectangle_33" rx="15" ry="15" x="0" y="0" width="437" height="562">
//         </rect>
// </svg >
// <div id="bg_design_grp">
// <svg class="bg_design_2" viewBox="1805.416 80.996 437 146.684">
//     <path id="bg_design_2" d="M 2218.853515625 81 C 2195.127685546875 81.37442016601562 1823.914306640625 81 1823.914306640625 81 C 1823.914306640625 81 1813.73583984375 81.42062377929688 1808.52294921875 90.31346130371094 C 1804.649658203125 96.919189453125 1805.493896484375 111.1990280151367 1805.493896484375 118.5688171386719 C 1805.493896484375 135.2425537109375 2242.416015625 227.6797332763672 2242.416015625 227.6797332763672 L 2242.416015625 104.5640258789062 C 2242.416015625 104.5640258789062 2242.57958984375 80.62557983398438 2218.853515625 81 Z">
//     </path>
// </svg>
// <svg class="bg_design_1" viewBox="1805.416 80.996 437 146.684">
//     <path id="bg_design_1" d="M 1828.978759765625 81 C 1852.70458984375 81.37442016601562 2223.917724609375 81 2223.917724609375 81 C 2223.917724609375 81 2234.09619140625 81.42062377929688 2239.309326171875 90.31346130371094 C 2243.182373046875 96.919189453125 2242.338134765625 111.1990280151367 2242.338134765625 118.5688171386719 C 2242.338134765625 135.2425537109375 1805.416259765625 227.6797332763672 1805.416259765625 227.6797332763672 L 1805.416259765625 104.5640258789062 C 1805.416259765625 104.5640258789062 1805.252685546875 80.62557983398438 1828.978759765625 81 Z">
//     </path>
// </svg>
// </div>
// <svg class="pfp_behind_pop_circle">
// <ellipse id="pfp_behind_pop_circle" rx="50" ry="50" cx="50" cy="50"  >
// </ellipse>
// </svg>
// {/* <img id="profile_pfp_img" src="profile_pfp_img.png" srcset="profile_pfp_img.png 1x" /> */ }
// {/* <input type="file" accept='image/*' name="profilePic" id="profile_pfp_img" onChange={(e) => profilePicUpload(e)} />
// {profilePic.length === 0 ? <img id="profile_pfp_img" width="100" height="100" src={profileImage ? "https://drive.google.com/uc?export=view&id=" + (profileImage.split('/')[5]) : "cars.png"} /> :
// <img id="profile_pfp_img" src={profilePic ? profilePic : "cars.png"} />} */}
// {
//     loading ? <div className="loading"> <Loader /> </div> :
//         <label for="imageProfilepc">
//             <input
//                 tabIndex={9}
//                 id="imageProfilepc"
//                 type="file"
//                 name="imageProfilepc"
//                 hidden
//                 accept="image/*"
//                 onChange={(e) => profilePicUpload(e)}
//             />
//             {profilePic.length === 0 ? (
//                 <img
//                     id="profile_pfp_img"
//                     width="100"
//                     height="100"
//                     src={
//                         profileImage && profileImage.length
//                             ? "https://drive.google.com/uc?export=view&id=" +
//                             profileImage.split('/')[5]
//                             :
//                             "default_image.png"
//                     }

//                     style={{ borderRadius: "75px", border: "1px solid black" }}
//                 />
//             ) : (
//                 <img
//                     id="profile_pfp_img"
//                     width="100"
//                     height="100"
//                     src={profilePic ? profilePic : "default_image.png"}
//                     style={{ borderRadius: "75px", border: "1px solid black" }}
//                 />
//             )}
//         </label>
// }
// <div id="state_grp_prof">
// <div id="state_lbl_pfp" >
//     <span >Name</span>
// </div>
// <input tabIndex={1} placeholder="Name" class="state_input_pfp" value={name} onChange={(e) => { setName(e.target.value) }} />

// </div>

// <div id="company_grp">
// <div id="company_lbl_pfp" >
//     <span  >Mobile</span>
// </div>



// </div>
// <div id="city_grp_prof">
// <div id="city_lbl_pfp" >
//     <span >Email</span>
// </div>
// <input tabIndex={3} placeholder="Email" class="city_input_pfp" value={email} onChange={(e) => { setEmail(e.target.value) }} />

// </div>
// <div id="company_grp2">
// <div id="company_lbl_pfp2">
//     <span>User Type</span>
// </div>
// <select tabIndex={4} className="select_user_2" value={userType} onChange={(e) => { setUserType(e.target.value) }}>
//     <option value="0">--- Select ----</option>
//     <option value="client">Client</option>
//     <option value="staff">Staff</option>
// </select>

// </div>
// <div id="city_grp_prof2">
// <div id="city_lbl_pfp2">
//     <span>User Access</span>
// </div>
// <select tabIndex={5} className="select_user" value={userAccess} onChange={(e) => { setUserAccess(e.target.value) }}>
//     <option value="0">--- Select ----</option>
//     <option value="1">Executive</option>
//     <option value="3">Manager</option>
//     <option value="5">Director</option>


// </select>

// </div>


// <div id="latitiude_grp">
// <div id="latitude_lbl" >
//     <span>Remark</span>
// </div>
// <textarea tabIndex={6} placeholder="Remark" class="latitude_input" value={remarks} onChange={(e) => { setRemarks(e.target.value) }} />


// </div>
// <div id="active_lbl" >
// <Switch {...label} tabIndex={10} size="small" className='switch2' checked={active} onChange={(e) => { setActive(e.target.checked) }} />
// <span>Active</span>
// </div>

// {/* <svg class="active_chkbx">

//      <rect id="active_chkbx" rx="0" ry="0" x="0" y="0" width="38" height="20">
// </rect> 
// </svg> */}
// <button id="change_pass_btn_grp" onClick={() => { props.display === "Edit" ? updateUser() : addUser() }}>
// <svg class="passs_bdr_btn">
//     <rect id="passs_bdr_btn" rx="8" ry="8" x="0" y="0" width="123" height="41">
//     </rect>
// </svg>
// <div id="Change_Password_lbl" >
//     <span tabIndex={7} >{props.display}</span>
// </div>
// </button>
// <img id="close" tabIndex={8} src="close.png" srcset="close.png 1x" onClick={() => { props.closePopup(); clearData() }} />
