import React,{useState,useEffect,useContext} from 'react'
import "../../Styles/Mob/clientStaffMob.css"
// import ClientStaffPopup from './ClientStaffPopup'
import { UserContext } from '../UserContext';
import ClientStaffPopup from '../PC/ClientStaffPopup';


const ClientStaffMob = (props) => {
    const [displayStaff, setDisplayStaff] = useState("none")
	const [showStaffData, setStaffShowData] = useState({});
   const[clientStaffData,setClientStaffData] =useState([]);
	const { user, setUser } = useContext(UserContext);


    useEffect(() => {
        setDisplayStaff(props.isAddPopupVisibleMob === true ? "Add" : displayStaff === true ? "Edit" : "none")
	}, [props.isAddPopupVisibleMob])

    useEffect(()=>{
if(user){
        GetClientStaff()
	}
    },[displayStaff,props.closeAddPopupMob,showStaffData ,user])

    const GetClientStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/clientstaff" + ((user && user.usertype === "staff" && user.useraccess >=5) || (user && user.usertype === "staff" && parseInt(user.useraccess) === 2) ? "/getClientStaff" : (user && user.usertype === "client") ? "/getClientStaffByUser/"+ user._id : "");   
		     let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setClientStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    return (
        <div id="client_staff_mob">
            <img id="over_all_bg_design_client_staf" src="over_all_bg_design_client_staf.png" srcset="over_all_bg_design_client_staf.png 1x" />
            {/* .filter(rw=> (rw.clientStaffName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.mobile.toString().includes(props.searchText.toLowerCase()) || rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) )) */}
            <div id="Scroll_client_staff_mob_new">
            {clientStaffData.filter(rw=> (rw.clientStaffName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.mobile.toString().includes(props.searchText.toLowerCase()) || rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec)=>(
            <div style={{position:"relative", display:"block"}}>
                <div id="card_1_client_staff_mob_new" onClick={()=>{setDisplayStaff("Edit");setStaffShowData(rec)}}>
                    <svg class="card_over_all_bg_client_staff_">
                        <rect id="card_over_all_bg_client_staff_" rx="10" ry="10" x="0" y="0" width="351" height="105">
                        </rect>
                    </svg>
                    <img id="card_bg_design_client_staff_mo" src="card_bg_design_client_staff_mo.png" srcset="card_bg_design_client_staff_mo.png 1x" />

                    <svg class="pfp_behind_circleclient_staff_">
                        <ellipse id="pfp_behind_circleclient_staff_" rx="43" ry="43" cx="43" cy="43">
                        </ellipse>
                    </svg>
                    <img id="profile_pic_cardclient_staff_m"  src={
									rec.profilePicUrl && rec.profilePicUrl.length > 10
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								} />
                    <img title="Mobile Number" id="iphone-5_lbl_client_staff_mob_" src="iphone-5_lbl_client_staff_mob_.png" srcset="iphone-5_lbl_client_staff_mob_.png 1x" />

                    <div id="n_234567890_mobile_number_clie">
                        <span>{rec.mobile}</span>
                    </div>
                    <div id="Mohammed_Affan_lbl_client_staf">
                        <span>{rec.clientStaffName}</span>
                    </div>
                    <img title="Staff Experience" id="exp_client_staff_mob_new" src="exp_client_staff_mob_new.png" srcset="exp_client_staff_mob_new.png 1x" />

                    <div id="yr_client_pc_new_client_staff_">
                        <span>{rec.clientDesignation}</span>
                    </div>
                    <img title="Staff Designation" id="manager_lbl_client_staff_mob_n" src="manager_lbl_client_staff_mob_n.png" srcset="manager_lbl_client_staff_mob_n.png 1x" />

                    <div id="Admin_lbl_client_staff_mob_new">
                        <span>{rec.companyName}</span>
                    </div>
                </div>
                </div>
            ))}
            </div>
    <ClientStaffPopup display={displayStaff} data={showStaffData} closePopup={() => {props.closeAddPopupMob();setDisplayStaff("none")}}/>

        </div>

    )
}

export default ClientStaffMob