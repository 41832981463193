import React, { useEffect, useState, useContext } from "react";
import "../../Styles/Mob/timeSheetPopup.css";
import Modal from "@mui/material/Modal";
import { UserContext } from "../UserContext";

const TimeSheetPopup = (props) => {
  const [tasksData, setTasksData] = useState([]);
  const { user } = useContext(UserContext);

  const [taskDate, setTaskDate] = useState(
    new Date().getFullYear() +
      "-" +
      parseInt(new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      parseInt(new Date().getDate()).toString().padStart(2, "0")
  );
  const [billedHours, setBilledHours] = useState();
  const [status, setStatus] = useState("");
  const [taskId, setTaskId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [timeSheetData, setTimeSheetData] = useState([]);

  const [switchState, setSwitchState] = useState(true);

  useEffect(() => {
    if (user) {
      GetTasks();
    }
  }, [user]);

  const addTimeSheet = () => {
    if (
      (status === "" && taskId != "Leave") ||
      (projectId === "" && taskId != "Leave") ||
      taskId === "" ||
      billedHours === "" ||
      !Date.parse(taskDate)
    ) {
      alert("Select all fields!");
    } else if (taskDate.length === "" || taskDate.length === 0) {
      return alert("Select Correct Date");
    } else if (taskDate.length === "" || taskDate.length === 0) {
      return alert("Select Date");
    } else if (taskDate.length === "" || taskDate.length === 0) {
      return alert("Select Date");
    } else {
      let apiURL = process.env.REACT_APP_API_URL + "/timesheet/addTimeSheet";
      let apiParams = {
        method: "POST",
        headers: {
          projectid: projectId,
          project: tasksData.filter((rew) => rew.projectId === projectId)[0]
            .project,
          taskid: taskId,
          task:
            taskId === "Leave"
              ? "Leave"
              : tasksData.filter((rec) => rec._id === taskId)[0].task,
          moduleid:
            taskId === "Leave"
              ? "Leave"
              : tasksData.filter((rec) => rec._id === taskId)[0].moduleId,
          module:
            taskId === "Leave"
              ? "Leave"
              : tasksData.filter((rec) => rec._id === taskId)[0].module,
          categoryid:
            taskId === "Leave"
              ? "Leave"
              : tasksData.filter((rec) => rec._id === taskId)[0].categoryId,
          category:
            taskId === "Leave"
              ? "Leave"
              : tasksData.filter((rec) => rec._id === taskId)[0].category,
          taskdate: taskDate,
          billedhours: billedHours,
          billedtoid:
            taskId === "Leave"
              ? user._id
              : tasksData.filter((rec) => rec._id === taskId)[0].assignedToId,
          billedtoname:
            taskId === "Leave"
              ? user.name
              : tasksData.filter((rec) => rec._id === taskId)[0].assignedToName,
          status: taskId === "Leave" ? "Leave" : status,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.message) {
            clearData();
            props.closePopup(false);
            alert("TimeSheet Added Successfully!!");
            props.getTimesheet();
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    }
  };

  const GetTasks = () => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/task/getTasksByStaff/" + user._id;
    let apiParams = {
      method: "GET",
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setTasksData(repos.data);
          if (repos.data[0]) {
            setTaskId(repos.data[0]._id);
          }
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const clearData = () => {
    return setBilledHours(""), setStatus(""), setTaskId(""), setProjectId("");
  };
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => props.closePopup(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{ overflowY: "scroll" }}
      >
        <div id="add_task_page_mob">
          <img
            id="n_6282087_rm141-nunny-02b"
            src="n_6282087_rm141-nunny-02b.png"
            srcset="n_6282087_rm141-nunny-02b.png 1x"
          />

          <div id="add_task_mob_grp">
            <select
              class="task_drop_down_mob_task_page"
              tabIndex={2}
              value={projectId}
              onChange={(e) => {
                setProjectId(e.target.value);
                setTaskId("");
              }}
            >
              <option> --Projects--</option>
              {tasksData &&
                ([...new Set(tasksData.map((rew) => rew.projectId))] || []).map(
                  (rec) => (
                    <option value={rec}>
                      {tasksData &&
                        tasksData.filter((rew) => rew.projectId === rec)[0]
                          .project}
                    </option>
                  )
                )}
            </select>
            {console.log(
              new Date().getFullYear() +
                "-" +
                parseInt(new Date().getMonth() + 1)
                  .toString()
                  .padStart(2, "0") +
                "-" +
                parseInt(new Date().getDate()).toString().padStart(2, "0")
            )}
            <input
              type="date"
              class="date_calendermob_task"
              max={
                new Date().getFullYear() +
                "-" +
                parseInt(new Date().getMonth() + 1)
                  .toString()
                  .padStart(2, "0") +
                "-" +
                parseInt(new Date().getDate()).toString().padStart(2, "0")
              }
              tabIndex={1}
              value={taskDate}
              onChange={(e) => {
                setTaskDate(e.target.value);
              }}
            />

            <img
              id="calendar_timeline_img"
              src="calendar_timeline_img.png"
              srcset="calendar_timeline_img.png 1x"
            />

            <select
              tabIndex={5}
              class="status_mob_task_timeline_page"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value=""> --Status--</option>
              <option value="Work in Progress">Work in Progress</option>
              <option value="On Hold">On Hold</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
            <button
              id="add_btn_timeline_page_mob_task"
              onClick={() => addTimeSheet()}
            >
              <svg class="add_bg_timeline_page_mob_task">
                <rect
                  id="add_bg_timeline_page_mob_task"
                  rx="8"
                  ry="8"
                  x="0"
                  y="0"
                  width="68"
                  height="36"
                ></rect>
              </svg>
              <svg
                class="Icon_ionic-timeline_page_mob_t"
                viewBox="6.75 6.75 18 18"
              >
                <path
                  id="Icon_ionic-timeline_page_mob_t"
                  d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z"
                ></path>
              </svg>
              <div id="add_timeline_page_mob_task">
                <span>Add</span>
              </div>
            </button>
            <input
              placeholder="Hours"
              class="hours_imob_taskt_timeline_page"
              tabIndex={4}
              type="number"
              value={billedHours}
              onChange={(e) => setBilledHours(e.target.value)}
            />

            <select
              class="date_calender_mob_task"
              tabIndex={3}
              value={taskId}
              onChange={(e) => setTaskId(e.target.value)}
            >
              <option value=""> --Tasks--</option>
              <option value="Leave">Leave</option>

              {(
                tasksData &&
                tasksData.filter((rew) => rew.projectId === projectId)
              ).map((rec) => (
                <option value={rec._id}>{rec.task}</option>
              ))}
            </select>
          </div>
          <div id="Add_Timesheet_lbl_">
            <span>Add Timesheet</span>
          </div>
          <img
            id="close_add_task_page"
            src="close.png"
            srcset="close.png 1x"
            onClick={() => props.closePopup(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TimeSheetPopup;

{
  /* <div id="add_task_page_mob">
<img id="n_6282087_rm141-nunny-02b" src="n_6282087_rm141-nunny-02b.png" srcset="n_6282087_rm141-nunny-02b.png 1x" />

<div id="add_task_mob_grp">
    <select class="status_mob_task_timeline_page">
        <option> --- All Task ---</option>
        <option> --- All Task ---</option>
    </select>

    <select tabIndex={5} class="status_mob_task_timeline_page" value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value=""> --Status--</option>
        <option value="Work in Progress">Work in Progress</option>
        <option value="On Hold">On Hold</option>
        <option value="Completed">Completed</option>
        <option value="Cancelled">Cancelled</option>
    </select>
    <input type="date" class="date_calendermob_task" tabIndex={1} value={taskDate} onChange={(e) => { setTaskDate(e.target.value) }} />

    <img id="calendar_timeline_img" src="calendar_timeline_img.png" srcset="calendar_timeline_img.png 1x" />

    <select class="task_drop_down_mob_task_page" tabIndex={2} value={projectId} onChange={(e) => { setProjectId(e.target.value); setTaskId("") }}>
        <option> --Projects--</option>
        {tasksData && ([...new Set(tasksData.map(rew => rew.projectId))] || []).map((rec) => (
            <option value={rec}>{tasksData && tasksData.filter(rew => rew.projectId === rec)[0].project}</option>
        ))}
    </select>

    <button id="add_btn_timeline_page_mob_task" onClick={() => addTimeSheet()}>
        <svg class="add_bg_timeline_page_mob_task">
            <rect id="add_bg_timeline_page_mob_task" rx="8" ry="8" x="0" y="0" width="68" height="36">
            </rect>
        </svg>
        <svg class="Icon_ionic-timeline_page_mob_t" viewBox="6.75 6.75 18 18">
            <path id="Icon_ionic-timeline_page_mob_t" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
            </path>
        </svg>
        <div id="add_timeline_page_mob_task">
            <span>Add</span>
        </div>
    </button>

    <input placeholder="Hours" class="hours_imob_taskt_timeline_page" tabIndex={4} type="number" value={billedHours} onChange={(e) => setBilledHours(e.target.value)} />

    <select class="date_calender_mob_task" tabIndex={3} value={taskId} onChange={(e) => setTaskId(e.target.value)}>
        <option value=""> --Tasks--</option>

        {(tasksData && tasksData.filter(rew => rew.projectId === projectId)).map((rec) => (
            <option value={rec._id}>{rec.task}</option>
        ))}
    </select>
</div>
<div id="Add_Timesheet">
    <span>Add Timesheet</span>
</div>
<img id="close" src="close.png" srcset="close.png 1x" onClick={() => (props.closePopup(false))} />

</div> */
}
