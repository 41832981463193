import React, { useEffect, useState,useContext } from 'react'
import "../../Styles/PC/taskCard.css"
import PopUpTask from './PopUpTask'
import { UserContext } from '../UserContext'


const TaskCard = (props) => {

    // const [displayTask, setDisplayTask] = useState("none")
	// const [showData, setShowData] = useState({});

	const { user } = useContext(UserContext);

    return (


        <div id="task_page__card_1" onClick={()=> { if ((user.usertype === "staff" && user.useraccess >= 5) || props.task.assignedToId === user._id || (user && user.usertype === "staff" && user.useraccess >= 3 && (props.teamData.filter(rw=> rw.staffUserId === user._id).length) > 0)) {props.setDisplayTask(); props.setShowData()} else alert("Access Denied") }}>
        {/* onClick={() => { if ((user.usertype === "staff" && user.useraccess >= 5) || props.task.assignedToId === user._id || (user && user.usertype === "staff" && user.useraccess >= 3 && (props.teamData.filter(rw=> rw.staffUserId === user._id).length) > 0)) { props.setDisplayTask("none"); props.setShowData(props.task) } else alert("Access Denied") }} */}
            <svg class="card_over_all_bg_task_page">
                <rect id="card_over_all_bg_task_page" rx="10" ry="10" x="0" y="0" width="174" height="182">
                </rect>
            </svg>
            <img id="task_name_bg_card_1_task_page" src="task_name_bg_card_1_task_page.png" srcset="task_name_bg_card_1_task_page.png 1x" />

            <img title="Compant Name" id="developemtn_img_task_page" src="developemtn_img_task_page.png" srcset="developemtn_img_task_page.png 1x" />

            <div id="development_lbl_task_page">
                <span>{props.task.category}</span>
            </div>
            <div id="task_name_task_page">
                <span>{props.task.task}</span>
            </div>
            <img title="Compant Name" id="img_22_task_page" src="img_22_task_page.png" srcset="img_22_task_page.png 1x" />

            <div id="n_2_hrs_lbltask_page">
                <span>{props.task.plannedHours} Hours</span>
            </div>
            <img title="Compant Name" id="time_2_img_task_page" src="time_2_img_task_page.png" srcset="time_2_img_task_page.png 1x" />

            <div id="time_2_task_page">
                <span>{props.task.billedHours}</span>
            </div>
            <svg class="work_in_progress_bdr_task_page" style={{ fill: props.task.status === "Unassigned" ? "#999999" : props.task.status === "Assigned" ? "#FFCC00" : props.task.status === "Work in Progress" ? "#3399FF" : props.task.status === "On Hold" ? "#FF9900" : props.task.status === "Completed" ? "#00CC66" : props.task.status === "Cancelled" ? "#FF0000" : "" }}>
                <rect id="work_in_progress_bdr_task_page" rx="10.5" ry="10.5" x="0" y="0" width="132" height="21">
                </rect>
            </svg>
            <div id="work_in_progress_lbl_task_page">
                <span>{props.task.status}</span>
            </div>
            <img title="Compant Name" id="assigned_to_pfp_task_page" src="assigned_to_pfp_task_page.png" srcset="assigned_to_pfp_task_page.png 1x" />

            <div id="assigned_to_lbl_task_page">
                <span>{props.task.assignedToName}</span>
            </div>
				{/* <PopUpTask display={displayTask} data={showData} teamData={props.teamData} closePopup={() => { props.closeAddPopup(); (props.setDisplayTask("none")) }} project={props.project} /> */}
            
        </div>
    )
}

export default TaskCard