import React, { useState, useEffect } from 'react'
import "../../Styles/Mob/staffMob.css"
import PopupStaff from '../PC/PopupStaff'

const StaffMob = (props) => {
	// const [addStaffPopupMob] = useState(null)
	// const [cardPopup, setCardPopup] = useState(false)
	// const [popUpData, setPopUpData] = useState({})

	const [displayStaff, setDisplayStaff] = useState("none")
	const [showStaffData, setStaffShowData] = useState({});

	const [staffData, setStaffData] = useState([])

	useEffect(() => {

		GetStaff()

	}, [props.isAddPopupVisibleMob, displayStaff])

	useEffect(() => {
		setDisplayStaff(props.isAddPopupVisibleMob === true ? "Add" : displayStaff === true ? "Edit" : "none")
	}, [props.isAddPopupVisibleMob])


	const GetStaff = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/staff/getStaff";
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setStaffData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

	return (
		<div id="staff_page_mob">
			<img id="over_all_bg" src="over_all_bg.png" srcset="over_all_bg.png 1x" />



			<div id="Scroll_staff_page_mob_card_" >
				
				{staffData.filter(rw=> (rw.staffName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.staffSkills.toLowerCase().includes(props.searchText.toLowerCase()) || rw.staffMobile.toString().includes(props.searchText.toLowerCase())|| rw.staffDesignation.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec) => (
					<div style={{ position: "relative", display: 'inline-flex' }} onClick={() => { setDisplayStaff("Edit"); setStaffShowData(rec) }}>
						<div id="staff_overall_card_mob">
							<svg class="card_over_all_bg_staff_page_mo" >
								<rect id="card_over_all_bg_staff_page_mo" rx="10" ry="10" x="0" y="0" width="93vw" height="103">
								</rect>
							</svg>
							<img title="Staff Experience" id="exp_staff_page_mob" src="exp_staff_page_mob.png" srcset="exp_staff_page_mob.png 1x" />

							<div id="yr_staff_page_mob">
								<span>{parseInt(rec.staffExpirienceMonths / 12)} Years {parseInt(rec.staffExpirienceMonths % 12)} Months</span>
							</div>
							<img title="Staff Designation" id="manager_lbl_staff_page_mob" src="manager_lbl_staff_page_mob.png" srcset="manager_lbl_staff_page_mob.png 1x" />

							<div id="Admin_lbl_staff_page_mob">
								<span>{rec.staffDesignation}</span>
							</div>
							<img id="card_bg_design_staff_page_mob" src="card_bg_design_staff_page_mob.png" srcset="card_bg_design_staff_page_mob.png 1x" />

							<svg class="pfp_behind_circle_staff_page_m" style={{ fill: rec.staffActive === true ? "rgba(119,238,170,1)" : "White" }}>
								<ellipse id="pfp_behind_circle_staff_page_m" rx="45" ry="45" cx="45" cy="45">
								</ellipse>
							</svg>
							<img id="profile_pic_card_staff_page_mo" src={
								rec.profilePicUrl && rec.profilePicUrl.length > 10
									? "https://drive.google.com/uc?export=view&id=" +
									rec.profilePicUrl.split('/')[5]
									:
									"default_image.png"
							} />

							<img title="Mobile Number" id="iphone-5_lbl_staff_page_mob" src="iphone-5_lbl_staff_page_mob.png" srcset="iphone-5_lbl_staff_page_mob.png 1x" />

							<div id="n_234567890_mobile_number_staf">
								<span>{rec.staffMobile}</span>
							</div>
							<img title="Access" id="client_lbl_staff_page_mob" src="remark_lbl_staff_page_mob.png" srcset="remark_lbl_staff_page_mob.png 1x" />

							<div id="client_lbl_staff_page_mob_c">
								<marquee behavior="scroll" direction="">
									<span>{rec.staffSkills}</span>
								</marquee>.
							</div>
							<div id="Mohammed_Affan_lbl_staff_page_">
								<span>{rec.staffName}</span>
							</div>
						</div>
					</div>
				))}
			</div>

			{/* <PopupStaff isOpen={props.isAddPopupVisibleMob && addStaffPopupMob === null} closePopup2={() => { props.closeAddPopupMob(false) }} /> */}
			<PopupStaff display={displayStaff} data={showStaffData} closePopup2={() => { props.closeAddPopupMob(); setDisplayStaff("none") }} />
		</div>
	)
}

export default StaffMob