import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/PC/cardGrid.css"
import { Switch } from 'antd'
import TimeSheetCard from './TimeSheetCard'
import BilledHoursCard from './BilledHoursCard'
import { UserContext } from '../UserContext'



const CardGrid = (props) => {

	const [timeSheetDates, setTimeSheetDates] = useState([])
	const [showCards, setShowCards] = useState([])
	const [activeStaffData, setActiveStaffData] = useState([])
	const { user } = useContext(UserContext);


	useEffect(() => {
		if (props.timeSheetData) {
			const unique = arr => arr.map(row => row.taskDate);
			const dates = [...new Set(unique(props.timeSheetData))]
			setTimeSheetDates(dates)
			var visibleCards = props.timeSheetData.map((cr, idx) => { return { idx, visible: true } })
			setShowCards(visibleCards)
		}
	}, [props.timeSheetData])

	useEffect(() => {
		// console.log(timeSheetDates)
	}, [timeSheetDates, showCards])

	useEffect(() => {

		if (user) {
			 GetActiveStaff()
		}
	}, [user])

	const GetActiveStaff = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/staff/getActiveStaff";
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setActiveStaffData(repos.data)

					// if (repos.data[0]) {
					//     setStaffUserId(repos.data[0]._id)
					// }
				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}






	let sortedDates = props.timeSheetData.sort((a, b) => Date.parse(b.taskDate) - Date.parse(a.taskDate));
	console.log(sortedDates)
	// let sortedCars1 = timeSheetDates.sort((a, b) => Date.parse(a.taskDate) - new Date(b.taskDate));
	// console.log(sortedCars1)
	// console.log(timeSheetDates)

	var collapseDate = (idx) => {
		var cards = showCards;
		cards[idx].visible = !cards[idx].visible;
		setShowCards([...cards])
	}
	// let sortedCars2 = cars.sort((a, b) => new Date(a.initialRegistration).getTime() - new Date(b.initialRegistration).getTime());

	return (
		<div id="timeline_page__cards_timesheet">

			{/* <img id="over_all_bg_timeline_page_timesheet" src="over_all_bg_timeline_page.png" srcset="over_all_bg_timeline_page.png 1x"/> */}

			<div id="scroll_timelime">
				{timeSheetDates.map((recDate, idx) =>
					<div id="unassigned_status_drop_grp_timesheet" >
						<div style={{ display: "table-cell", textAlign: "center" }}>
							<div id="top_status_area_timesheet">
								<div id="unassigned_lbl__timesheet">

									<span>{recDate.toString().slice(0, 10)}</span>
								</div>
								<img id="right-arrow_2_drop_btn_timesheet" src={showCards[idx].visible === true ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => collapseDate(idx)} />

								{/* <img id="right-arrow_2_drop_btn_timesheet" src="right-arrow_2_drop_btn.png" srcset="right-arrow_2_drop_btn.png 1x" /> */}

								<svg class="long_line_unassigned_timesheet" viewBox="0 0 970 1">
									<path id="long_line_unassigned_timesheet" d="M 0 0 L 970 0">
									</path>
								</svg>
								<svg class="left_arrow_line__timesheet" viewBox="0 0 14 1">
									<path id="left_arrow_line__timesheet" d="M 14 0 L 0 0">
									</path>
								</svg>

							</div>
							{/* {console.log(new Date().toISOString().substring(0,10))} */}
						{user && activeStaffData &&	<BilledHoursCard billedHours={activeStaffData.map(stf => {return{name:stf.name,hours:props.timeSheetData.filter(bill=>stf._id===bill.billedToId && bill.taskDate.substring(0,10) === recDate.toString().slice(0, 10)).reduce((a,b) => a + b.billedHours,0)}})} /> }
							{showCards[idx].visible ? props.timeSheetData.filter(rw => rw.taskDate === recDate).map((rec) =>
								<TimeSheetCard data={rec} />
							) : <div style={{ height: "30px" }}></div>}

						</div>
					</div>
				)}
			</div>

		</div>
	)
}

export default CardGrid