import React from 'react'
import "../../Styles/PC/timeSheetCard.css"

const TimeSheetCard = (props) => {



    return (

        
        <div id="task_page__card_1_timesheet">
            <svg class="card_over_all_bg_task_page_timesheet">
                <rect id="card_over_all_bg_task_page_timesheet" rx="10" ry="10" x="0" y="0" width="174" height="182">
                </rect>
            </svg>
            <img id="task_name_bg_card_1_task_page_timesheet" src="task_name_bg_card_1_task_page.png" srcset="task_name_bg_card_1_task_page.png 1x" />

            <img title="Project Name" id="developemtn_img_task_page_timesheet" src="developemtn_img_task_page.png" srcset="developemtn_img_task_page.png 1x" />

            <div id="development_lbl_task_page_timesheet">
                <span>{props.data.project}</span>
            </div>
            <div id="task_name_task_page_timesheet">
                <span>{props.data.task}</span>
            </div>
            <div id="Group_49_timesheet">
                <img  title="Billed Hours" id="time_2_img_task_page_timesheet" src="time_2_img_task_page.png" srcset="time_2_img_task_page.png 1x" />

                <div id="time_2_task_page_timesheet">
                    <span>{props.data.billedHours}</span>
                    <span style={{ fontSize: "10px" }}>({props.data.category})</span>
                </div>
            </div>
            <svg class="work_in_progress_bdr_task_page_timesheet" style={{ fill: props.data.status === "Work in Progress" ? "#3399FF" : props.data.status === "On Hold" ? "#FF9900" : props.data.status === "Completed" ? "#00CC66" : props.data.status === "Cancelled" ? "#FF0000" : "" }}>
                <rect id="work_in_progress_bdr_task_page_timesheet" rx="10.5" ry="10.5" x="0" y="0" width="132" height="21">
                </rect>
            </svg>
            <div id="work_in_progress_lbl_task_page_timesheet">
                <span>{props.data.status}</span>
            </div>
            <div id="Group_48_timesheet">
                <img title="Staff Name" id="assigned_to_pfp_task_page_timesheet" src="assigned_to_pfp_task_page.png" srcset="assigned_to_pfp_task_page.png 1x" />

                <div id="assigned_to_lbl_task_page_timesheet">
                    <span>{props.data.billedToName}</span>
                </div>
            </div>
        </div>
    )
}

export default TimeSheetCard