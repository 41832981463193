import React, { useEffect, useState, useContext } from 'react'
import "../../Styles/PC/clientStaffPopup.css"
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import { UserContext } from '../UserContext';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };


const ClientStaffPopup = (props) => {

    const { user, setUser } = useContext(UserContext);
    const [companyName, setCompanyName] = useState("")
    const [clientUserId, setClientUserId] = useState("")
    const [clientId, setClientId] = useState("")

    const [companyId, setCompanyId] = useState("")
    const [clientStaffName, setClientStaffName] = useState("")
    const [clientDesignation, setClientDesignation] = useState("")
    const [introduction, setIntroduction] = useState("")
    const [mobile, setMobile] = useState("")
    const [remarks, setRemarks] = useState("")
    const [active, setActive] = useState(true)

    const [clientData, setClientData] = useState([])
    const [userData, setUserData] = useState([])

    const [profilePic, setProfilePic] = useState("")
    const [profileImage, setProfileImage] = useState("")



    useEffect(() => {
        if (user) {
            GetClient()
            GetUser()
        }
    }, [user])

    useEffect(() => {
        if (props.display === "Edit") {
            setClientId(props.data ? props.data._id : "")
            setClientUserId(props.data ? props.data.clientUserId : "")
            setCompanyId(props.data ? props.data.companyId : "")
            setCompanyName(props.data ? props.data.companyName : "")
            setClientStaffName(props.data ? props.data.clientStaffName : "")
            setClientDesignation(props.data ? props.data.clientDesignation : "")
            setIntroduction(props.data ? props.data.introduction : "")
            setMobile(props.data ? props.data.mobile : "")
            setRemarks(props.data ? props.data.remarks : "")
            setProfileImage(props.data ? props.data.profilePicUrl : "")
            setActive(props.data ? props.data.active : false)

        }
    }, [props.data, props.display])


    const clearData = () => {
        setClientId("")
        setClientUserId("")
        setCompanyId("")
        setCompanyName("")
        setClientStaffName("")
        setClientDesignation("")
        setIntroduction("")
        setMobile("")
        setRemarks("")
        setProfileImage("")
        setActive(false)
    }


    const addClientStaff = () => {
        if (clientUserId.length === 0 || clientUserId.length === "") {
            return alert("Please Select the Staff Name ")
        }
        if (mobile.length === 0 || mobile.length > 10) {
            return alert("Please Enter Mobile Number")
        }
        
        let apiURL = process.env.REACT_APP_API_URL + "/clientstaff/addClientStaff";
        let apiParams = {
            method: "POST",
            headers: {
                clientuserid: clientUserId,
                companyid: companyId,
                companyname: clientData.filter(rw => rw._id === companyId)[0].companyName,
                clientstaffname: userData.filter((rec) => rec._id === clientUserId)[0].name,
                profilepicurl: userData.filter((rec) => rec._id === clientUserId)[0].profilePicUrl,
                // profilepicurl:userData.filter((rec) => rec._id === staffUserId)[0].profilePicUrl,
                clientdesignation: clientDesignation,
                introduction: introduction,
                mobile: mobile,
                remarks: remarks,
                active: active

            },
        };
       
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup()
                    clearData()
                    alert("Client Staff Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const updateClientStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/clientstaff/editClientStaff";
        let apiParams = {
            method: "POST",
            headers: {
                clientid: props.data._id,
                companyname: props.data.companyName,
                companyid: props.data.companyId,
                clientuserid: props.data.clientUserId,
                clientstaffname: props.data.clientStaffName,
                profilepicurl: props.data.profilePicUrl,
                clientdesignation: clientDesignation,
                introduction: introduction,
                mobile: mobile,
                remarks: remarks,
                active: active
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup()
                    clearData()
                    alert("Staff Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }



    const GetClient = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/client" + ((user && user.usertype === "staff") ? "/getClient" : (user && user.usertype === "client") ? "/getCompaniesByUser/" + (user && user._id) : "");
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setClientData(repos.data)
                    if (repos.data[0]) {
                        setCompanyId(repos.data[0]._id)
                        setCompanyName(repos.data[0].companyName)

                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetUser = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/users/getNewClients";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setUserData(repos.data)

                    if (repos.data[0]) {

                        setClientUserId(repos.data[0]._id)
                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const guardarArchivo = async (e) => {
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = function (e) { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {

                        alert("Image Uploaded Successfully!!") //See response

                        SaveProfilePic(data)
                    }).catch(e => console.log(e)) // Or Error in console
            }
        }

    }
    const SaveProfilePic = (data) => {

        const apiurl = process.env.REACT_APP_API_URL + "/users/addUserProfilePic/" + props.data._id;
        let apiParams = {
            method: "POST",
            headers: {
                profilepicurl: data.url,


            }
        }
        fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("Image Uploaded Successfully");
                }
            })
            .catch(e => console.log(e))
    }

    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProfilePic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            guardarArchivo(e);
        }


    }




    return (
        <div>
            <Modal

                open={props.display === "Add" || props.display === "Edit"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflow: "scroll", textAlign: "center" }}
            >
                <div id="pup_up_add_staff">
                    <svg class="over_all__client_pop_pc">
                        <rect id="over_all__client_pop_pc" rx="15" ry="15" x="0" y="0" width="361" height="772">
                        </rect>
                    </svg>
                    <svg class="top_bg__client_pop_pc_c">
                        <linearGradient id="top_bg__client_pop_pc_c" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                            <stop offset="0" stop-color="#a2ffca" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="top_bg__client_pop_pc_c" rx="15" ry="15" x="0" y="0" width="361" height="94">
                        </rect>
                    </svg>
                    <div id="pop_up__client_pop_pc">
                        <svg class="pfp_behind_circle_bg__client_p">
                            <ellipse id="pfp_behind_circle_bg__client_p" rx="50" ry="50" cx="50" cy="50" style={{ fill: props.data.active === true ? "rgba(119,238,170,1)" : "white" }}>
                            </ellipse>
                        </svg>
                        {/* <img id="profile_pfp_img__client_pop_pc" src="profile_pfp_img__client_pop_pc.png" srcset="profile_pfp_img__client_pop_pc.png 1x" /> */}
                        {(user && user.usertype === "staff" && parseInt(user.useraccess) >= 5) || (user && user._id === props.data.clientUserId) ? 
                            <label for="imageProfilepc" >

                                <input
                                    id="imageProfilepc"
                                    type="file"
                                    name="imageProfilepc"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => profilePicUpload(e)}
                                />
                                {profilePic.length === 0 ? (
                                    <img

                                        id="profile_pfp_img__client_pop_pc"
                                        width="100"
                                        height="100"
                                        src={
                                            profileImage && profileImage.length > 10
                                                ? "https://drive.google.com/uc?export=view&id=" +
                                                profileImage.split('/')[5]
                                                :
                                                "default_image.png"
                                        }
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                ) : (
                                    <img
                                        id="profile_pfp_img__client_pop_pc"
                                        width="100"
                                        height="100"
                                        src={profilePic ? profilePic : "default_image.png"}
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                )}
                            </label>
                            :  <img

                            id="profile_pfp_img__client_pop_pc"
                            width="100"
                            height="100"
                            src={
                                profileImage && profileImage.length > 10
                                    ? "https://drive.google.com/uc?export=view&id=" +
                                    profileImage.split('/')[5]
                                    :
                                    "default_image.png"
                            }
                            style={{ borderRadius: "75px", border: "1px solid black" }}
                        />}

                    </div>
                    <div id="client_pop_pcname_grp_prof">
                        <div id="client_pop_pc_lbl_pfp">
                            <span>Client Staff Name</span>
                        </div>
                        <select tabIndex={2} placeholder="Staff Name" class="client_pop_pc_input_pfp" value={clientUserId} onChange={(e) => setClientUserId(e.target.value)}>
                            {/* {console.log(props.data)} */}

                            {props.display === "Edit" ? <option value={props.data._id}>{props.data.clientStaffName}</option>
                                :
                                <>
                                    <option value="">---Select----</option>
                                    {userData.map((rec) => (
                                        <option value={rec._id}>{rec.name}</option>

                                    ))}


                                </>
                            }

                        </select>
                    </div>
                    <div id="client_pop_pcdesignation_grp_p">
                        <div id="client_pop_pc_designation_lbl_">
                            <span>Client Designation</span>
                        </div>
                        <input tabIndex={3} placeholder="Designation" class="client_pop_pcdesignationinput_" value={clientDesignation} onChange={(e) => setClientDesignation(e.target.value)} />

                    </div>
                    <div id="staff_skill_grp_client_pop_pc">
                        <div id="client_pop_pc_lbl_pfp_dd">
                            <span>Introduction</span>
                        </div>
                        <textarea tabIndex={5} placeholder="Introduction" class="client_pop_pc_input_pfp_de" value={introduction} onChange={(e) => setIntroduction(e.target.value)} />

                    </div>
                    <div id="staff_exp_grp_client_pop_pc">
                        <div id="client_pop_pc_lbl_pfp_dg">
                            <span>Client Company</span>
                        </div>
                        <select tabIndex={1} class="client_pop_pc_input_pfp_dh" value={companyId} onChange={(e) => setCompanyId(e.target.value)}>

                            {props.display === "Edit" ? <option value={props.data.companyId}>{props.data.companyName}</option> :

                                clientData.map((rec) => (
                                    <option value={rec._id}>{rec.companyName}</option>
                                ))


                            }

                            {/* {console.log(clientData)} */}


                        </select>
                    </div>
                    <div id="remark_grp_client_pop_pc">
                        <div id="remark_client_pop_pc_lbl">
                            <span>Remark</span>
                        </div>
                        <textarea tabIndex={6} placeholder="Remark" class="remark_client_pop_pc_input" value={remarks} onChange={(e) => setRemarks(e.target.value)} />

                    </div>
                    <div id="active_grp_client_pop_pc">
                        <div id="active_lbl_client_pop_pc">
                            <span>Active</span>
                        </div>
                        <Switch tabIndex={10} {...label} size="small" className='switch_staff' checked={active} onChange={(e) => setActive(e.target.checked)} />

                        {/* <svg class="active_chkbx_client_pop_pc">
                            <rect id="active_chkbx_client_pop_pc" rx="0" ry="0" x="0" y="0" width="38" height="20">
                            </rect>
                        </svg> */}
                    </div>
                    <button tabIndex={7} id="Add_pup_client_pop_pcgrp" onClick={() => { props.display === "Edit" ? updateClientStaff() : addClientStaff() }}>
                        <svg class="addd_bdr_btn_client_pop_pc">
                            <rect id="addd_bdr_btn_client_pop_pc" rx="8" ry="8" x="0" y="0" width="123" height="41">
                            </rect>
                        </svg>
                        <div id="Add_client_pop_pc_lbl">
                            <span>{props.display}</span>
                        </div>
                    </button>
                    <div id="client_pop_pc_grp">
                        <div id="client_pop_pc_lbl_">
                            <span>Client Mobile</span>
                        </div>
                        <input tabIndex={4} placeholder="Mobile" class="client_pop_pc_pup_op" value={mobile} onChange={(e) => setMobile(e.target.value)} />

                    </div>
                    <img tabIndex={8} id="close_pup_op_client_pop_pc" src="closet_pop_pc.png" srcset="close_pup_op_client_pop_pc.png 1x" onClick={() => { props.closePopup(); clearData() }} />
                </div>
            </Modal>
        </div>
    )
}

export default ClientStaffPopup