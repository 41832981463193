import * as React from 'react';
import PopupUser from './PopupUser'
import "../../Styles/PC/user.css"
import { useState, useEffect, useContext } from "react"
import { UserContext } from '../UserContext';




const User = (props) => {

	// const [addUserPopup, setAddUserPopup] = useState(null);

	// const [popUp, setPopUp] = useState(false);
	// const [popUpData, setPopUpData] = useState({});

	// const [user, setUser] = useState(UserContext)
	const { user, setUser } = useContext(UserContext);

	const [userData, setUserData] = useState([])

	const [displayUser, setDisplayUser] = useState("none")
	const [popUpData, setPopUpData] = useState({});

	useEffect(() => {
		if (user) {
			GetUser()
		}
	}, [user, displayUser])

	useEffect(() => {

		setDisplayUser(props.isAddPopupVisible === true ? "Add" : displayUser === true ? "Edit" : "none")
	}, [props.isAddPopupVisible])

	const GetUser = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setUserData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

	const showAccess = (rec) => {
		switch (parseInt(rec)) {
			case 1:
				return "Executive"
				break;

			case 2:
				return "HR"
				break;
			case 3:
				return "Manager"
				break;
			case 5:
				return "Director"
				break;
			case 10:
				return "Super Admin"
				break;
		}
	}



	// function guardarArchivo(e) {
	// 	var file = e.target.files[0] //the file
	// 	var reader = new FileReader() //this for convert to Base64 
	// 	reader.readAsDataURL(e.target.files[0]) //start conversion...
	// 	reader.onload = function (e) { //.. once finished..
	// 		var rawLog = reader.result.split(',')[1]; //extract only thee file data part
	// 		var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
	// 		fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
	// 			{ method: "POST", body: JSON.stringify(dataSend) }) //send to Api
	// 			.then(res => res.json()).then((a) => {

	// 				console.log(a) //See response
	// 			}).catch(e => console.log(e)) // Or Error in console
	// 	}
	// }



	return (
		<div className='user_page'>

			<img src="over_all_bg_page.png" alt="" srcSet="over_all_bg_page.png 1x" className='bg_overall' />




			<div id="userpage_scroll">
			{/* .filter(rw=>(rw.name.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.email.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.mobile.toString().includes(props.searchText.toLowerCase()) ||rw.userAccess.toLowerCase().includes(props.searchText.toLowerCase())  ||rw.userType.toLowerCase().includes(props.searchText.toLowerCase())  )) */}
				{userData.filter(rw=>(rw.name.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.email.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.mobile.toString().includes(props.searchText.toLowerCase()) ||rw.userAccess.toLowerCase().includes(props.searchText.toLowerCase())  ||rw.userType.toLowerCase().includes(props.searchText.toLowerCase())  )) .map((rec) => (
					<div style={{ position: "relative", display: 'inline-flex' }} >


						<div id="user_card" onClick={() => { setDisplayUser("Edit"); setPopUpData(rec) }}>
							<svg class="card_over_all_bg">
								<rect id="card_over_all_bg" rx="10" ry="10" x="0" y="0" width="174" height="191">
								</rect>
							</svg>
							<img id="card_bg_design" src="card_bg_design.png" srcset="card_bg_design.png 1x" />

							<div id="pfp_grp">
								<svg class="pfp_behind_circle">
									<ellipse id="pfp_behind_circle" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{ fill: rec.active === true ? "rgba(119,238,170,1)" : "White" }}>
									</ellipse>
								</svg>
								<img id="profile_pic_card" src={
									rec.profilePicUrl && rec.profilePicUrl.length
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }} />
							</div>
							<img title="Email" id="email_lbl" src="email_lbl.png" srcset="email_lbl.png 1x" />

							<div id="affanghanigmailcom_lbl">
								<marquee behavior="scroll" direction="">
									<span>{rec.email}</span>
								</marquee>
							</div>
							<img title="Mobile Number" id="iphone-5_lbl" src="iphone-5_lbl.png" srcset="iphone-5_lbl.png 1x" />

							<div id="n_234567890_mobile_number">
								<span>{rec.mobile}</span>
							</div>
							<img title="Access" id="manager_lbl" src="manager_lbl.png" srcset="manager_lbl.png 1x" />

							<div id="Admin_lbl">
								<span>{rec.userType}</span>
							</div>
							<img title="User Role" id="remark_lbl" src="remark_lbl.png" srcset="remark_lbl.png 1x" />

							<div id="Remark_lbl_b">
								<span>{showAccess(rec.userAccess)}</span>
							</div>

							<div id="Mohammed_Affan_lbl">
								<span>{rec.name}</span>
							</div>


						</div>

					</div>
				))}

			</div>

			{/* <div id="add_btn_user">
				<svg class="add_bg" onClick={() => setAddUserPopup(true)}>
					<rect id="add_bg" rx="8" ry="8" x="0" y="0" width="97" height="36">
					</rect>
				</svg>
				<svg class="Icon_ionic-md-add1" viewBox="6.75 6.75 18 18" onClick={() => setAddUserPopup(true)}>
					<path id="Icon_ionic-md-add1" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
					</path>
				</svg>
				<div id="Active_lbl_bn" >
					<span onClick={() => setAddUserPopup(true)}>Add User</span>
					
					
				</div>
			</div> */}

			{/* <div id="timeline_pageassignment_grp_user">
                    <div id="Project_Assignments_lbl_timeli_user">
                        <span>Project Assignments</span>
                    </div>
                    <img id="logo_Project_Assignments_timel_user" src="logo_Project_Assignments_timel.png" srcset="logo_Project_Assignments_timel.png 1x" />

                </div> */}
			{/* <PopupUser isOpen={popUp} data={popUpData} closePopup={(value) => setPopUp(value)} /> */}

			{/* <PopupUser isOpen={addUserPopup} closePopup={(value) => setAddUserPopup(value)} /> */}

			<PopupUser display={displayUser} data={popUpData} closePopup={() => { props.closeAddPopup(); setDisplayUser("none") }} />

		</div>
	)
}

export default User