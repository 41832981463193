import React ,{useState,useContext} from 'react'
import "../../Styles/Mob/loginMob.css"
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom'
import {useNavigate} from "react-router-dom";

const LoginMob = () => {

    var navigate = useNavigate();
    const [email,setEmail] = useState("")
    const [password, setPass] = useState("")
    
    let emailSubmit = (e) => {
        setEmail(e.target.value)
    }
    
    let passSubmit = (e1) => {
        setPass(e1.target.value)
    }
    
    
    
    // bzbvb
    const { user, setUser} = useContext(UserContext);
    
        const redirect = () =>{
            if(email.length === 0){
                return	alert("Please Enter Email!!");
                }
            if(password.length === 0){
                return	alert("Please Enter Password!!");
                }
            const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
            var apiParam = {
              method: 'POST',
              headers: { email: email, password: password },
              credentials: 'same-origin'
            };
            fetch(apiUrl, apiParam)
              .then((res) => (res.status === 200 ? res.json() : res.text()))
              .then((repos) => {
                if (repos.data) {
                    console.log(repos.data)
                  localStorage.setItem("loggedintoken", repos.data);
                  localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
                  setUser(JSON.parse(atob(repos.data.split(".")[1])))
              
                  navigate("/dashboardmob");
                } else alert(JSON.stringify(repos));
              })
              .catch((err) => alert(JSON.stringify(err)));
           };
    return (
        <div>

            <div id="mob_login">
                <img id="emile-perr_mob_login_proj" src="emile-perr_mob_login_proj.png" srcset="emile-perr_mob_login_proj.png 1x" />

                <div id="mid_overall_mob_login_proj">
                    <svg class="bg_dummy_mob">
                        <rect id="bg_dummy_mob" rx="17" ry="17" x="0" y="0" width="321" height="436">
                        </rect>
                    </svg>
                    <img id="logo_img_mob_login_proj" src="logo_img_mob_login_proj.png" srcset="logo_img_mob_login_proj.png 1x" />

                    <div id="Project_Assignments_mob_login_">
                        <span>Project Assignments</span>
                    </div>
                    <button id="btn_grp_mob_login_proj" tabIndex={3} onClick={()=>redirect()}>
                        <svg class="sign_in_bdr_mob_login_proj_da">
                            <linearGradient id="sign_in_bdr_mob_login_proj_da" spreadMethod="pad" x1="0.5" x2="0.5" y1="1" y2="-0.936">
                                <stop offset="0" stop-color="#4ce26f" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="#569a66" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="sign_in_bdr_mob_login_proj_da" rx="6" ry="6" x="0" y="0" width="288" height="45">
                            </rect>
                        </svg>
                        <div id="Sign_in_mob_login_proj">
                            <span>Sign in</span>
                        </div>
                    </button>
                    <input placeholder="Email" type='email' tabIndex={1} class="username_mob_login_proj" value={email} onChange={emailSubmit}/>

                    <input placeholder="Password" type='password' tabIndex={2} class="password_mob_login_proj" value={password} onChange={passSubmit}/>

                    {/* <div id="Forgot_Password_mob_login_proj">
                        <span>Forgot Password</span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default LoginMob