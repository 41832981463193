import React ,{useContext, useEffect, useState}from 'react'
import "../../Styles/PC/searchBar.css"
import { UserContext } from '../UserContext';
import { Switch, useLocation } from 'react-router-dom';



const SearchBar = (props) => {

	let location = useLocation()

	const { user, setUser } = useContext(UserContext);
	// const { showAddButton, setShowAddButton } = useContext(UserContext);


	useEffect(()=>{
// console.log(window.location.pathname)
	},[user,location.pathname])

	return (
		<div>
			<div id="search_component">
				<input placeholder="Search Content" class="search_place" value={props.searchText} onChange={(e)=>props.setSearchText(e.target.value)}/>

				<img id="search" src="search.png" srcset="search.png 1x" />
				{/* {console.log(props.isAddVisible)} */}
{(((props.isAddVisible === true) && (user && user.usertype === "staff" && user.useraccess >= 5 && (window.location.pathname !== "/dashboard" && window.location.pathname !== "/timesheet" )))||((props.isAddVisible === true ) && (user && user.usertype === "staff"  && (window.location.pathname === "/projects"))) || (user.usertype === "client" && (window.location.pathname === "/projects" || window.location.pathname === "/clients")) || ((props.isAddVisible === true) &&(user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ))) &&  
				 <button id="add_btn_overall_page" onClick={()=>props.openAddPopup()}>
					<svg class="add_bg_overall_page">
						<rect id="add_bg_overall_page" rx="8" ry="8" x="0" y="0" width="68" height="36">
						</rect>
					</svg>
					<svg class="Icon_ionic-searchpage" viewBox="6.75 6.75 18 18">
						<path id="Icon_ionic-searchpage" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
						</path>
					</svg>
					<div id="add_overall_lbl_page">
						<span>Add</span>
					</div>
				</button> 
				 }
				

			</div>
			
		</div>
	)
}

export default SearchBar