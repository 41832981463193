import React from 'react'
import "../../Styles/PC/logoName.css"

const LogoName = () => {
	return (

		<div id="proj_assignment_grp" >
			<div id="Project_Assignments_lbl">
				<span>Project Assignments</span>
			</div>
			<div id="logo_container"><img id="logo_Project_Assignments" src="logo_Project_Assignments.png" srcset="logo_Project_Assignments.png 1x" />
			</div>

		</div>

	)
}

export default LogoName