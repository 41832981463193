import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/PC/taskPage.css"
import PopUpTask from './PopUpTask'
import { UserContext } from '../UserContext'
import TaskCard from './TaskCard'
import Team from './Team'
import ProjectPopup from './ProjectPopup'
import { flushSync } from 'react-dom'

const TaskPage = (props) => {

	const { user } = useContext(UserContext);
	// const { showAddButton, setShowAddButton } = useContext(UserContext);



	const [taskData, setTaskData] = useState([])
	const [display, setDisplay] = useState("none")

	const [categoryData, setCategoryData] = useState([])

	const [moduleGet, setModuleGet] = useState(UserContext)
	const [moduleData, setModuleData] = useState([])

	const [editRowNum, setEditRowNum] = useState(-1)
	const [editRowNumModule, setEditRowNumModule] = useState(-1)

	const [teamData, setTeamData] = useState([])
	// const [addTeamPopup, setAddTeamPopup] = useState(false)

	const [staffData, setStaffData] = useState([])



	const [showUnAssigned, setShowUnAssigned] = useState(true)
	const [showAssigned, setShowAssigned] = useState(true)
	const [showWip, setShowWip] = useState(true)
	const [showHold, setShowHold] = useState(true)
	const [showCompleted, setShowCompleted] = useState(true)
	const [showCancelled, setShowCancelled] = useState(true)

	// const [showPopup, setShowPopup] = useState(false)
	const [showTeamPopup, setShowTeamPopup] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState({});
	const [editPopup, setEditPopUp] = useState(false)
	const [editData, setEditData] = useState({})

	const [displayTask, setDisplayTask] = useState("none")
	const [showData, setShowData] = useState({});




	const [category, setCategory] = useState("")
	const [active, setActive] = useState(true)
	const [createdById, setCreatedById] = useState("")
	const [createdByName, setCreatedByName] = useState("")
	const [projectId, setProjectId] = useState("")
	const [projectName, setProjectName] = useState("")
	const [module, setModule] = useState("")



	const addCategory = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/category/addCategory";
		let apiParams = {
			method: "POST",
			headers: {

				projectid: props.project._id,
				projectname: props.project.projectName,
				createdbyid: user._id,
				createdbyname: user.name,
				category: category,
				active: active,

			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Added Successfully!!");

				} else alert(repos.message || repos);
				GetCategory()

			})
			.catch((err) => alert(err));

	}

	useEffect(() => {
		if (props.project._id) {
			GetCategory()
			
		}
	}, [props.project])


	// useEffect(() => {
	// // alert(1)
	// }, [categoryData])

	useEffect(() => {
		if (props.project._id,user) {

			GetTeam()
		}
	}, [props.project, display,user])
	// useEffect(() => {

	// }, [editRowNum,editRowNumModule])

	useEffect(() => {
		if (props.project)
			GetTask()
	}, [props.project, displayTask])

	useEffect(() => {
		setDisplayTask(props.isAddPopupVisible === true ? "Add" : displayTask === true ? "Edit" : "none")
	}, [props.isAddPopupVisible])

	useEffect(() => {

		GetStaff()

	}, [props.project._id])

	useEffect(() => {
		if (moduleGet) {
			GetModule()
		}
	}, [])

	const GetCategory = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/category/getCategories/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setCategoryData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	const GetTeam = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/team/GetTeam/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
				// alert(	((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
					if(((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
					 {
						props.showAddButton(false)
					}
					else{
						props.showAddButton(true)
					}
					
					setTeamData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	const saveCategory = (categoryId, category, active) => {
		let apiURL = process.env.REACT_APP_API_URL + "/category/editCategory";
		let apiParams = {
			method: "POST",
			headers: {
				categoryid: categoryId,
				category: category,
				active: active,
			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Details Updated Successfully!!");

					GetCategory()

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	const GetStaff = () => {

		// console.log(props.project._id)

		let apiURL = process.env.REACT_APP_API_URL + "/users/getStaffUsers/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setStaffData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

	const addModule = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/addModule";
		let apiParams = {
			method: "POST",
			headers: {
				projectid: props.project._id,
				projectname: props.project.projectName,
				createdbyid: user._id,
				createdbyname: user.name,
				module: module,
				active: active,
			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Added Successfully!!");


				} else alert(repos.message || repos);
				GetModule()
			})
			.catch((err) => alert(err));

	}

	const GetModule = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/getModules/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setModuleData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}
	const saveModule = (moduleId, module, active) => {
		let apiURL = process.env.REACT_APP_API_URL + "/modules/editModule";
		let apiParams = {
			method: "POST",
			headers: {
				moduleid: moduleId,
				module: module,
				active: active,
			},
		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {
					alert("Details Updated Successfully!!");


				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

	const handleTextChange = (text, index) => {
		var newArray = categoryData;
		newArray[index].category = text;

		setCategoryData([...newArray])
	}
	const handleDelete = (i) => {
		const deleteVal = [...categoryData]
		deleteVal.splice(i, 1)
		setCategoryData(deleteVal)

	}

	const handleModule = (text, index) => {
		var newArray = moduleData;
		newArray[index].module = text;

		setModuleData([...newArray])
	}
	const moduleDelete = (i) => {
		const deleteVal2 = [...moduleData]
		deleteVal2.splice(i, 1)
		setModuleData(deleteVal2)
	}


	const GetTask = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/task/getTasks/" + props.project._id;
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				// console.log(repos.data)
				if (repos.data) {

					setTaskData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}


	return (
		<div>
			<div id="task_page">
				<img id="over_all_bg_project_page" src="over_all_bg_project_page.png" srcset="over_all_bg_project_page.png 1x" />

<div id="category_module_team_grp">
				<div id="categories_overall_card">
					<button id="grpTask_page" >
						<svg class="bgTask_page">
							<rect id="bgTask_page" rx="15" ry="15" x="0" y="0" width="197" height="100%">
							</rect>
						</svg>
						<div id="categories_Task_page">
							<span>Categories</span>
						</div>
						{((editRowNum < categoryData.length - 1 || editRowNum === -1) && ((user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client") ) )? 
							<svg class="Icon_ionic-minus_Task_page" viewBox="6.75 6.75 18 18" onClick={() => { setCategoryData([...categoryData, { category: "", projectId: props.project._id, project: props.project.projectName, active: true }]); setEditRowNum(categoryData.length) }}>
								<path id="Icon_ionic-minus_Task_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
								</path>
							</svg>
							: ""}

					</button>
					<div id="category_scroll">
					{
						categoryData.map((rec, idx) => (
							<div>
								{/* {console.log(idx,editRowNum)} */}

								<div>{idx !== editRowNum ?

									<div id="documentation_grp_category">
										<div id="add_minus_Task_page">
											<span>{rec.category}</span>
										</div>
										{(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client") ? 
											<>
												<div id="minus_Task_page">
													<svg class="add_bg_minus_Task_page" onClick={() => { saveCategory(rec._id, rec.category, false); handleDelete(idx) }}>

														<rect id="add_bg_minus_Task_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
														</rect>
													</svg>
													<svg class="Icon_ionic-minus_Task_page_cd" onClick={() => { saveCategory(rec._id, rec.category, false); handleDelete(idx) }} viewBox="6.75 11.167 10.193 1.359" >
														<path id="Icon_ionic-minus_Task_page_cd" d="M 16.94312858581543 12.52609539031982 L 12.52609539031982 12.52609539031982 L 11.16703319549561 12.52609539031982 L 6.75 12.52609539031982 L 6.75 11.16703319549561 L 11.16703319549561 11.16703319549561 L 12.52609539031982 11.16703319549561 L 16.94312858581543 11.16703319549561 L 16.94312858581543 12.52609539031982 Z">
														</path>
													</svg>
												</div>
												<div id="edit_grp_Task_page">

													<svg class="add_bg_edit_grp">
														<rect id="add_bg_edit_grp" rx="5" ry="5" x="0" y="0" width="20" height="20">
														</rect>
													</svg>
													<img id="pencil_1_edit_grp" src="pencil_1_edit_grp.png" srcset="pencil_1_edit_grp.png 1x" onClick={() => { setEditRowNum(idx) }} />

												</div>
											</>
											: ""}
									</div>
									:
									<div id="input_categories_grp">
										<div id="cancel_grp_Task_page">
											<svg class="add_bgcancel" onClick={() => {setCategoryData([...categoryData.filter(rw=>rw.categoryId)]); setEditRowNum(-1) }} >
												<rect id="add_bgcancel" rx="5" ry="5" x="0" y="0" width="20" height="20">
												</rect>
											</svg>
											<svg class="Icon_ioniccancel" onClick={() => {setCategoryData([...categoryData.filter(rw=>rw.categoryId)]);  setEditRowNum(-1) }} viewBox="6.75 6.75 14.056 14.056" >
												<path id="Icon_ioniccancel" d="M 20.80647850036621 14.71532344818115 L 14.71532344818115 14.71532344818115 L 14.71532344818115 20.80647850036621 L 12.84115600585938 20.80647850036621 L 12.84115600585938 14.71532344818115 L 6.75 14.71532344818115 L 6.75 12.84115600585938 L 12.84115505218506 12.84115600585938 L 12.84115505218506 6.75 L 14.71532344818115 6.75 L 14.71532344818115 12.84115505218506 L 20.80647850036621 12.84115505218506 L 20.80647850036621 14.71532344818115 Z">
												</path>
											</svg>
										</div>
										<div id="save_grp_bdr_Task_page">
											<svg class="save_bdr_Task_page">
												<rect id="save_bdr_Task_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
												</rect>
											</svg>
											<img id="diskette_2_Task_page" src="diskette_2_Task_page.png" srcset="diskette_2_Task_page.png 1x" onClick={() => { (setEditRowNum(-1)); rec._id ? saveCategory(rec._id, category, active) : addCategory() }} />
										</div>
										<input placeholder="Category" type='text' class="edit_documentation" value={rec.category} onChange={(e) => { (setCategory(e.target.value)); handleTextChange(e.target.value, idx) }} />

									</div>}
								</div>
							</div>
						))
					}
					</div>
				</div>
				<div id="Modules_over_all_grp_task_page">


					<button id="module_">
						<svg class="bdr_module_Task_page">
							<rect id="bdr_module_Task_page" rx="15" ry="15" x="0" y="0" width="197" height="100%">
							</rect>
						</svg>
						{(editRowNumModule < moduleData.length - 1 || editRowNumModule === -1) && ((user && user.usertype === "staff" &&  user.useraccess >= 5) ||(user && user.usertype === "staff" && parseInt(user.useraccess) >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client")) ? 
							<svg class="add_btn_Task_page" onClick={() => { setModuleData([...moduleData, { module: "", projectId: props.project._id, project: props.project.projectName, active: true }]); setEditRowNumModule(moduleData.length) }} viewBox="6.75 6.75 18 18">
								<path id="add_btn_Task_page" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
								</path>
							</svg>
							: ""}

						<div id="module_lbl_Task_page">
							<span>Modules</span>
						</div>
					</button>
					<div id="module_scroll">
					{moduleData.filter(rw=>rw.active === true ).map((rec, idx) => {

							return (
								<div>{idx !== editRowNumModule ?
									<div id="documentation_grp_module">
										<div id="documentation_lbl_">
											<span>{rec.module}</span>
										</div>
										{(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client") ? 
											<>
												<div id="minus_grp">
													<svg class="bdr_minus_Task_page" onClick={() => { saveModule(rec._id, rec.module, false); moduleDelete(idx) }}>
														<rect id="bdr_minus_Task_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
														</rect>
													</svg>
													<svg class="minus_path_" onClick={() => { saveModule(rec._id, rec.module, false); moduleDelete(idx) }} viewBox="6.75 11.167 10.193 1.359">
														<path id="minus_path_" d="M 16.94312858581543 12.52609539031982 L 12.52609539031982 12.52609539031982 L 11.16703319549561 12.52609539031982 L 6.75 12.52609539031982 L 6.75 11.16703319549561 L 11.16703319549561 11.16703319549561 L 12.52609539031982 11.16703319549561 L 16.94312858581543 11.16703319549561 L 16.94312858581543 12.52609539031982 Z">
														</path>
													</svg>
												</div>
												<div id="edit_btn_grp">
													<svg class="bdr_edit_option">
														<rect id="bdr_edit_option" rx="5" ry="5" x="0" y="0" width="20" height="20">
														</rect>
													</svg>
													<img id="pencil_1_Task_page" src="pencil_1_Task_page.png" srcset="pencil_1_Task_page.png 1x" onClick={() => setEditRowNumModule(idx)} />

												</div>
											</>
											: ""}
									</div>
									:
									<div id="input_grp_modules">
										<div id="cancel_btn_grp">
											<svg class="add_bg_client_page" onClick={() => {setModuleData([...moduleData.filter(rw=>rw.moduleIdId)]);setEditRowNumModule(-1)}}>
												<rect id="add_bg_client_page" rx="5" ry="5" x="0" y="0" width="20" height="20">
												</rect>
											</svg>
											<svg class="Icon_ioniccancel" onClick={() => {setModuleData([...moduleData.filter(rw=>rw.moduleIdId)]); setEditRowNumModule(-1)}} viewBox="6.75 6.75 14.056 14.056">
												<path id="Icon_ioniccancel" d="M 20.80647850036621 14.71532344818115 L 14.71532344818115 14.71532344818115 L 14.71532344818115 20.80647850036621 L 12.84115600585938 20.80647850036621 L 12.84115600585938 14.71532344818115 L 6.75 14.71532344818115 L 6.75 12.84115600585938 L 12.84115505218506 12.84115600585938 L 12.84115505218506 6.75 L 14.71532344818115 6.75 L 14.71532344818115 12.84115505218506 L 20.80647850036621 12.84115505218506 L 20.80647850036621 14.71532344818115 Z">
												</path>
											</svg>

										</div>
										<div id="save_btn_grp_">
											<svg class="bdr_save_btn_">
												<rect id="bdr_save_btn_" rx="5" ry="5" x="0" y="0" width="20" height="20">
												</rect>
											</svg>
											<img id="diskette_2" src="diskette_2.png" srcset="diskette_2.png 1x" onClick={() => { setEditRowNumModule(-1); rec._id ? saveModule(rec._id, module, active) : addModule() }} />

										</div>
										<input placeholder="Module" class="text_box_module_task_page" value={rec.module} onChange={(e) => { (setModule(e.target.value)); (handleModule(e.target.value, idx)); handleModule(e.target.value, idx) }} />

									</div>
								}
								</div>
							)
						})}
</div>





				</div>
				<div id="Modules_over_all_grp_task_page_eb">
					<button id="team_area_grp" >
						<svg class="bdr_module_Task_page_team">
							<rect id="bdr_module_Task_page_team" rx="15" ry="15" x="0" y="0" width="197" height="100%">
							</rect>
						</svg>
						{(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0)|| (user && user.usertype === "client") ? 

							<svg class="add_btn_Task_page_team" viewBox="6.75 6.75 18 18" onClick={() => setDisplay("Add")} style={{ cursor: "pointer" }}>
								<path id="add_btn_Task_page_team" d="M 24.75000190734863 16.94998168945312 L 16.94998168945312 16.94998168945312 L 16.94998168945312 24.75000190734863 L 14.55002021789551 24.75000190734863 L 14.55002021789551 16.94998168945312 L 6.75 16.94998168945312 L 6.75 14.55002021789551 L 14.55001831054688 14.55002021789551 L 14.55001831054688 6.75 L 16.94998168945312 6.75 L 16.94998168945312 14.55001831054688 L 24.75000190734863 14.55001831054688 L 24.75000190734863 16.94998168945312 Z">
								</path>
							</svg>
							: ""}
						<div id="team_lbl_Task_page">
							<span>Team</span>
						</div>
					</button>
					{/* {console.log(selectedStaff,staffData)} */}
					{props.project && <Team display={display} project={props.project} selectedStaff={selectedStaff} staffData={staffData} closePopupTeam={() => setDisplay("none")}  teamData={teamData}/>}
					<div id="Scroll_team_cards">

						{(teamData || []).map((rec) => (
							<div id="team_grp_module" onClick={() =>  {setDisplay("Edit"); setSelectedStaff(rec)}}>
								<svg class="team_bdr_">
									<rect id="team_bdr_" rx="5" ry="5" x="0" y="0" width="100%" height="64">
									</rect>
								</svg>
								<div id="documentation_lbl_team_staff">
									<span>{rec.staffName}</span>
								</div>
								<div id="documentation_lbl_role_team">
									<span>{rec.role}</span>
								</div>
								<div id="documentation_lbl_date_team">
									<span>{rec.startDate.toString().slice(0, 10)} {rec.endDate.toString().slice(0, 10)}</span>
								</div>

							</div>

						))}
						{/* {props.project && <Team isOpen={showTeamPopup} staff={selectedStaff} project={props.project} staffData={staffData} closePopupTeam={(value) => setShowTeamPopup(value) } />} */}
{/* {console.log(selectedStaff,user)} */}
					</div>
				</div>
				</div>
				<div id="back_btn">
					<img id="right-arrow_2_back_btn_task" onClick={() => props.goBack()} src="back_btn.png" srcset="back_btn.png 1x, back_btn@2x.png 2x" />
				</div>
				<div id="titles_and_back_btn_grp">
					<div id="unassigned">
						<span>{props.project.projectName}</span>
					</div>
				</div>
				{(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client")? 
					<button onClick={() => { setEditPopUp(true); setEditData(props.project) }}>
						<img id="pen_1" src="pen_1.png" srcset="pen_1.png 1x" />
					</button>
					: ""}
				<ProjectPopup isOpen={editPopup} data={editData} closePopup3={(value) => setEditPopUp(value)} selectProject={(value)=>{}}/>
				<div id="all_status_scroll">

					<div>

						<div id="unassigned_status_drop_grp">
							<div id="top_status_area">
								<div id="unassigned_lbl_">
									<span>Unassigned</span>
								</div>

								<img id="right-arrow_2_drop_btn" src={showUnAssigned ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowUnAssigned(!showUnAssigned)} />

								<svg class="long_line_unassigned" viewBox="0 0 761 1">
									<path id="long_line_unassigned" d="M 0 0 L 761 0">
									</path>
								</svg>
								<svg class="left_arrow_line_" viewBox="0 0 14 1">
									<path id="left_arrow_line_" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							
							{showUnAssigned ? taskData.filter(row => row.status === "Unassigned" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
								<div id="caard_1_status_drop_tax_page_s">

									<TaskCard task={rec} project={props.project} teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
						</div>
						<div id="assigned_status_drop_grp">
							<div id="top_status_area_assigned_">
								<div id="assigned_lbl_">
									<span>Assigned</span>
								</div>

								<img id="right-arrow_2_drop_btn" src={showAssigned ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowAssigned(!showAssigned)} />

								<svg class="long_line_assigned_" viewBox="0 0 761 1">
									<path id="long_line_assigned_" d="M 0 0 L 761 0">
									</path>
								</svg>
								<svg class="left_arrow_line_assigned_" viewBox="0 0 14 1">
									<path id="left_arrow_line_assigned_" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							{showAssigned ? taskData.filter(row => row.status === "Assigned" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (

								<div id="caard_1_status_drop_tax_page_s">
									<TaskCard task={rec} project={props.project} teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}


						</div>
						<div id="work_in_progress_status_drop_g">
							<div id="top_status_area_work_in_progre">
								<div id="work_in_progresslbl_">
									<span>Work in progress</span>
								</div>
								<img id="right-arrow_2_drop_btn" src={showWip ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowWip(!showWip)} />


								<svg class="long_line_work_in_progress" viewBox="0 0 762 1">
									<path id="long_line_work_in_progress" d="M 0 0 L 762 0">
									</path>
								</svg>
								<svg class="left_arrow_line__work_in_progr" viewBox="0 0 14 1">
									<path id="left_arrow_line__work_in_progr" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							{showWip ? taskData.filter(row => row.status === "Work in Progress" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
								<div id="caard_1_status_drop_tax_page_s">
									<TaskCard task={rec} project={props.project} teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
						</div>
						<div id="on_hold_status_drop_grp">
							<div id="top_status_area_completed_">
								<div id="completed_lbl_">
									<span>On Hold</span>
								</div>
								<img id="right-arrow_2_drop_btn" src={showHold ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowHold(!showHold)} />
								<svg class="long_line_completed_" viewBox="0 0 762 1">
									<path id="long_line_completed_" d="M 0 0 L 762 0">
									</path>
								</svg>
								<svg class="left_arrow_line_completed_" viewBox="0 0 14 1">
									<path id="left_arrow_line_completed_" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							{showHold ? taskData.filter(row => row.status === "On Hold" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
								<div id="caard_1_status_drop_tax_page_s">

									<TaskCard task={rec} project={props.project} teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
						</div>
						<div id="completed_status_drop_grp">
							<div id="top_status_area_completed__f">
								<div id="completed_lbl__f">
									<span>Completed</span>
								</div>
								<img id="right-arrow_2_drop_btn" src={showCompleted ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowCompleted(!showCompleted)} />


								<svg class="long_line_completed__f" viewBox="0 0 762 1">
									<path id="long_line_completed__f" d="M 0 0 L 762 0">
									</path>
								</svg>
								<svg class="left_arrow_line__completed_" viewBox="0 0 14 1">
									<path id="left_arrow_line__completed_" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							{showCompleted ? taskData.filter(row => row.status === "Completed" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
								<div id="caard_1_status_drop_tax_page_s" >


									<TaskCard task={rec} project={props.project} teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
						</div>
						<div id="cancelled_status_drop_grp">
							<div id="top_status_areacancelled">
								<div id="cancelledlbl_">
									<span>Cancelled</span>
								</div>

								<img id="right-arrow_2_drop_btn" src={showCancelled ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowCancelled(!showCancelled)} />

								<svg class="long_line_cancelled" viewBox="0 0 762 1">
									<path id="long_line_cancelled" d="M 0 0 L 762 0">
									</path>
								</svg>
								<svg class="left_arrow_linecancelled_" viewBox="0 0 14 1">
									<path id="left_arrow_linecancelled_" d="M 14 0 L 0 0">
									</path>
								</svg>
							</div>
							{showCancelled ? taskData.filter(row => row.status === "Cancelled" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
								<div id="caard_1_status_drop_tax_page_s">

									<TaskCard task={rec} project={props.project}  teamData={teamData} setDisplayTask = {()=> setDisplayTask("Edit") } setShowData = {()=> setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
						</div>

					</div>

				</div>

				<PopUpTask display={displayTask} data={showData} teamData={teamData} closePopup={() => { props.closeAddPopup(); setDisplayTask("none") }} project={props.project} />
				{/* {console.log(display)} */}
				{/* <PopUpTask isOpen={showPopup} data={taskData}  closePopup={(value) => setShowPopup(value)} /> */}

			</div>

		</div>
	)
}

export default TaskPage