import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/PC/popupStaff.css"
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import { UserContext } from '../UserContext';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };

const PopupStaff = (props) => {

    const [staffName, setStaffName] = useState("")
    const [staffDesignation, setStaffDesignation] = useState("")
    const [staffExpirienceMonths, setStaffExpirienceMonths] = useState(0)
    const [staffExpirienceYears, setStaffExpirienceYears] = useState(0)
    const [staffSkills, setStaffSkills] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [staffRemarks, setStaffRemarks] = useState("")
    const [staffActive, setStaffActive] = useState(true)

    const [staffId, setStaffId] = useState("")
    const [staffUserId, setStaffUserId] = useState("")

    const [profilePic, setProfilePic] = useState("")
    const [profileImage, setProfileImage] = useState("")



    // const [user] = useState(UserContext)
    const { user, setUser } = useContext(UserContext);

    const [userData, setUserData] = useState([])
    const [visibleUser, setVisibleUser] = useState([])




    useEffect(() => {
        // console.log(props.data)
        if (props.display === "Edit") {
            setStaffId(props.data ? props.data._id : "")
            setStaffUserId(props.data ? props.data.staffUserId : "")
            setStaffName(props.data ? props.data.staffName : "")
            //  console.log(props.data.staffName)
            setStaffDesignation(props.data ? props.data.staffDesignation : "")
            setStaffExpirienceMonths(props.data ? props.data.staffExpirienceMonths % 12 : "")
            setStaffExpirienceYears(props.data ? parseInt(props.data.staffExpirienceMonths / 12) : "")
            setStaffSkills(props.data ? props.data.staffSkills : "")
            setStaffMobile(props.data ? props.data.staffMobile : "")
            setStaffRemarks(props.data ? props.data.staffRemarks : "")
            setProfileImage(props.data ? props.data.profilePicUrl : "")
            setStaffActive(props.data ? props.data.staffActive : false)
        }


    }, [props.data])

    useEffect(() => {
        if (user) {
            GetUser()
        }
    }, [user])

    const updateStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/staff/editStaff";
        let apiParams = {
            method: "POST",
            headers: {
                staffid: props.data._id,
                staffuserid: props.data.staffUserId,
                staffname: props.data.staffName,
                profilepicurl: props.data.profilePicUrl,
                staffdesignation: staffDesignation,
                staffexpiriencemonths: (parseInt(staffExpirienceYears) * 12) + parseInt(staffExpirienceMonths),
                staffskills: encodeURI(staffSkills),
                staffmobile: staffMobile,
                staffactive: staffActive,
                staffremarks: encodeURI(staffRemarks),
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup2()
                    clearData()
                    alert("Staff Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    const addStaff = () => {

        if (staffMobile.length === 0 || staffMobile.length > 10) {
            return alert("Please Enter Mobile Number")
        }
        if (staffDesignation.length === 0 || staffDesignation.length === "") {
            return alert("Please Enter Your  Designation")
        }
        if (staffExpirienceMonths === 0 || staffExpirienceMonths === "") {
            return alert("Please Enter The Expirience Months")
        }
        if (staffSkills === 0 || staffSkills === "") {
            return alert("Please Enter the Skills")
        }


        let apiURL = process.env.REACT_APP_API_URL + "/staff/addStaff";
        let apiParams = {
            method: "POST",
            headers: {
                staffuserid: staffUserId,
                staffname: userData.filter((rec) => rec._id === staffUserId)[0].name,
                profilepicurl: userData.filter((rec) => rec._id === staffUserId)[0].profilePicUrl,
                staffdesignation: staffDesignation,
                staffexpiriencemonths: (parseInt(staffExpirienceYears) * 12) + parseInt(staffExpirienceMonths),
                staffskills: encodeURI(staffSkills),
                staffmobile: staffMobile,
                staffremarks: encodeURI(staffRemarks),
                staffactive: staffActive

            },
        };
        // console.log(apiParams,staffUserId)
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup2()
                    clearData()
                    alert("Staff Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }





    const GetUser = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/users/getNewUsers";
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setUserData(repos.data)

                    if (repos.data[0]) {
                        setStaffUserId(repos.data[0]._id)
                    }
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const guardarArchivo = async (e) => {
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = function (e) { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {

                        // alert("Image Uploaded Successfully!!") //See response

                        SaveProfilePic(data)
                    }).catch(e => console.log(e)) // Or Error in console
            }
        }

    }
    const SaveProfilePic = (data) => {

        const apiurl = process.env.REACT_APP_API_URL + "/users/addUserProfilePic/" + props.data._id;
        let apiParams = {
            method: "POST",
            headers: {
                profilepicurl: data.url,


            }
        }
        fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("Image Uploaded Successfully");
                }
            })
            .catch(e => console.log(e))
    }

    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProfilePic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            guardarArchivo(e);
        }


    }

    const clearData = () => {

        setStaffId("")
        setStaffUserId("")
        setStaffName("")
        setStaffDesignation("")
        setStaffExpirienceMonths(0)
        setStaffExpirienceYears(0)
        setStaffSkills("")
        setStaffMobile("")
        setStaffRemarks("")
        setStaffId("")
        setProfileImage("")




    }


    return (


        <div>
            <Modal

                open={props.display === "Add" || props.display === "Edit"}
                onClose={() => props.closePopup2(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflow: "scroll", textAlign: "center" }}
            >



                <div id="pup_up_add_staff">
                    <svg class="over_all_staff_page">
                        <rect id="over_all_staff_page" rx="15" ry="15" x="0" y="0" width="361" height="742">
                        </rect>
                    </svg>
                    <svg class="top_bg_staff_page_y">
                        <linearGradient id="top_bg_staff_page_y" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                            <stop offset="0" stop-color="#a2ffca" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="top_bg_staff_page_y" rx="15" ry="15" x="0" y="0" width="361" height="94">
                        </rect>
                    </svg>
                    <div id="pop_up_add_staff_pfp">
                        <svg class="pfp_behind_circle_bg_staff">
                            <ellipse id="pfp_behind_circle_bg_staff" rx="50" ry="50" cx="50" cy="50" style={{ fill: staffActive === true ? "rgba(119,238,170,1)" : "White" }}>
                            </ellipse>
                        </svg>
                        {/* <img id="profile_pfp_img_staff" src="profile_pfp_img.png" srcset="profile_pfp_img.png 1x" /> */}
                        {(user && user.usertype === "staff" && parseInt(user.useraccess) >= 5) || (user && user._id === props.data.staffUserId) ?
                            <label for="imageProfilepc" >

                                <input
                                    id="imageProfilepc"
                                    type="file"
                                    name="imageProfilepc"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => profilePicUpload(e)}
                                />
                                {profilePic.length === 0 ? (
                                    <img

                                        id="profile_pfp_img_staff"
                                        width="100"
                                        height="100"
                                        src={
                                            profileImage && profileImage.length > 10
                                                ? "https://drive.google.com/uc?export=view&id=" +
                                                profileImage.split('/')[5]
                                                :
                                                "default_image.png"
                                        }
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                ) : (
                                    <img
                                        id="profile_pfp_img_staff"
                                        width="100"
                                        height="100"
                                        src={profilePic ? profilePic : "default_image.png"}
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                )}
                            </label>
                            :  <img

                            id="profile_pfp_img_staff"
                            width="100"
                            height="100"
                            src={
                                profileImage && profileImage.length > 10
                                    ? "https://drive.google.com/uc?export=view&id=" +
                                    profileImage.split('/')[5]
                                    :
                                    "default_image.png"
                            }
                            style={{ borderRadius: "75px", border: "1px solid black" }}
                        />}

                    </div>
                    <div id="staff_name_grp_prof">
                        <div id="staff_name__lbl_pfp">
                            <span>Staff Name</span>
                        </div>
                        {/* <input placeholder="Staff Name" class="staff_name__input_pfp" value={staffName} onChange={(e) => { setStaffName(e.target.value) }} /> */}
                        <select tabIndex={1} class="staff_name__input_pfp" value={staffUserId} onChange={(e) => { setStaffUserId(e.target.value); }}>
                            {props.display === "Edit" ? <option value={props.data.staffUserId}>{props.data.staffName}</option> : userData.filter((staff) => staff.userType === "staff").map((rec) => (
                                <option value={rec._id}>{rec.name}</option>
                            ))}

                        </select>
                    </div>
                    <div id="staff_designation_grp_prof">
                        <div id="staff_designation_lbl_pfp">
                            <span>Staff Designation</span>
                        </div>
                        <input tabIndex={2} placeholder="Staff Designation" class="staff_designationinput_pfp" value={staffDesignation} onChange={(e) => { setStaffDesignation(e.target.value) }} />

                    </div>
                    <div id="staff_mob_grp">
                        <div id="staff_mob_lbl_pup_op_staff">
                            <span>Staff Mobile</span>
                        </div>
                        <input tabIndex={5} placeholder="Staff Mobile" type='number' class="staff_mob_pup_op_staff" value={staffMobile} onChange={(e) => { setStaffMobile(e.target.value) }} />

                    </div>
                    <div id="staff_skill_grp">
                        <div id="staff_skill_lbl_pfp">
                            <span>Staff Skills</span>
                        </div>
                        <textarea tabIndex={6} placeholder="Staff Skills" class="staff_skill_input_pfp" value={staffSkills} onChange={(e) => { setStaffSkills(e.target.value) }} />

                    </div>
                    <div id="staff_exp_grp">
                        <div id="staff_exp_lbl_pfp">
                            <span>Staff Experience</span>
                        </div>
                        <input tabIndex={4} placeholder="Month" type='number' class="staff_exp_input_pfp_month" value={staffExpirienceMonths} onChange={(e) => { setStaffExpirienceMonths(e.target.value) }} />
                        <input tabIndex={3} placeholder="Years" type='number' class="staff_exp_input_pfp_year" value={staffExpirienceYears} onChange={(e) => { setStaffExpirienceYears(e.target.value) }} />

                    </div>
                    <div id="remark_grp">
                        <div id="remark_pup_op_staff_lbl">
                            <span>Remark</span>
                        </div>
                        <textarea tabIndex={7} placeholder="Remark" class="remark_pup_op_staff_input" value={staffRemarks} onChange={(e) => { setStaffRemarks(e.target.value) }} />


                    </div>
                    <div id="active_grp_staff">
                        <div id="active_lbl_staff">
                            <span>Active</span>
                        </div>
                        <Switch {...label} tabIndex={11} size="small" className='switch_staff' checked={staffActive} onChange={(e) => { setStaffActive(e.target.checked) }} />

                        {/* <svg class="active_chkbx">
                        <rect id="active_chkbx" rx="0" ry="0" x="0" y="0" width="38" height="20">
                        </rect>
                    </svg> */}
                    </div>
                    {/* {console.log(user)} */}
                    {(user && user.usertype === "staff" && parseInt(user.useraccess) >= 5) || (user && user._id === props.data.staffUserId) ?
                        <button id="Add_pup_op_staff_btn_grp" onClick={() => { props.display === "Edit" ? updateStaff() : addStaff() }}>
                            <svg class="addd_bdr_btn_staff">
                                <rect id="addd_bdr_btn_staff" rx="8" ry="8" x="0" y="0" width="123" height="41">
                                </rect>
                            </svg>
                            <div id="Add_pup_op_staff_lbl">
                                <span tabIndex={8} >{props.display}</span>
                            </div>
                        </button>
                        : ""}
                    <img id="close_pup_op_staff" tabIndex={9} src="close_pup_op_staff.png" srcset="close_pup_op_staff.png 1x" onClick={() => { props.closePopup2(); clearData() }} />

                </div>
            </Modal>
        </div>
    )
}

export default PopupStaff