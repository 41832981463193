import React from 'react'
import "../../Styles/Mob/timesheetCardMob.css"

const TimeSheetCardMob = (props) => {
  return (
    <div id="date_card_1_mob_1">
                        <svg class="n_bg_task_page_mob_1">
                            <rect id="n_bg_task_page_mob_1" rx="10" ry="10" x="0" y="0" width="174" height="182">
                            </rect>
                        </svg>
                        <img id="n_card_1_task_page_mob_1" src="n_card_1_task_page_mob_1.png" srcset="n_card_1_task_page_mob_1.png 1x" />

                        <img title="Compant Name" id="img_task_page_mob_1" src="img_task_page_mob_1.png" srcset="img_task_page_mob_1.png 1x" />

                        <div id="bl_task_page_mob_1">
                            <span>{props.data.project}</span>
                        </div>
                        <div id="n_task_page_mob_1">
                            <span>{props.data.task}</span>
                        </div>
                        <img title="Compant Name" id="n_img_task_mob_1" src="n_img_task_mob_1.png" srcset="n_img_task_mob_1.png 1x" />

                        <div id="n_page_mob_1">
                            <span>{props.data.billedHours} </span>
                    <span style={{ fontSize: "10px" }}>({props.data.category})</span>
            
                        </div>
                        <svg class="is_bdr_task_page_mob_"style={{ fill: props.data.status === "Work in Progress" ? "#3399FF" : props.data.status === "On Hold" ? "#FF9900" : props.data.status === "Completed" ? "#00CC66" : props.data.status === "Cancelled" ? "#FF0000" : "" }}>
                            <rect id="is_bdr_task_page_mob_" rx="10.5" ry="10.5" x="0" y="0" width="132" height="21" >
                            </rect>
                        </svg>
                        <div id="lbl_task_page_mob_1">
                            <span>{props.data.status}</span>
                        </div>
                        <img title="Compant Name" id="to_pfp_task_page_mob_1" src="to_pfp_task_page_mob_1.png" srcset="to_pfp_task_page_mob_1.png 1x" />

                        <div id="to_lbl_task_page__mob_1">
                            <span>{props.data.billedToName}</span>
                        </div>
                    </div>
  )
}

export default TimeSheetCardMob