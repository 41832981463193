import React, { useEffect, useState, useContext } from 'react'
import "../../Styles/Mob/userMob.css"
import { UserContext } from '../UserContext';
import UserPopupMob from './UserPopupMob';
import PopupUser from '../PC/PopupUser';


const UserMob = (props) => {
	// const [addUserPopupMob, setAddUserPopup] = useState(null);

	// const [popUp, setPopUp] = useState(false);
	// const [popUpData, setPopUpData] = useState();

	const [user, setUser] = useState(UserContext)
	const [userData, setUserData] = useState([])

	const[displayUser,setDisplayUser]= useState("none")
	 const [popUpData, setPopUpData] = useState({});
 
	// const { user, setUser } = useContext(UserContext);


	useEffect(() => {
		if (user) {
			GetUser()
		}
	}, [user,displayUser])

	useEffect(() => {
		setDisplayUser(props.isAddPopupVisibleMob === true ? "Add" : displayUser === true ? "Edit" : "none")
	}, [props.isAddPopupVisibleMob])


	const GetUser = () => {
		let apiURL = process.env.REACT_APP_API_URL + "/users/getUser";
		let apiParams = {
			method: "GET",

		};

		fetch(apiURL, apiParams)
			.then((res) => (res.status === 200 ? res.json() : res.text()))
			.then((repos) => {
				if (repos.data) {

					setUserData(repos.data)

				} else alert(repos.message || repos);
			})
			.catch((err) => alert(err));

	}

	const showAccess = (rec) => {
		switch (parseInt(rec)) {
			case 1:
				return "Executive"
				break;
			case 3:
				return "Manager"
				break;
			case 5:
				return "Director"
				break;
			case 10:
				return "Super Admin"
				break;
		}
	}

	return (

		<div id="User_page_mob">
			<img id="over_all_bg_design_userpage_mo" src="over_all_bg_design_userpage_mo.png" srcset="over_all_bg_design_userpage_mo.png 1x" />

			<div id="userpage_mob_scroll">
			
				{userData.filter(rw=>(rw.name.toLowerCase().includes(props.searchText && props.searchText.toLowerCase()) ||rw.email.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.mobile.toString().includes(props.searchText.toLowerCase()) ||rw.userAccess.toLowerCase().includes(props.searchText.toLowerCase())  ||rw.userType.toLowerCase().includes(props.searchText.toLowerCase())  )).map((rec) => (
					<div style={{ position: "relative", display: 'inline-flex' }} >

						<div id="card_1_user_page_mob" onClick={() => {setDisplayUser("Edit"); setPopUpData(rec)  }}>
							<svg class="card_over_all_bg_userpage_mob">
								<rect id="card_over_all_bg_userpage_mob" rx="10" ry="10" x="0" y="0" width="93vw" height="105">
								</rect>
							</svg>
							<img id="card_bg_design_userpage_mob" src="card_bg_design_userpage_mob.png" srcset="card_bg_design_userpage_mob.png 1x" />

							<svg class="pfp_behind_circle_userpage_mob" style={{ fill: rec.active === true ? "rgba(119,238,170,1)" : "White" }}>
								<ellipse id="pfp_behind_circle_userpage_mob" rx="43" ry="43" cx="43" cy="43">
								</ellipse>
							</svg>
							<img id="profile_pic_card_userpage_mob" src={
									rec.profilePicUrl && rec.profilePicUrl.length
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								}
									style={{ borderRadius: "75px", border: "1px solid black" }} />

							<img title="Email" id="email_lbl_userpage_mob" src="email_lbl_userpage_mob.png" srcset="email_lbl_userpage_mob.png 1x" />

							<div id="affanghanigmailcom_lbl_userpag">
								<marquee behavior="scroll" direction="">
									<span>{rec.email}</span>
								</marquee>
							</div>
							<img title="Mobile Number" id="iphone-5_lbl_userpage_mob" src="iphone-5_lbl_userpage_mob.png" srcset="iphone-5_lbl_userpage_mob.png 1x" />

							<div id="n_234567890_mobile_number_user">
							<span>{rec.mobile}</span>

							</div>
							<img title="Access" id="client_lbl_userpage_mob" src="client_lbl_userpage_mob.png" srcset="client_lbl_userpage_mob.png 1x" />

							<div id="client_lbl_userpage_mob_c">
							<span>{rec.userType}</span>
							</div>
							<img title="User Role" id="manager_img_userpage_mob" src="manager_img_userpage_mob.png" srcset="manager_img_userpage_mob.png 1x" />

							<div id="manager_lbl_userpage_mob">
							<span>{showAccess(rec.userAccess)}</span>
							</div>
							{/* <div id="Active_lbl_userpage_mob">
								<span>Active</span>
							</div> */}
							<div id="Mohammed_Affan_lbl_userpage_mo">
							<span>{rec.name}</span>
							</div>
							<svg class="Rectangle_7">
								<rect id="Rectangle_7" rx="6" ry="6" x="0" y="0" width="29" height="12">
								</rect>
							</svg>
						</div>
					</div>
				))}
			</div>
			{/* <UserPopupMob isOpen={props.isAddPopupVisibleMob && addUserPopupMob === null}  closePopup={() => { props.closeAddPopupMob(false) }} />
            <UserPopupMob isOpen={popUp} data={popUpData} closePopup={(value) => {setPopUp(value) }}/>  */}

<PopupUser display={displayUser} data={popUpData} closePopup={() => {props.closeAddPopupMob();setDisplayUser("none")}} />
		</div>
	)
}

export default UserMob