import React, { useState, useMemo, useEffect, useContext } from 'react'
import Navbar from './Component/PC/Navbar'
import User from './Component/PC/User'
import Login from "./Component/PC/Login"
import Forget from "./Component/PC/Forget"
import StaffPage from "./Component/PC/StaffPage"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { UserContext } from "../src/Component/UserContext"
import ProjectPage from './Component/PC/ProjectPage'
import Client from './Component/PC/Client'
import ClientDashBoard from './Component/PC/ClientDashBoard'
import Footer from './Component/PC/Footer'
import ToggleNavbar from './Component/PC/ToggleNavbar'
import SearchBar from './Component/PC/SearchBar'
import TaskPage from './Component/PC/TaskPage'
import TimeSheet from './Component/PC/TimeSheet'
import LogoName from './Component/PC/LogoName'
import UserMob from './Component/Mob/UserMob'
import TopBarMob from './Component/Mob/TopBarMob'
import MenuMob from './Component/Mob/MenuMob'
import MobileMain from './Component/Mob/MobileMain'
import StaffMob from './Component/Mob/StaffMob'
import ProjectMob from './Component/Mob/ProjectMob'
import ClientMob from './Component/Mob/ClientMob'
import CardGrid from './Component/PC/CardGrid'
import ClientDashBoardMob from './Component/Mob/ClientDashBoardMob'
import ClientStaff from './Component/PC/ClientStaff'
import LoginMob from './Component/Mob/LoginMob'
import ClientStaffMob from './Component/Mob/ClientStaffMob'
import TimeSheetMob from './Component/Mob/TimeSheetMob'



const App = () => {


  const [user, setUser] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false)

  // const { user, setUser } = useContext(UserContext);


  const [isAddVisible, setIsAddVisible] = useState(true)
  const [isTaskPage, setIsTaskPage] = useState(false)

  const [showAddPopupMob, setShowAddPopupMob] = useState(false)
  const [searchText, setSearchText] = useState("")


  const value = useMemo(() => ({
    user,
    setUser,
  }),
    [
      user,
      setUser,
    ]
  );

  useEffect(() => {
    let storedUser = localStorage.getItem("loggedInUser");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  useEffect(() => {
    console.log(isTaskPage)
  }, [isTaskPage, user]);

  return (
    <div>
      <UserContext.Provider value={value}>
        <Router>


          {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) ?

            <>
              {user ? <MobileMain openAddPopupMob={() => setShowAddPopupMob(true)} searchText={searchText} setSearchText={(value) => setSearchText(value)} isAddVisible={((user && parseInt(user.useraccess) >= 5 && user.usertype === "staff")) || ((user && parseInt(user.useraccess) >= 3 && user.usertype === "staff") && isTaskPage !== true) || ((user && parseInt(user.useraccess) < 3 && user.usertype === "staff") && isTaskPage === true)} /> : ""}

              <Routes>
                <Route exact path="/" element={user ? <ClientDashBoardMob searchText={searchText} /> : <LoginMob />} />
                {(user && ((parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (parseInt(user.useraccess) === 2 && user.usertype === "staff"))) ? <Route exact path="/usermob" element={<UserMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} closeAddPopupMob={() => setShowAddPopupMob(false)} />} /> : ""}
                <Route exact path="/staffmob" element={<StaffMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} closeAddPopupMob={() => setShowAddPopupMob(false)} />} />
                <Route exact path="/projectmob" element={<ProjectMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} setTaskPage={(value) => setIsTaskPage(value)} closeAddPopupMob={() => setShowAddPopupMob(false)} />} />
                {(user && parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (user && user.usertype === "client") || (user && parseInt(user.useraccess) === 2 && user.usertype === "staff") ? <Route exact path="/companiesmob" element={<ClientMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} closeAddPopupMob={() => setShowAddPopupMob(false)} />} /> : ""}
                <Route exact path="/dashboardmob" element={<ClientDashBoardMob searchText={searchText} />} />
                {(user && parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (user && user.usertype === "client") || (user && parseInt(user.useraccess) === 2 && user.usertype === "staff") ? <Route exact path="/clientsmob" element={<ClientStaffMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} closeAddPopupMob={() => setShowAddPopupMob(false)} />} /> : ""}
                <Route exact path="/timesheetmob" element={<TimeSheetMob searchText={searchText} isAddPopupVisibleMob={showAddPopupMob} closeAddPopupMob={() => setShowAddPopupMob(false)} />} />
              </Routes>
              </>
            :
            <> 
              {user ? <ToggleNavbar openAddPopup={() => setShowAddPopup(true)} searchText={searchText} setSearchText={(value) => setSearchText(value)} isAddVisible={((user && parseInt(user.useraccess) >= 5 && user.usertype === "staff")) || ((user && parseInt(user.useraccess) >= 3 && user.usertype === "staff") && isTaskPage !== true) || ((user && parseInt(user.useraccess) < 3 && user.usertype === "staff") && isTaskPage === true)} /> : ""}
              <Routes>
                <Route exact path="/" element={user ? <ClientDashBoard searchText={searchText}  /> : <Login />} />
                {/* <Route exact path="/forgotpassword" element={<Forget />} /> */}
                {/* <Route exact path="/navbar" element={<Navbar/>}/> */}
                {(user && ((parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (parseInt(user.useraccess) === 2 && user.usertype === "staff"))) ? <Route exact path="/users" element={<User searchText={searchText} isAddPopupVisible={showAddPopup} closeAddPopup={() => setShowAddPopup(false)} />} /> : ""}
                <Route exact path="/staff" element={<StaffPage searchText={searchText} isAddPopupVisible={showAddPopup} closeAddPopup={() => setShowAddPopup(false)} />} />
                {(user && parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (user && user.usertype === "client") || (user && parseInt(user.useraccess) === 2 && user.usertype === "staff") ? <Route exact path="/clients" element={<ClientStaff searchText={searchText} isAddPopupVisible={showAddPopup} closeAddPopup={() => setShowAddPopup(false)} />} /> : ""}
                <Route exact path="/projects" element={<ProjectPage searchText={searchText} isAddPopupVisible={showAddPopup} setTaskPage={(value) => setIsTaskPage(value)} closeAddPopup={() => setShowAddPopup(false)} />} />
                {(user && parseInt(user.useraccess) >= 5 && user.usertype === "staff") || (user && user.usertype === "client") || (user && parseInt(user.useraccess) === 2 && user.usertype === "staff") ? <Route exact path="/companies" element={<Client searchText={searchText} isAddPopupVisible={showAddPopup} closeAddPopup={() => setShowAddPopup(false)} />} /> : ""}
                <Route exact path="/timesheet" element={<TimeSheet searchText={searchText} />} />
                {/* <Route exact path="/cardgrid" element={<CardGrid/>}/> */}
                <Route exact path="/dashboard" element={<ClientDashBoard searchText={searchText} isAddPopupVisible={showAddPopup} closeAddPopup={() => setShowAddPopup(false)} />} />

              </Routes></>
          }

          {/* <Route exact path="/" element={ (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) ? <MenuMob/> :<ToggleNavbar/>}/> */}


        </Router>
      </UserContext.Provider>

    </div>
  )
}

export default App
