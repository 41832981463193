import React,{useState,useEffect,useContext} from 'react'
import "../../Styles/PC/clientStaff.css"
import ClientStaffPopup from './ClientStaffPopup'
import { UserContext } from '../UserContext';


const ClientStaff = (props) => {

    const [displayStaff, setDisplayStaff] = useState("none")
	const [showStaffData, setStaffShowData] = useState({});
   const[clientStaffData,setClientStaffData] =useState([]);
	const { user, setUser } = useContext(UserContext);


    useEffect(() => {
        setDisplayStaff(props.isAddPopupVisible === true ? "Add" : displayStaff === true ? "Edit" : "none")
	}, [props.isAddPopupVisible])

    useEffect(()=>{
if(user){
        GetClientStaff()
	}
    },[displayStaff,props.closeAddPopup,showStaffData ,user])

    const GetClientStaff = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/clientstaff" + ((user && user.usertype === "staff" && user.useraccess >=5) || (user && user.usertype === "staff" && parseInt(user.useraccess) === 2) ? "/getClientStaff" : (user && user.usertype === "client") ? "/getClientStaffByUser/"+ user._id : "");   
		     let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setClientStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

  return (
    <div id="client_page__1">
	<img id="over_all_bg_page_client_pc_new" src="over_all_bg_page_client_pc_new.png" srcset="over_all_bg_page_client_pc_new.png 1x"/>
		
	<div id="client_pc_new_page_scroll">
        {clientStaffData.filter(rw=> (rw.clientStaffName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.mobile.toString().includes(props.searchText.toLowerCase()) || rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec)=>(
            <div style={{position:"relative", display:"inline-flex"}}>
		<div id="user_card_client_pc_new" onClick={()=>{setDisplayStaff("Edit");setStaffShowData(rec)}}>
			<svg class="card_over_all_bgclient_pc_new">
				<rect id="card_over_all_bgclient_pc_new" rx="10" ry="10" x="0" y="0" width="174" height="200" >
				</rect>
			</svg>
			<img id="card_bg_design_client_pc_new" src="card_bg_design_client_pc_new.png" srcset="card_bg_design_client_pc_new.png 1x"/>
				
			<div id="pfp_grpclient_pc_new">
				<svg class="pfp_behind_circle_client_pc_ne">
					<ellipse id="pfp_behind_circle_client_pc_ne" rx="22.5" ry="22.5" cx="22.5" cy="22.5" style={{fill:rec.active ? "rgba(119,238,170,1)" : "white"}}>
					</ellipse>
				</svg>
				<img id="profile_pic_cardclient_pc_new" src={
									rec.profilePicUrl && rec.profilePicUrl.length > 10
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								} />
					
			</div>
			<img title="Staff Experience" id="exp_client_pc_new" src="exp_client_pc_new.png" srcset="exp_client_pc_new.png 1x"/>
				
			<div id="yr_client_pc_new">
				<span>{rec.clientDesignation}</span>
			</div>
			<img title="Mobile Number" id="iphone-client_pc_new" src="iphone-client_pc_new.png" srcset="iphone-client_pc_new.png 1x"/>
				
			<div id="client_pc_new_mobile_number">
				<span>{rec.mobile}</span>
			</div>
			<img title="Staff Designation" id="manager_lbl_client_pc_new" src="manager_lbl_client_pc_new.png" srcset="manager_lbl_client_pc_new.png 1x"/>
				
			<div id="Admin_lbl_client_pc_new">
				<span>{rec.companyName}</span>
			</div>
			<div id="Mohammed_Affan_lblclient_pc_ne">
				<span>{rec.clientStaffName}</span>
			</div>
		</div>
        </div>
        ))}
	</div>
    <ClientStaffPopup display={displayStaff} data={showStaffData} closePopup={() => {props.closeAddPopup();setDisplayStaff("none")}}/>
</div>

  )
}

export default ClientStaff