import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/Mob/taskPageMob.css"
import Cat_Mod_Team from './Cat_Mod_Team'
import { UserContext } from '../UserContext'
import TaskCardMob from './TaskCardMob'
import ProjectPopup from '../PC/ProjectPopup'
import PopUpTask from '../PC/PopUpTask'


const TaskPageMob = (props) => {

    const { user } = useContext(UserContext);

    const [display, setDisplay] = useState("none")


    const [catModTeam, setCatModTeam] = useState(false)
    const [teamData, setTeamData] = useState([])
    const [staffData, setStaffData] = useState([])

    const [showUnAssigned, setShowUnAssigned] = useState(true)
    const [showAssigned, setShowAssigned] = useState(true)
    const [showWip, setShowWip] = useState(true)
    const [showHold, setShowHold] = useState(true)
    const [showCompleted, setShowCompleted] = useState(true)
    const [showCancelled, setShowCancelled] = useState(true)


    const [displayTask, setDisplayTask] = useState("none")
    const [showData, setShowData] = useState({});
    const [taskData, setTaskData] = useState([])

    const [editPopup, setEditPopUp] = useState(false)
	const [editData, setEditData] = useState({})


    useEffect(() => {
        // console.log(props.project._id)
        if (props.project._id)

            GetTask()
    }, [props.project, displayTask])

    useEffect(() => {
        if (props.project._id, user) {

            GetTeam()
        }
    }, [props.project, display, user])

    useEffect(() => {

        GetStaff()

    }, [props.project._id])

    
	useEffect(() => {
		setDisplayTask(props.isAddPopupVisibleMob === true ? "Add" : displayTask === true ? "Edit" : "none")
	}, [props.isAddPopupVisibleMob])



    const GetTeam = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/team/GetTeam/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    // alert(	((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
                    if(((user && user.usertype === "staff" && user.useraccess >= 5) || (user && user.usertype === "staff" && user.useraccess >= 3 && repos.data.filter(rw=> rw.staffUserId === user._id).length > 0)))
                     {
                    	props.showAddButton(false)
                    }
                    else{
                    	props.showAddButton(true)
                    }

                    setTeamData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetStaff = () => {

        // console.log(props.project._id)

        let apiURL = process.env.REACT_APP_API_URL + "/users/getStaffUsers/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetTask = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/task/getTasks/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                console.log(repos.data)

                if (repos.data) {

                    setTaskData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    return (
        <div>
            <div id="task_page_mob">
                <img id="bg_design_task_page_mob_mob_1" src="bg_design_task_page_mob_mob_1.png" srcset="bg_design_task_page_mob_mob_1.png 1x" />


                {(user && user.usertype === "staff" && user.useraccess >= 5) ||(user && user.usertype === "staff" && user.useraccess >= 3 && teamData.filter(rw=> rw.staffUserId === user._id).length>0) || (user && user.usertype === "client")? 
					<button onClick={() => { setEditPopUp(true); setEditData(props.project) }}>
				        <img id="edit_btn_task_mob" src="edit_btn_task_mob.png" srcset="edit_btn_task_mob.png 1x" />
					</button>
					: ""}
				<ProjectPopup isOpen={editPopup} data={editData} closePopup3={(value) => setEditPopUp(value)} selectProject={(value)=>{}}/>

                <img id="back_btn_task_mob" src="back_btn.png" srcset="back_btn.png 1x" onClick={() => props.goBack()} />

                <div id="projet_name_task_page_mob">
                    <span>{props.project.projectName}</span>
                </div>
                <div id="task_page_mob_side_menu_btn" onClick={() => setCatModTeam(true)}>
                    <svg class="side_menu_mid" viewBox="4.5 18 27 3">
                        <path id="side_menu_mid" d="M 4.5 18 L 31.5 18">
                        </path>
                    </svg>
                    <svg class="side_menu_top" viewBox="4.5 9 27 3">
                        <path id="side_menu_top" d="M 4.5 9 L 31.5 9">
                        </path>
                    </svg>
                    <svg class="side_menu_down" viewBox="4.5 27 27 3">
                        <path id="side_menu_down" d="M 4.5 27 L 31.5 27">
                        </path>
                    </svg>
                </div>
                <div id="Scroll_task_page_card">
                    <div id="card_1_area_unassigned_mob">
                        <div id="top_status_area_mob_1">
                            <div id="unassigned_lbl_mob_1">
                                <span>Unassigned</span>
                            </div>
                            <img id="right-arrow_mob_1drop_btn_" src={showUnAssigned ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowUnAssigned(!showUnAssigned)} />

                            <svg class="long_line_unassigned_mob_1" viewBox="0 0 220.611 1">
                                <path id="long_line_unassigned_mob_1" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="left_arrow_line__mob_1" viewBox="0 0 14 1">
                                <path id="left_arrow_line__mob_1" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_unassigned_mob">
                            
                            {showUnAssigned ? taskData.filter(row => row.status === "Unassigned"  && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec) => (
                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                        {/* {console.log(taskData)} */}
                    </div>
                    <div id="card_1_areaassigned_mob">
                        <div id="top_status_assigned_mob">
                            <div id="unassigned_lbl_assigned_mob">
                                <span>Assigned</span>
                            </div>
                            <img id="right-arrow_mob_assigned_mob" src={showAssigned ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowAssigned(!showAssigned)} />

                            <svg class="long_line_assigned_mob" viewBox="0 0 220.611 1">
                                <path id="long_line_assigned_mob" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="left_arrow_line__assigned_mob" viewBox="0 0 14 1">
                                <path id="left_arrow_line__assigned_mob" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_assigned_mob">
                            {showAssigned ? taskData.filter(row => row.status === "Assigned" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (

                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                    </div>
                    <div id="card_1_area_WIP_mob">
                        <div id="top_status_area_WIP_mob">
                            <div id="unassigned_lbl_mob_1_eq">
                                <span>Work in Progress</span>
                            </div>
                            <img id="right-arrow_WIP_mob" src={showWip ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowWip(!showWip)} />

                            <svg class="long_lineWIP_mob" viewBox="0 0 193.111 1">
                                <path id="long_lineWIP_mob" d="M 0 0 L 193.11083984375 0">
                                </path>
                            </svg>
                            <svg class="left_arrow_lineWIP_mob" viewBox="0 0 14 1">
                                <path id="left_arrow_lineWIP_mob" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_WIP_mob">
                            {showWip ? taskData.filter(row => row.status === "Work in Progress" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                    </div>
                    <div id="card_1_area_OnHold_mob">
                        <div id="top_status_OnHold_mob">
                            <div id="OnHold_mob_lbl_mob_1">
                                <span>On Hold</span>
                            </div>
                            <img id="right-arrow_OnHold_mob_" src={showHold ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowHold(!showHold)} />

                            <svg class="long_line_unassigned__OnHold_m" viewBox="0 0 220.611 1">
                                <path id="long_line_unassigned__OnHold_m" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="left_arrow_line_OnHold_mob" viewBox="0 0 14 1">
                                <path id="left_arrow_line_OnHold_mob" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_OnHold_mob">
                            {showHold ? taskData.filter(row => row.status === "On Hold" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                    </div>
                    <div id="card_1_area_completed_mob">
                        <div id="top_status_area_mob_1_fv">
                            <div id="completed_mob_lbl_mob_1">
                                <span>Completed</span>
                            </div>
                            {/* <img id="right-arrow_mob_completed_mob_" src="right-arrow_mob_completed_mob_.png" srcset="right-arrow_mob_completed_mob_.png 1x" /> */}
                            <img id="right-arrow_mob_completed_mob_" src={showCompleted ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowCompleted(!showCompleted)} />

                            <svg class="long_line_completed_mob_mob_1" viewBox="0 0 220.611 1">
                                <path id="long_line_completed_mob_mob_1" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="left_arrow_line_completed_mob" viewBox="0 0 14 1">
                                <path id="left_arrow_line_completed_mob" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_completed_mob">
                            {showCompleted ? taskData.filter(row => row.status === "Completed" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (

                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                    </div>
                    <div id="card_1_area_cancelled_mob">
                        <div id="top_status_cancelled_mob">
                            <div id="cancelled_mob_lbl_mob_1">
                                <span>Cancelled</span>
                            </div>
                            <img id="cancelled_mob_1drop_btn_" src={showCancelled ? "right-arrow_2_drop_btn.png" : "right-arrow_2_dassigned_.png"} onClick={() => setShowCancelled(!showCancelled)} />

                            <svg class="long_line_cancelled_mobmob_1" viewBox="0 0 220.611 1">
                                <path id="long_line_cancelled_mobmob_1" d="M 0 0 L 220.61083984375 0">
                                </path>
                            </svg>
                            <svg class="cancelled_mob_mob_1" viewBox="0 0 14 1">
                                <path id="cancelled_mob_mob_1" d="M 14 0 L 0 0">
                                </path>
                            </svg>
                        </div>
                        <div id="Scroll_card_1_cancelled_mob">
                            {showCancelled ? taskData.filter(row => row.status === "Cancelled" && (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase())  || row.category.toLowerCase().includes(props.searchText.toLowerCase())  || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                                <div id="caard_1_status_drop_tax_page_mob">
                                    <TaskCardMob task={rec} project={props.project} teamData={teamData} setDisplayTask={() => setDisplayTask("Edit")} setShowData={() => setShowData(rec)} /></div>)) : <div style={{ height: "30px" }}></div>}
                        </div>
                    </div>
                </div>
            </div>
            {catModTeam === true ? <Cat_Mod_Team hide={catModTeam} closeSide={(value) => setCatModTeam(value)} project={props.project} staffData={staffData} /> : ""}
            <PopUpTask display={displayTask} data={showData} teamData={teamData} closePopup={() => { props.closeAddPopup(); setDisplayTask("none") }} project={props.project} />

        </div>
    )
}

export default TaskPageMob


                    // < div id="task_page_mob" >
                    //     <img id="bg_design_task_page_mob_mob_1" src="bg_design_task_page_mob_mob_1.png" srcset="bg_design_task_page_mob_mob_1.png 1x" />

                    //     <div id="card_1_area_unassigned_mob">
                    //         <div id="top_status_area_mob_1">
                    //             <div id="unassigned_lbl_mob_1">
                    //                 <span>Unassigned</span>
                    //             </div>
                    //             <img id="right-arrow_mob_1drop_btn_" src="right-arrow_mob_1drop_btn_.png" srcset="right-arrow_mob_1drop_btn_.png 1x" />

                    //             <svg class="long_line_unassigned_mob_1" viewBox="0 0 220.611 1">
                    //                 <path id="long_line_unassigned_mob_1" d="M 0 0 L 220.61083984375 0">
                    //                 </path>
                    //             </svg>
                    //             <svg class="left_arrow_line__mob_1" viewBox="0 0 14 1">
                    //                 <path id="left_arrow_line__mob_1" d="M 14 0 L 0 0">
                    //                 </path>
                    //             </svg>
                    //         </div>
                    //         <div id="Scroll_card_1_unassigned_mob">

                    //         </div>
                    //     </div>

                    //     <img id="edit_btn_task_mob" src="edit_btn_task_mob.png" srcset="edit_btn_task_mob.png 1x" />

                    //     <img id="back_btn_task_mob" src="back_btn.png" srcset="back_btn.png 1x" onClick={() => props.goBack()} />

                    //     <div id="projet_name_task_page_mob">
                    //         <span>{props.project.projectName}</span>
                    //     </div>

                    //     {/* {currentform1 === "taskpage" ? <TaskPageMob/> : <Cat_Mod_Team onFormSwitch={toggleform2} />} */}

                    //     <button id="task_page_mob_side_menu_btn" onClick={() => setCatModTeam(true)}>
                    //         <svg class="side_menu_mid" viewBox="4.5 18 27 3">
                    //             <path id="side_menu_mid" d="M 4.5 18 L 31.5 18">
                    //             </path>
                    //         </svg>
                    //         <svg class="side_menu_top" viewBox="4.5 9 27 3">
                    //             <path id="side_menu_top" d="M 4.5 9 L 31.5 9">
                    //             </path>
                    //         </svg>
                    //         <svg class="side_menu_down" viewBox="4.5 27 27 3">
                    //             <path id="side_menu_down" d="M 4.5 27 L 31.5 27">
                    //             </path>
                    //         </svg>