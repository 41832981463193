import React, { useState, useContext, useEffect } from 'react'
import "../../Styles/Mob/topUsers.css"
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext'
import ResetPass from '../PC/ResetPass'




const TopUsers = (props) => {

  const [showUsers, setShowUsers] = useState(false)
  const[resetPass,setResetPass]= useState(false)

  const { user, setUser } = useContext(UserContext);

  // const handlelogout = () => {
  //   localStorage.clear();
  //   setUser(null);
  //   window.location.href = "/";
  // }

  useEffect(() => {

  }, [showUsers])



  return (

    <div id="examples" onDoubleClick={()=>  setShowUsers(false)}>
      {/* {console.log(props)} */}
      {/* <img id="exmple_1_mob" src="exmple_1_mob.png" srcset="exmple_1_mob.png 1x" />

      <img id="example_2_mob" src="example_2_mob.png" srcset="example_2_mob.png 1x" /> */}

      {/* <img id="example_3_mob" src="example_3_mob.png" srcset="example_3_mob.png 1x" /> */}

      {/* {console.log(showUsers)} */}
      {/* { showUsers === true && <TopUsers isOpen = {showUsers} closeUsers = {(value)=> setShowUsers(value)}/>} */}
     
      <img id="profile_pic_card_top_bar_mob" src={
        user.imageId && user.imageId.length > 10
          ? "https://drive.google.com/uc?export=view&id=" + user.imageId
          :
          "default_image.png"
      }
        style={{ borderRadius: "75px", border: "1px solid black" }}

        onClick={() => setShowUsers(!showUsers)} />

      {showUsers === true ?

        <div id="top_nav_users_mob" onClick={() => setShowUsers(false)}>
							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 

          <Link to={"/usermob"}>
            <img id="exmple_1_mob" src="user_page_btn_img.png" srcset="user_page_btn_img.png 1x" title="Users" />
          </Link>
           :""}
							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 

          <Link to={"/companiesmob"}>
            <img id="example_2_mob" src="client_page_img_btn.png" srcset="client_page_img_btn.png 1x" title="Companies" />
          </Link>
          :""}
							{(user && user.useraccess  >= 5 && user.usertype === "staff") || (user &&user.usertype === "client") || (user && parseInt(user.useraccess)  === 2 && user.usertype === "staff" ) ? 

          <Link to={"/clientsmob"}>
            <img id="example_3_mob" src="clientstaff.png" srcset="clientstaff.png 1x" title="Clients" />
          </Link>
:""}
          {/* <div id="logout_grp_mob" onClick={() => handlelogout()}  title='Log Out'> */}
          {/* <img id="logout_icon_I_mob" src="logout_icon_I.png" srcset="logout_icon_I.png 1x" onClick={() => handlelogout()} title='Log Out' /> */}
          <img src="resetpassword.png" id='reset_pass_mob' onClick={()=>setResetPass(true)} />

          {/* </div> */}
        </div>
:""
      }
<ResetPass isOpen={resetPass} closePopup={(value)=>setResetPass(value)}/> 

    </div>
  )
}

export default TopUsers