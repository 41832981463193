import React, { useEffect,useContext } from 'react'
import "../../Styles/PC/billedHoursCard.css"
import { UserContext } from '../UserContext'


const BilledHoursCard = (props) => {
  

	const { user } = useContext(UserContext);

    useEffect(()=>{

    },[props.billedHours,user])

    return (
        <div id="timeline_page__cards">
            {/* <img id="over_all_bg_timeline_page" src="over_all_bg_timeline_page.png" srcset="over_all_bg_timeline_page.png 1x" /> */}

            <svg class="card_over_all_bg_task_page_da">
                <linearGradient id="card_over_all_bg_task_page_da" spreadMethod="pad" x1="-0.27" x2="1.112" y1="-0.415" y2="1.279">
                    <stop offset="0" stop-color="#cee2fe" stop-opacity="1"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                </linearGradient>
                <rect id="card_over_all_bg_task_page_da" rx="10" ry="10" x="0" y="0" width="174" height="182">
                </rect>
            </svg>
            <div id="total_build_hrs_timesheet_new_">
                <span>Billed Hours</span>
            </div>
            
            <div id="Scroll_details_total_hrs_times">
                {props.billedHours.map((rec) => (
                 
                <div id="affan_grp_timesheet_new_pc" style={{backgroundColor:rec.hours === 0 && "red",color:rec.hours === 0 && "white"}}>
                    {/* {console.log(rec)}    */}
                    <div id="module_billedhours_page">
                        <span>{rec.name}</span>
                    </div>
                    <div id="module_timeline_page_df">

                        <span style={{color:(rec.hours > 0 && rec.hours<8) && "red"}}>{rec.hours}</span>
                    </div>
                </div>
                ))}
            </div>
            <svg class="Line_9" viewBox="0 0 158 1">
                <path id="Line_9" d="M 0 0 L 158 0">
                </path>
            </svg>
        </div>

    )
}

export default BilledHoursCard