import React, { useEffect, useState ,useContext} from 'react'
import "../../Styles/PC/projectPopup.css"
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import { UserContext } from '../UserContext';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };


const ProjectPopup = (props) => {

    const [projectName, setProjectName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [projectInfo, setProjectInfo] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [contactPersonDesignation, setContactPersonDesignation] = useState("")
    const [contactPersonMobile, setContactPersonMobile] = useState("")
    const [active, setActive] = useState(true)
    const [startDate, setStartDate] = useState((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0'))
    const [endDate, setEndDate] = useState((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0'))

    const [projectId, setProjectId] = useState("")
    const [clientData, setClientData] = useState([])

    const [projectPic, setProjectPic] = useState("")
    const [projectImage, setProjectImage] = useState("")

	const { user, setUser } = useContext(UserContext);


    useEffect(() => {
        // console.log(props.data)
        if (props.data) {
            setProjectId(props.data ? props.data._id : "")
            setProjectName(props.data ? props.data.projectName : "")
            setCompanyId(props.data ? props.data.companyId : "")
            setCompanyName(props.data ? props.data.companyName : "")
            setProjectInfo(props.data ? props.data.projectInfo : "")
            setContactPersonName(props.data ? props.data.contactPersonName : "")
            setContactPersonDesignation(props.data ? props.data.contactPersonDesignation : "")
            setContactPersonMobile(props.data ? props.data.contactPersonMobile : "")
            setProjectImage(props.data ? props.data.projectImageUrl : "")
            setActive(props.data ? props.data.active : false)
            setStartDate(props.data ? new Date(props.data.startDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.startDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.startDate).getDate()).toString().padStart(2, '0') : "")
            // console.log(new Date(props.data.endDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.endDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.endDate).getDate() + 1).toString().padStart(2, '0'))
            setEndDate(props.data ? new Date(props.data.endDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.data.endDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.data.endDate).getDate()).toString().padStart(2, '0') : "")
        }
    }, [props.data])

    

    useEffect(() => {
        if(user){
        GetClient()
           }
    },[user])

    const addProject = () => {
        if (clientData.filter(rw => rw._id === companyId)[0].companyName.length === 0 || clientData.filter(rw => rw._id === companyId)[0].companyName.length === "") {
            return alert("Please Enter  Company Name!!")
        }
        if (projectName.length === 0 || projectName.length === "") {
            return alert("Please Enter Project Name")
        }
        if (projectInfo.length === 0 || projectInfo.length === "") {
            return alert("Please Enter Project Information")
        }
        if (startDate === 0 || startDate === "") {
            return alert("Please Select  Start Date")
        }
        if (endDate === 0 || endDate === "") {
            return alert("Please Select  End Date")
        }
        if (contactPersonName.length === 0 || contactPersonName.length === "") {
            return alert("Please Enter contact Person  Name!!")
        }
        if (contactPersonDesignation.length === 0 || contactPersonDesignation.length === "") {
            return alert("Please Enter  Contact Person Designation!!")
        }

        if (contactPersonMobile.length === 0 || contactPersonMobile.length === "") {
            return alert("Please Enter Contact Person Mobile!!")
        }

        let apiURL = process.env.REACT_APP_API_URL + "/project/addProject";
        let apiParams = {
            method: "POST",
            headers: {
                projectname: projectName,
                companyid: companyId,
                companyname: clientData.filter(rw => rw._id === companyId)[0].companyName,
                projectinfo: projectInfo,
                contactpersonname: contactPersonName,
                contactpersondesignation: contactPersonDesignation,
                contactpersonmobile: contactPersonMobile,
                active: active,
                startdate: startDate,
                enddate: endDate,

            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
// alert(repos.data._id)
props.closePopup3(false)
clearData()
if(user&& parseInt(user.useraccess) === 3 ){
    addTeam(repos.data)
}
                    alert("Project Details Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    const updateProject = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/project/editProject";
        let apiParams = {
            method: "POST",
            headers: {
                projectid: projectId,
                projectname: projectName,
                companyid: companyId,
                companyname: clientData.filter(rw => rw._id === companyId)[0].companyName,
                projectinfo: projectInfo,
                contactpersonname: contactPersonName,
                contactpersondesignation: contactPersonDesignation,
                contactpersonmobile: contactPersonMobile,
                active: active,
                startdate: startDate,
                enddate: endDate,
            },
        };
        // alert(JSON.stringify(apiParams))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup3(false)
                    clearData()
                    alert("Project Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    
    const addTeam = (project) => {
        // console.log(props.staffData)
        let apiURL = process.env.REACT_APP_API_URL + "/team/addTeam";
        let apiParams = {
            method: "POST",
            headers: {
                projectid: project._id,
                projectname: project.projectName,
                staffuserid: user._id,
                staffname: user.name,
                profilepicurl:user.profilePicUrl,
                role: "Project Manager",
                allocation: 0,
                billingunit: "month",
                unitcost: 0,
                active: true,
                startdate: project.startDate,
                enddate: project.endDate,

            },
        };
        // alert(JSON.stringify((apiParams)))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                props.selectProject(project)
                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetClient = () => {
        if(user){
        let apiURL = process.env.REACT_APP_API_URL + "/client" + ((user && user.usertype ==="staff") ?  "/getClient" : (user && user.usertype === "client") ? "/getCompaniesByUser/" +(user && user._id):"");
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setClientData(repos.data)
                    if (repos.data[0]) {
                        setCompanyId(repos.data[0]._id)
                        setCompanyName(repos.data[0].companyName)

                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
}
    const guardarArchivo = async (e) => {
        if (e.target.files[0]) {
            var file = e.target.files[0] //the file
            var reader = new FileReader() //this for convert to Base64 
            reader.readAsDataURL(e.target.files[0]) //start conversion...
            reader.onload = function (e) { //.. once finished..
                var rawLog = reader.result.split(',')[1]; //extract only thee file data part
                var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
                fetch('https://script.google.com/macros/s/AKfycbyWhd-At-SX3l6eOqCmDpvAD-WBOOl7Sn97SJTqVJlM42beDnW3LXXK0ByG44Iuyv59AA/exec', //your AppsScript URL
                    { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                    .then(res => res.json()).then((data) => {

                        // alert("Image Uploaded ") //See response

                        SaveProjectImage(data)
                    }).catch(e => console.log(e)) // Or Error in console
            }
        }

    }
    const SaveProjectImage = (data) => {

        const apiurl = process.env.REACT_APP_API_URL + "/project/addProjectImage/" + props.data._id;
        let apiParams = {
            method: "POST",
            headers: {
                projectImageUrl: data.url,


            }
        }
        fetch(apiurl, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    alert("Image Uploaded Successfully!!");
                }
            })
            .catch(e => console.log(e))
    }

    function handleFileSelect(f) {
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                setProjectPic("data:image/jpeg;base64," + base64String);
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);
    }
    const profilePicUpload = (e) => {
        if (e.target.files[0]) {
            console.log(e.target.files[0])
            handleFileSelect(e.target.files[0]);
            guardarArchivo(e);
        }


    }
const clearData  = ()=>{ 
    
    setCompanyId("")
    setProjectId("")
    setProjectName( "")
    setCompanyId("")
    setCompanyName("")
    setProjectInfo("")
    setContactPersonName("")
    setContactPersonDesignation("")
    setContactPersonMobile("")
    setProjectImage("")
    setActive(true)
    setStartDate("")
    setEndDate("")
    setProjectPic("")

}





    return (
        <div>

            <Modal

                open={props.isOpen}
                onClose={() => props.closePopup3(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflow: "scroll", textAlign: "center" }}


            >

                <div>
                    <div id="pup_up_addproject">
                        <svg class="over_all_project_page_1">
                            <rect id="over_all_project_page_1" rx="15" ry="15" x="0" y="0" width="361" height="772">
                            </rect>
                        </svg>
                        <svg class="top_bg_project_page_1_">
                            <linearGradient id="top_bg_project_page_1_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                                <stop offset="0" stop-color="#7ff6e6" stop-opacity="1"></stop>
                                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                            </linearGradient>
                            <rect id="top_bg_project_page_1_" rx="15" ry="15" x="0" y="0" width="361" height="94">
                            </rect>
                        </svg>
                        <div id="active_pfp_add_project_pfp">
                            <svg class="pfp_behind_circle_bg3_project" >
                                <ellipse id="pfp_behind_circle_bg3_project" rx="50" ry="50" cx="50" cy="50" style={{fill:active === true ? "rgba(119,238,170,1)":"White"}}>
                                </ellipse>
                            </svg>
                            {/* <img id="profile_pfp_img3_project" src="profile_pfp_img.png" srcset="profile_pfp_img.png 1x" /> */}
                            <label for="projectImage">
                                <input
                                tabIndex={11}
                                    id="projectImage"
                                    type="file"
                                    name="projectImage"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => profilePicUpload(e)}
                                />
                                {projectPic.length === 0 ? (
                                    <img
                                        id="profile_pfp_img3_project"
                                        width="100"
                                        height="100"
                                        src={
                                            projectImage && projectImage.length
                                                ? "https://drive.google.com/uc?export=view&id=" +
                                                projectImage.split('/')[5]
                                                :
                                                "default_image.png"
                                        }
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                ) : (
                                    <img
                                        id="profile_pfp_img3_project"
                                        width="100"
                                        height="100"
                                        src={projectPic ? projectPic : "default_image.png"}
                                        style={{ borderRadius: "75px", border: "1px solid black" }}
                                    />
                                )}
                            </label>

                        </div>
                        <div id="project_name_grp_prof">
                            <div id="project_name__lbl_pfp">
                                <span>Company Name</span>
                            </div>
                            {/* <input placeholder="Company Name" class="project_name__input_pfp" value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} /> */}
                            <select tabIndex={1} placeholder="Company Name" class="project_name__input_pfp" value={companyId} onChange={(e) => setCompanyId(e.target.value)} >

                                {props.data ? <option value={props.data._id}>{props.data.companyName}</option> : 
                                clientData.map((rec) => (
                                    <option value={rec._id}>{rec.companyName}</option>
                                ))}

                            </select>
                        </div>
                        <div id="project_name__grp_prof">
                            <div id="project_name__lbl_pfp">
                                <span>Project Name</span>
                            </div>
                            <input tabIndex={2} placeholder="Project Name" class="project_name__input_pfp" value={projectName} onChange={(e) => { setProjectName(e.target.value) }} />

                        </div>
                        <div id="project_CPN__skill_grp">
                            <div id="Project_CPN_skill_lbl_pfp">
                                <span>Contact Person Name</span>
                            </div>
                            <input tabIndex={6} placeholder="Contact Person Name" class="project__skill_input_pfp" value={contactPersonName} onChange={(e) => { setContactPersonName(e.target.value) }} />


                        </div>
                        <div id="project_Info_exp_grp">
                            <div id="project__exp_lbl_pfp">
                                <span>Project Information</span>
                            </div>
                            <textarea tabIndex={3} placeholder="Project Information" class="project__exp_input_pfp" value={projectInfo} onChange={(e) => { setProjectInfo(e.target.value) }} />

                        </div>
                        <div id="CPD_project_grp">
                            <div id="project_pup_op_staff_lbl">
                                <span>Contact Person Designation</span>
                            </div>
                            <input tabIndex={7} placeholder="Contact Person Designation" class="project__pup_op_staff_input" value={contactPersonDesignation} onChange={(e) => { setContactPersonDesignation(e.target.value) }} />


                        </div>
                        <div id="project_active_grp_">
                            <div id="active_lbl_project">
                                <span>Active</span>
                            </div>
                            <Switch {...label} tabIndex={12} size="small" className='switch_project' checked={active} onChange={(e) => setActive(e.target.checked)} />


                        </div>
			{/* {user && user.useraccess  >= 3 && user.usertype === "staff" ? */}

                        <button id="Add_pup_op_project__btn_grp" tabIndex={9} onClick={() => { props.data ? updateProject() : addProject() }}>
                            <svg class="addd_bdr_btn_project_">

                            </svg>
                            <div id="Add_pup_opproject__lbl">
                                <span   >{props.data ? "Save" : "Add"}</span>
                            </div>
                        </button>
                        {/* // :""} */}
                        <div id="project__mob_grp">
                            <div id="project_mob_lbl_pup_op">
                                <span>Start Date</span>
                            </div>
                            <input tabIndex={4} placeholder="Start Date" type="date" class="project__mob_pup_op_staff" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />

                        </div>
                        <img id="close_pup_op_project_" tabIndex={10} src="close_pup_op_project_.png" srcset="close_pup_op_project_.png 1x" onClick={() => {props.closePopup3(false);clearData()}} />

                        <div id="end_date_grp">
                            <div id="project__mob_lbl_pup_op_staff">
                                <span>End Date</span>
                            </div>
                            <input tabIndex={5} placeholder="End Date" type="date" class="project_mob_pup_op_staff" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />

                        </div>
                        <div id="CPM_project_grp">
                            <div id="remark_pup_op_project__lbl">
                                <span>Contact Person Mobile</span>
                            </div>
                            <input tabIndex={8} placeholder="Contact Person Mobile" type='number' class="remark_pup_opproject__input" value={contactPersonMobile} onChange={(e) => { setContactPersonMobile(e.target.value) }} />


                        </div>
                    </div >

                </div >
            </Modal>
        </div>
    )
}

export default ProjectPopup