import React from 'react'
import "../../Styles/PC/loader.css"

const Loader = () => {
  return (
    <div className="loader">

    </div>
  )
}

export default Loader