import React, { useState, useEffect, useContext } from 'react'
import "../../Styles/PC/popupTask.css"
import Modal from '@mui/material/Modal';
// import Switch from '@mui/material/Switch';
import { UserContext } from '../UserContext';



const PopUpTask = (props) => {

    const [project, setProject] = useState("");
    const [module, setModule] = useState("")
    const [task, setTask] = useState("")
    const [category, setCategory] = useState("")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("Unassigned")
    const [plannedHours, setPlannedHours] = useState(0)
    const [assignedToName, setAssignedToName] = useState()
    const [categoryId, setCategoryId] = useState("")
    const [moduleId, setModuleId] = useState("")
    const [staffUserId, setStaffUserId] = useState("")


    const [taskId, setTaskId] = useState("")

    const [categoryData, setCategoryData] = useState([])
    const [staffData, setStaffData] = useState([])


    const [moduleData, setModuleData] = useState([])

    const { user, setUser } = useContext(UserContext);


    // useEffect(() => {

    // }, [props.display, props.project])


    // useEffect(() => {
    //     if (props.project ) {
    //       GetStaff();
    //     }
    //     // console.log(props.display)

    //   }, [props.project._id]);

    //   useEffect(() => {
    //     if (props.project._id) {
    //       GetCategory();
    //       GetModule();
    //     }

    //   }, [props.project._id]);

    useEffect(() => {
        if (props.display === "Add") {

            setCategoryId("")
            setModuleId("")
            GetCategory();
            GetModule();
            setStatus(user && user.useraccess <= 3 && user.usertype === "staff" ? "Assigned" : "Unassigned")
            setStaffUserId(user && user.useraccess <= 3 && user.usertype === "staff" ? user._id : "")

        }
        // console.log(categoryId)
    }, [props.display, props.project._id])

    useEffect(() => {
        // console.log(props)
    }, [props.teamData])
    useEffect(() => {
        if (props.display === "Edit") {
            // console.log(props)
            setTaskId(props.data ? props.data._id : "")
            setModule(props.data ? props.data.module : "")
            setModuleId(props.data ? props.data.moduleId : "")
            setTask(props.data ? props.data.task : "")
            setCategory(props.data ? props.data.category : "")
            setCategoryId(props.data ? props.data.categoryId : "")
            setDescription(props.data ? props.data.description : "")
            setStatus(props.data ? props.data.status : user && user.useraccess <= 3 && user.usertype === "staff" ? "Assigned" : "Unassigned")
            setPlannedHours(props.data ? props.data.plannedHours : 0)
            setStaffUserId(props.data ? props.data.assignedToId : user && user.useraccess <= 3 && user.usertype === "staff" ? user._id : "")
        }


    }, [props.display])

    const addTask = () => {
        if (props.project._id.length === 0 || props.project._id === "") {
            return alert("Please Select Project!!")
        }
        if (moduleId.length === 0) {
            return alert("Please Enter Module!!")
        }
        if (task.length === 0 || task.length === "") {
            return alert("Please Enter  Task!!")
        }
        if (categoryId.length === 0) {
            return alert("Please Select Category!!")
        }
        if (status === 0) {
            return alert("Please Select Status!!")
        }
        if (plannedHours === 0 || plannedHours === "") {
            return alert("Please Select Planned Hours!!")
        }


        let apiURL = process.env.REACT_APP_API_URL + "/task/addTask";
        let apiParams = {
            method: "POST",
            headers: {
                projectid: props.project._id,
                project: props.project.projectName,
                task: task,
                moduleid: moduleId,
                module: moduleData.filter((rec) => rec._id === moduleId)[0].module,
                categoryid: categoryId,
                category: categoryData.filter((rec) => rec._id === categoryId)[0].category,
                description: description,
                status: status,
                plannedhours: plannedHours,
                assignedtoid: staffUserId || user && user._id,
                // assignedtoname: staffUserId === "" ? "" : staffData.filter((rec) => rec.staffUserId === staffUserId)[0].staffName,
                assignedtoname: (staffUserId === "" ? "" : (props.teamData || [0]).filter((rec) => rec.staffUserId === staffUserId)[0].staffName) || (user && user._id && user && user.name),

            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopup()
                    resetForm()
                    alert("Task Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetCategory = () => {
        // console.log(props.project._id)
        let apiURL = process.env.REACT_APP_API_URL + "/category/getCategories/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setCategoryData(repos.data)
                    if (repos.data[0]) {
                        setCategoryId(repos.data[0]._id)
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetModule = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/modules/getModules/" + props.project._id;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setModuleData(repos.data)
                    if (repos.data[0]) {
                        setModuleId(repos.data[0]._id)
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    // const GetStaff = () => {
    //     let apiURL = process.env.REACT_APP_API_URL + "/staff/getStaff";
    //     let apiParams = {
    //         method: "GET",

    //     };

    //     fetch(apiURL, apiParams)
    //         .then((res) => (res.status === 200 ? res.json() : res.text()))
    //         .then((repos) => {
    //             if (repos.data) {

    //                 setStaffData(repos.data)

    //             } else alert(repos.message || repos);
    //         })
    //         .catch((err) => alert(err));

    // }

    const updateTask = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/task/editTask";
        // console.log(staffData)
        let apiParams = {
            method: "POST",
            headers: {
                taskid: props.data._id,
                projectid: props.project._id,
                project: props.project.projectName,
                task: task,
                moduleid: moduleId,
                module: moduleData.filter((rec) => rec._id === moduleId)[0].module,
                categoryid: categoryId,
                category: categoryData.filter((rec) => rec._id === categoryId)[0].category,
                description: description,
                status: status,
                plannedhours: plannedHours,
                assignedtoid: staffUserId,
                assignedtoname: staffUserId === "" ? "" : (props.teamData || [0]).filter((rec) => rec.staffUserId === staffUserId)[0].staffName,

            },
        };
        // alert(JSON.stringify(apiParams))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopup()
                    resetForm()
                    alert("Task Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const resetForm = () => {
        setTaskId("")
        setModuleId("")
        setModule("")
        setTask("")
        setCategoryId("")
        setCategory("")
        setDescription("")
        setStatus(user && user.useraccess <= 3 && user.usertype === "staff" ? "Assigned" : "Unassigned")
        setPlannedHours(0)
        setStaffUserId(user && user.useraccess <= 3 && user.usertype === "staff" ? user._id : "")
    }

    return (
        <div style={{ textAlign: "center" }}>
            <Modal

                open={props.display === "Add" || props.display === "Edit"}
                onClose={() => props.closePopup(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflowY: "scroll" }}

            >
                <div id="pup_up_task_page" >
                    <svg class="over_all_bg_add_task_pop_up">
                        <rect id="over_all_bg_add_task_pop_up" rx="15" ry="15" x="0" y="0" width="361" height="823">
                        </rect>
                    </svg>
                    <svg class="top_bg_add_task_pop_up_bu">
                        <linearGradient id="top_bg_add_task_pop_up_bu" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                            <stop offset="0" stop-color="#5ee278" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="top_bg_add_task_pop_up_bu" rx="15" ry="15" x="0" y="0" width="361" height="94">
                        </rect>
                    </svg>
                    <button id="add_add_task_pop_up">
                        <svg class="addd_bdr_btn_add_task_pop_up">
                            <rect id="addd_bdr_btn_add_task_pop_up" rx="8" ry="8" x="0" y="0" width="123" height="41">
                            </rect>
                        </svg>
                        <div id="Add_add_task_pop_up_lbl">
                            <span tabIndex={9} onClick={() => { props.display === "Edit" ? updateTask() : addTask() }}>{props.display}</span>
                        </div>
                    </button>
                    <img id="close_pup_add_task_pop_up" tabIndex={10} src="close_pup_add_task_pop_up.png" srcset="close_pup_add_task_pop_up.png 1x" onClick={() => { props.closePopup(); resetForm() }} />

                    <div id="task_grp_add_task_pop_up">
                        <div id="task_lbl_add_task_pop_up">
                            <span>Task</span>
                        </div>
                        <input placeholder="Task" tabIndex={3} class="task_input_add_task_pop_up" value={task} onChange={(e) => { setTask(e.target.value) }} />

                    </div>
                    <div id="description_grp_add_task_pop_u">
                        <div id="description_lbl_add_task_pop_u">
                            <span>Description</span>
                        </div>
                        <textarea tabIndex={5} placeholder="Description" class="description_input_add_task_pop" value={description} onChange={(e) => { setDescription(e.target.value) }} />

                    </div>


                    <div>
                        <div id="category_grp_add_task_pop_up">
                            <div id="category_lbl_add_task_pop_up">
                                <span>Category</span>
                            </div>
                            <select tabIndex={4} placeholder="GST No." class="category_input_add_task_pop_up" value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                                {/* <rect id="category_input_add_task_pop_up" rx="0" ry="0" x="0" y="0" width="321" height="40">
                                </rect> */}
                                <option value=""> --Category--</option>

                                {categoryData.map((rec) => (
                                    <option value={rec._id}>{rec.category}</option>
                                )

                                )}
                            </select>
                        </div>
                        <div id="module_grp_add_task_pop_up">
                            <div id="module_lbl_add_task_pop_up">
                                <span>Module</span>
                            </div>
                            <select tabIndex={2} placeholder="Module." class="module_input_add_task_pop_up" value={moduleId} onChange={(e) => setModuleId(e.target.value)}>
                                {/* <rect id="category_input_add_task_pop_up" rx="0" ry="0" x="0" y="0" width="321" height="40">
                                </rect> */}
                                <option value=""> --Module--</option>

                                {moduleData.map((rec) => (
                                    <option value={rec._id}>{rec.module}</option>
                                ))}
                            </select>

                        </div>
                    </div>

                    <div id="planned_hrs_lbl_add_task_pop_u">
                        <div id="planned_lbll_add_task_pop_up">
                            <span>Planned Hours</span>
                        </div>
                        <input tabIndex={7} placeholder="Planned Hours" type='number' class="planned_input_add_task_pop_up" value={plannedHours} onChange={(e) => { setPlannedHours(e.target.value) }} />

                        {/* <select placeholder="GST No." class="planned_input_add_task_pop_up" value={plannedHours} onChange={(e) => { setPlannedHours(e.target.value) }}>
                            <rect id="planned_input_add_task_pop_up" rx="0" ry="0" x="0" y="0" width="110.507" height="40">
                            </rect>
                        </select> */}
                    </div>
                    <div id="status_add_task_grp">
                        <div id="status_lbl_add_task_pop_up">
                            <span>Status</span>
                        </div>
                        {/* id="status_input_add_task_pop_up" rx="0" ry="0" x="0" y="0" width="204" height="40" */}
                        <select tabIndex={6} class="status_input_add_task_pop_up" value={status} onChange={(e) => { setStatus(e.target.value) }} >
                            <option value=""> --Status--</option>
                            {user && user.useraccess < 3 && user.usertype === "staff" ? <>
                                <option value="Assigned">Assigned</option>

                            </>
                                :
                                <>
                                    <option value="Unassigned">Unassigned</option>
                                    <option value="Assigned">Assigned</option>
                                    <option value="Work in Progress">Work in Progress</option>
                                    <option value="On Hold">On Hold</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Cancelled">Cancelled</option>
                                </>
                            }

                        </select>
                    </div>
                    <div id="project_input_lbl_grp">
                        <div id="project_lbl_add_task_pop_up">
                            <span>Project</span>
                        </div>
                        <select tabIndex={1} placeholder="Company Name" class="proeject_input_add_task_pop_up" value={project} onChange={(e) => { setProject(e.target.value) }}>
                            <rect id="proeject_input_add_task_pop_up" rx="0" ry="0" x="0" y="0" width="321" height="40">
                            </rect>


                            <option value={props.project && props.project._id || ""}>{props.project && props.project.projectName || ""}</option>
                        </select>
                    </div>
                    <div >
                        <div id="assigned_to_lbl_add_task_pop_u">
                            <span>Assigned To</span>
                        </div>
                        {/* <input class="assigned_to_input_add_task_pop" value={assignedTo} onChange={(e) => { setAssignedTo(e.target.value) }} /> */}
                        <select tabIndex={8} placeholder="Company Name" class="assigned_to_input_add_task_pop" value={staffUserId} onChange={(e) => setStaffUserId(e.target.value)}>
                            <rect id="assigned_to_input_add_task_pop" rx="0" ry="0" x="0" y="0" width="321" height="40">
                            </rect>
                            {/* {console.log(JSON.stringify(user._id && user.name))} */}
                            {(user && parseInt(user.useraccess) >= 3 && user.usertype === "staff") || (user && user.usertype === "client") ?
                                <>
                                    <option value="">Unassigned</option>
                                    {(props.teamData || []).map((rec) => (
                                        <option value={rec.staffUserId}>{rec.staffName}</option>
                                    ))}

                                </> :
                                <option value={user && user._id}>{user && user.name}</option>}

                        </select>
                    </div>
                    <div id="add_task_lbl_add_task_pop_up">
                        <span>{props.display} Task</span>
                    </div>
                    <div id="upload_lbl_add_task_pop_up">
                        <span>Upload</span>
                    </div>
                    <div id="Icon_feather-download">
                        <svg class="arrow_bottom" viewBox="4.5 22.5 23.941 9">
                            <path id="arrow_bottom" d="M 28.44140625 22.5 L 28.44140625 28.5 C 28.44140625 30.1568546295166 27.25041389465332 31.5 25.78125 31.5 L 7.16015625 31.5 C 5.69099235534668 31.5 4.5 30.1568546295166 4.5 28.5 L 4.5 22.5">
                            </path>
                        </svg>
                        <svg class="arrow_angle" viewBox="10.5 15 15 7.5">
                            <path id="arrow_angle" d="M 10.5 15 L 18 22.5 L 25.5 15">
                            </path>
                        </svg>
                        <svg class="arrow_line" viewBox="18 4.5 3 18">
                            <path id="arrow_line" d="M 18 22.5 L 18 4.5">
                            </path>
                        </svg>
                    </div>
                    <div id="download_invoice_add_task_pop_">
                        <span>Download </span>
                    </div>
                    <div id="Icon_feather-upload">
                        <svg class="invoice_down_" viewBox="4.5 22.5 23.94 9">
                            <path id="invoice_down_" d="M 28.44000434875488 22.5 L 28.44000434875488 28.5 C 28.44000434875488 30.1568546295166 27.24908065795898 31.5 25.78000259399414 31.5 L 7.159999847412109 31.5 C 5.690921783447266 31.5 4.5 30.1568546295166 4.5 28.5 L 4.5 22.5">
                            </path>
                        </svg>
                        <svg class="invoice_arrow_diagnal" viewBox="10.5 4.5 15 7.5">
                            <path id="invoice_arrow_diagnal" d="M 25.50000381469727 12 L 18.00000190734863 4.5 L 10.5 12">
                            </path>
                        </svg>
                        <svg class="invoice_line_arrow" viewBox="18 4.5 3 18">
                            <path id="invoice_line_arrow" d="M 18.00002288818359 4.5 L 18.00002288818359 22.5">
                            </path>
                        </svg>
                    </div>
                    <select placeholder="GST No." class="download_drop_down__add_task_p">
                        <rect id="download_drop_down__add_task_p" rx="0" ry="0" x="0" y="0" width="120.9" height="40">
                        </rect>
                    </select>

                    <div id="billed_hours_lbl_add_task_pop_">
                        <span>Billed Hours</span>
                    </div>
                    <div id="n_0_hours_lbl_add_task_pop_up">
                        <span>{props.data && props.data.billedHours} Hours</span>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default PopUpTask