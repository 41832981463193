import React, { useEffect, useState,useContext } from 'react'
import "../../Styles/PC/team.css"
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import { UserContext } from '../UserContext';


const label = { inputProps: { 'aria-label': 'Size switch demo' } };


const Team = (props) => {

    const [staffUserId, setStaffUserId] = useState("")
    const [role, setRole] = useState("")
    const [startDate, setStartDate] = useState((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0'))
    const [endDate, setEndDate] = useState((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0'))
    const [allocation, setAllocation] = useState(100)
    const [billingUnit, setBillingUnit] = useState("")
    const [unitCost, setUnitCost] = useState("")
    const [active, setActive] = useState(true)
    const [staffName, setStaffName] = useState("")
    const [teamId, setTeamId] = useState("")

	const { user, setUser } = useContext(UserContext);


    useEffect(() => {

        // console.log(props.selectedStaff)
        if (props.display === "Edit") {
            setTeamId(props.selectedStaff ? props.selectedStaff._id : "")
            setStaffUserId(props.selectedStaff? props.selectedStaff.staffUserId: "")
            setStaffName(props.selectedStaff ? props.selectedStaff.staffName : "")
            setRole(props.selectedStaff ? props.selectedStaff.role : "")
            setAllocation(props.selectedStaff ? props.selectedStaff.allocation : "")
            setBillingUnit(props.selectedStaff ? props.selectedStaff.billingUnit : "")
            setUnitCost(props.selectedStaff ? props.selectedStaff.unitCost : "")
            setActive(props.selectedStaff ? props.selectedStaff.active : false)
            setStartDate(props.selectedStaff ? new Date(props.selectedStaff.startDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.selectedStaff.startDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.selectedStaff.startDate).getDate() + 1).toString().padStart(2, '0') : "") 
            setEndDate(props.selectedStaff ? new Date(props.selectedStaff.endDate).getFullYear().toString().padStart(4, '0') + "-" + (new Date(props.selectedStaff.endDate).getMonth() + 1).toString().padStart(2, '0') + "-" + (new Date(props.selectedStaff.endDate).getDate()).toString().padStart(2, '0') : "")
        }
        else{
            setTeamId("")
            props.staffData[0] && setStaffUserId(props.staffData[0]._id)
            setRole( "")
            setAllocation("")
            setBillingUnit("")
            setUnitCost(0)
            setActive(true)
            setStartDate((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0')) 
            setEndDate((new Date()).getFullYear() + "-" + parseInt((new Date()).getMonth() + 1).toString().padStart(2, '0') + "-" + parseInt((new Date()).getDate()).toString().padStart(2, '0'))
        }

    }, [props.display])
   

    useEffect(() => {
        if(user){
        props.staffData[0] && setStaffUserId(props.staffData[0].staffUserId)
        // console.log(props.selectedStaff)

        // console.log(props.project)
        }
        // console.log(props.staffData)
    }, [user,props.project])

    
  

    const addTeam = () => {
        // console.log(props.staffData)
        let apiURL = process.env.REACT_APP_API_URL + "/team/addTeam";
        let apiParams = {
            method: "POST",
            headers: {
                projectid: props.project._id,
                projectname: props.project.projectName,
                staffuserid: staffUserId,
                staffname: props.staffData.filter(rw => rw._id === staffUserId)[0].name,
                profilepicurl: props.staffData.filter(rw => rw._id === staffUserId)[0].profilePicUrl,
                role: role,
                allocation: allocation,
                billingunit: billingUnit,
                unitcost: unitCost,
                active: active,
                startdate: startDate,
                enddate: endDate,

            },
        };
        // alert(JSON.stringify((apiParams)))
        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.message) {
                    props.closePopupTeam(false)
                    alert("Team Details Added Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const updateTeam = () => {
        // console.log(props.staffData)

        let apiURL = process.env.REACT_APP_API_URL + "/team/editTeam";
        let apiParams = {
            method: "POST",
            headers: {
                teamid:teamId,
                role: role,
                allocation: allocation,
                billingunit: billingUnit,
                unitcost: unitCost,
                active: active,
                startdate: startDate,
                enddate: endDate,
                // profilepicurl: props.staffData.filter(rw => rw._id === staffUserId)[0].profilePicUrl
            },
        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {
                    props.closePopupTeam(false)
                    alert("Team Details Updated Successfully!!");


                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }


    return (
        <div>
            <Modal

                open={props.display !== "none" }
                onCloseTeam={() => props.closePopupTeam(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ overflowY: "scroll" }}

            >
                <div id="pup_up_task_page_team">
                    <svg class="over_all_bg_add_add_team_bg">
                        <rect id="over_all_bg_add_add_team_bg" rx="15" ry="15" x="0" y="0" width="351" height="467">
                        </rect>
                    </svg>
                    <svg class="top_bg_add_add_team_up_c">
                        <linearGradient id="top_bg_add_add_team_up_c" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
                            <stop offset="0" stop-color="#5ee278" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="top_bg_add_add_team_up_c" rx="15" ry="15" x="0" y="0" width="351" height="94">
                        </rect>
                    </svg>
                    {/* {console.log(props.selectedStaff)}  */}
                    {(user && user.useraccess >= 5 && user.usertype === "staff") || (user && user.useraccess >= 3 && user.usertype === "staff" && props.teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (props.teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client")?
                    <button id="add_add_add_team" onClick={() => {props.display === "Edit" ? updateTeam() :addTeam()}}>
                        <svg class="addd_bdr_btn_add_add_team">
                            <rect id="addd_bdr_btn_add_add_team" rx="8" ry="8" x="0" y="0" width="123" height="41">
                            </rect>
                        </svg>
                        <div id="Add_add_add_team_lbl">
                            <span tabIndex={8} >{props.display === "Edit" ? "Save":"Add"}</span>
                        </div>
                    </button>
                    :""}
                    <img id="close_pup_add_add_team" tabIndex={9} src="close_pup_add_add_team.png" srcset="close_pup_add_add_team.png 1x" onClick={() =>  props.closePopupTeam(false) } />

                    <div id="allocation_start_grp_add_team">
                        <div id="allocation_start_lbl_add_team">
                            <span>Allocation Start</span>
                        </div>
                        <input tabIndex={3} class="alocation_input_add_team" type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />

                    </div>
                    <div id="unit_price_grp_add_team">
                        <div id="unit_price_add_team_lbl">
                            <span>Unit Price</span>
                        </div>
                        <input tabIndex={7} placeholder="Unit Price" type='number' class="unit_price_input_add_team" value={unitCost} onChange={(e) => setUnitCost(e.target.value)} />

                    </div>
                    <div id="billing_grp_add_team">
                        <div id="billing_unit_add_team">
                            <span>Billing Unit</span>
                        </div>

                        <select tabIndex={6} class="billing_input_add_team" type='number' value={billingUnit} onChange={(e) => setBillingUnit(e.target.value)}>
                            <option value="hour">Hour</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="once">One Time</option>
                          
                        </select>
                    </div>
                    <div id="role_grp_add_team">
                        <div id="role_lbl_add_team">
                            <span>Role</span>
                        </div>
                        <input tabIndex={2} class="role_input_add_team" placeholder='Role' value={role} onChange={(e) => setRole(e.target.value)} />

                    </div>
                    <div id="staff_grp_add_team">
                        <div id="staff_lbl_add_team">
                            <span>Staff</span>
                        </div>
                        <select tabIndex={1} class="staff_inpiut_add_team" value={staffUserId} onChange={(e) => setStaffUserId(e.target.value)}>
                            <rect id="staff_inpiut_add_team" rx="0" ry="0" x="0" y="0" width="321" height="40">
                            </rect>
                            {props.display === "Edit" ?<option value={props.selectedStaff.staffUserId}>{props.selectedStaff.staffName}</option> :props.staffData.map((rec) => (
                                <option value={rec._id}>{rec.name}</option>
                            ))}
                        </select>
                    </div>
                    <div id="add_team_memebr_lbl_add_team">
                        <span>{props.display} Team Member</span>
                    </div>
                    <div id="allocation_ends_grp_add_team">
                        <div id="allocation_lbl_add_team">
                            <span>Allocation End</span>
                        </div>
                        <input tabIndex={4} class="allocation_ends_input_add_team" type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />

                    </div>
                    <div id="allocation_grp_add_team">
                        <div id="allocation_lbl_add_team_dx">
                            <span>Allocation %</span>
                        </div>
                        <input tabIndex={5} class="allocation_input__add_team" placeholder='Percentage' type='number' value={allocation} onChange={(e) => setAllocation(e.target.value)} />

                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Team