import React, { useEffect, useState, useContext } from 'react'
import "../../Styles/PC/clientDashBoard.css"
import Pie from './Pie'
import TaskBarChart from './TaskBarChart'
// import PopupStaff from './PopupStaff'
import ProjectPopup from './ProjectPopup'
import PopUpTask from './PopUpTask'
import Team from './Team'
import { UserContext } from '../UserContext';


const ClientDashBoard = (props) => {

    const [tasksData, setTasksData] = useState([])
    const [projectData, setProjectData] = useState([])
    const [staffData, setStaffData] = useState([])
    const [teamData, setTeamData] = useState([])

    const [display, setDisplay] = useState("none")
    const [selectedStaff, setSelectedStaff] = useState({});

    // const [addTeamPopup, setAddTeamPopup] = useState(false)
    const [addProjectPopUp, setAddProjectPopUp] = useState(false)
    // const [addTaskPopup, setAddTaskPopup] = useState(false)

    const [showUnassigned, setShowUnassigned] = useState(true)
    const [showAssigned, setShowAssigned] = useState(true)
    const [showWip, setShowWip] = useState(true)
    const [showOnHold, setShowOnHold] = useState(true)
    const [showCompleted, setShowCompleted] = useState(true)
    const [showCancelled, setShowCancelled] = useState(true)
    const [visibleTasks, setVisibleTasks] = useState([])
    const [selectedProject, setSelectedProject] = useState({})

    const [displayTask, setDisplayTask] = useState("none")

    const { user, setUser } = useContext(UserContext);


    useEffect(() => {
        if (user) {
            GetProject()
        }
    }, [user, addProjectPopUp])

    useEffect(() => {
        if (selectedProject._id) {

            GetStaff(selectedProject._id)
        }
    }, [display])

    useEffect(() => {
        if (selectedProject._id && user) {

            GetTasks(selectedProject._id)
        }
    }, [user, displayTask])

    useEffect(() => {
        var tasks = tasksData;
        if (showUnassigned !== true) {
            tasks = tasks.filter(rw => rw.status !== "Unassigned")
        }
        if (showAssigned !== true) {
            tasks = tasks.filter(rw => rw.status !== "Assigned")
        }
        if (showWip !== true) {
            tasks = tasks.filter(rw => rw.status !== "Work in Progress")
        }
        if (showOnHold !== true) {
            tasks = tasks.filter(rw => rw.status !== "On Hold")
        }
        if (showCompleted !== true) {
            tasks = tasks.filter(rw => rw.status !== "Completed")
        }
        if (showCancelled !== true) {
            tasks = tasks.filter(rw => rw.status !== "Cancelled")
        }
        setVisibleTasks(tasks.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
    }, [showUnassigned, showAssigned, showWip, showOnHold, showCompleted, showCancelled, tasksData])

    useEffect(() => {
        if (selectedProject._id) {

            GetTeam(selectedProject._id)
        }
    }, [display, selectedProject._id])

    const GetProject = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/project" + ((user && user.usertype === "staff" && user.useraccess >= 3) || (user && user.usertype === "staff" && parseInt(user.useraccess) === 2) ? "/getProject" : (user && user.usertype === "client") ? "/getProjectByClient/" + user._id : "/getProjectByUser/" + user._id);
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setProjectData(repos.data)


                    if (repos.data.length > 0) {
                        // console.log(repos.data)
                        GetTasks(repos.data[0]._id)
                        setSelectedProject(repos.data[0])
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetTasks = (projectId) => {
        // console.log(projectId,"Tasks")
        let apiURL = process.env.REACT_APP_API_URL + "/task/getTasks/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setTasksData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    const GetStaff = (projectId) => {
        // console.log(selectedProject._id,projectId,"Staff")
        let apiURL = process.env.REACT_APP_API_URL + "/users/getStaffUsers/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setStaffData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    const GetTeam = (projectId) => {
        // console.log(projectId,"Team")

        let apiURL = process.env.REACT_APP_API_URL + "/team/GetTeam/" + projectId;
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setTeamData(repos.data)
                    if (repos.data) {
                        setStaffData(repos.data)
                    }

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }
    
    const max = Math.max(...tasksData.map((rec, rew) => {
        return (
            rec.plannedHours > rew.billedHours ? rec : rew
        )
    }))
    // console.log(max)


    const maxBilled = Math.max(...tasksData.map((rec) =>
        rec.billedHours))
    // console.log(maxBilled)

    const maxPlanned2 = Math.max(...tasksData.map((rec) =>
        rec.plannedHours))
    // console.log(maxPlanned2)

    const maxHours = maxBilled > maxPlanned2 ? maxBilled : maxPlanned2
    // console.log(maxHours)
    // const max ={tasksData.reduce((prev, current) => (prev.plannedHours > current.billedHours) ? prev : current)}
    // console.log(max)
    return (
        <div id="client_dashboard">
            <img id="over_all_bg_project_page_clien" src="over_all_bg_project_page_clien.png" srcset="over_all_bg_project_page_clien.png 1x" />

            {/* <img id="pie_chart_client_dashboard" src="pie_chart_client_dashboard.png" srcset="pie_chart_client_dashboard.png 1x" /> */}
            <div id="pie_chart_client_dashboard">
                <Pie data={[
                    { title: 'Unassigned', value: tasksData.filter(i => i.status === "Unassigned").length, color: '#999999', },
                    { title: 'Assigned', value: tasksData.filter(i => i.status === "Assigned").length, color: '#FFCC00' },
                    { title: 'Work In Progress', value: tasksData.filter(i => i.status === "Work in Progress").length, color: '#3399FF' },
                    { title: 'On Hold', value: tasksData.filter(i => i.status === "On Hold").length, color: '#FF9900' },
                    { title: 'Completed', value: tasksData.filter(i => i.status === "Completed").length, color: '#00CC66' },
                    { title: 'Cancelled', value: tasksData.filter(i => i.status === "Cancelled").length, color: '#FF0000' },



                ]} />
            </div>
            <div id="bar_chart_grp_client_dashboard">
                {/* <svg class="bar_chart_behind_bdr">
                    <rect id="bar_chart_behind_bdr" rx="10" ry="10" x="0" y="0" width="784" height="244">
                    </rect>
                </svg> */}

                {visibleTasks.filter(row => (row.task.toLowerCase().includes(props.searchText.toLowerCase()) || row.module.toLowerCase().includes(props.searchText.toLowerCase()) || row.category.toLowerCase().includes(props.searchText.toLowerCase()) || row.assignedToName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                    <TaskBarChart data={rec} maxHours={maxHours} project={selectedProject} teamData={teamData} refresh={()=>GetTasks(selectedProject._id)}/>

                ))}

               

                
                {(user && user.useraccess >= 5 && user.usertype === "staff") || (user && user.useraccess >= 3 && user.usertype === "staff" && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client") ?

                    <button onClick={() => setDisplayTask("Add")} style={{ cursor: "pointer" }}>
                        <svg class="add_task_bar_graph">
                            <rect id="add_task_bar_graph" rx="3" ry="3" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="task_add_chat_bar" viewBox="6.75 6.75 15 15">
                            <path id="task_add_chat_bar" d="M 21.75 15.24998474121094 L 15.24998474121094 15.24998474121094 L 15.24998474121094 21.75 L 13.25001621246338 21.75 L 13.25001621246338 15.24998474121094 L 6.75 15.24998474121094 L 6.75 13.25001621246338 L 13.25001525878906 13.25001621246338 L 13.25001525878906 6.75 L 15.24998474121094 6.75 L 15.24998474121094 13.25001525878906 L 21.75 13.25001525878906 L 21.75 15.24998474121094 Z">
                            </path>
                        </svg>
                    </button>
                    : ""}
                {/* {console.log(teamData)} */}
                <PopUpTask display={displayTask} closePopup={() => setDisplayTask("none")} project={selectedProject} teamData={teamData} />
                <div id="Tasks_lbl_chart_bar">
                    <span>Tasks</span>
                </div>

            </div>
            <div id="status_grp_eye_pc">
                    <div id="unasigned_grp_pc">
                        <div id="Unassigned_lbl_client_dashboar">
                            <span>Unassigned {tasksData.filter(rw => rw.status === "Unassigned").length}</span>
                        </div>
                        <svg class="unassigned_grey">
                            <rect id="unassigned_grey" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_unassigned" src={showUnassigned === true ? "eye_unassigned.png" : "eye_unassigned_hidden.png"} onClick={() => setShowUnassigned(!showUnassigned)}  />

                    </div>
                    <div id="assigned_grp_pc">
                        <div id="Assigned_lbl_client_dashboard">
                            <span>Assigned {tasksData.filter(rw => rw.status === "Assigned").length}</span>
                        </div>
                        <svg class="yellow_assigned_client_dashboa">
                            <rect id="yellow_assigned_client_dashboa" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_assigned" src={showAssigned === true ? "eye_assigned.png" : "eye_assigned_hidden.png"} onClick={() => setShowAssigned(!showAssigned)} />
                    </div>
                    <div id="work_in_progress_grp_pc">
                        <div id="Work_in_progress_client_dashbo">
                            <span>Work in progress {tasksData.filter(rw => rw.status === "Work in Progress").length}</span>
                        </div>
                        <svg class="blue_work_in_progress">
                            <rect id="blue_work_in_progress" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_work_in_progress" src={showWip === true ? "eye_work_in_progress.png" : "eye_work_in_progress_hidden.png"} onClick={() => setShowWip(!showWip)} />

                    </div>
                    <div id="on_hold_grp_pc">
                        <div id="On_Hold_lbl_client_dashboard">
                            <span>On Hold {tasksData.filter(rw => rw.status === "On Hold").length}</span>
                        </div>
                        <svg class="orange_on_hold">
                            <rect id="orange_on_hold" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_on_hold" src={showOnHold === true ? "eye_on_hold.png" : "eye_on_hold_hidden.png"} onClick={() => setShowOnHold(!showOnHold)} />

                    </div>
                    <div id="completed_grp_pc">
                        <div id="Completed_lbl_client_dashboard">
                            <span>Completed {tasksData.filter(rw => rw.status === "Completed").length}</span>
                        </div>
                        <svg class="completed_greeen">
                            <rect id="completed_greeen" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_completed" src={showCompleted === true ? "eye_completed.png" : "eye_completed_hidden.png"} onClick={() => setShowCompleted(!showCompleted)} />

                    </div>
                    <div id="cancelled_grp_pc">
                        <div id="Cancelled_lbl_client_dashboard">
                            <span>Cancelled {tasksData.filter(rw => rw.status === "Cancelled").length}</span>
                        </div>
                        <svg class="red_cancelled_client_dashboard">
                            <rect id="red_cancelled_client_dashboard" rx="0" ry="0" x="0" y="0" width="15" height="15">
                            </rect>
                        </svg>
                        <img id="eye_cancelled" src={showCancelled === true ? "eye_cancelled.png" : "eye_cancelled_hidden.png"} onClick={() => setShowCancelled(!showCancelled)} />

                    </div>
                </div>

            <div id="project_place_overall_bdr_grp">
                <svg class="project_left_bdr_over_all" >
                    <rect id="project_left_bdr_over_all" rx="10" ry="10" x="0" y="0" width="307" height="100%">
                    </rect>
                </svg>
                <div id="Projects_lbl_client_dashboard">
                    <span>Projects</span>
                </div>

                <div id="Scroll_project_client_dashboar">
                    {projectData.filter(rw=> (rw.projectName.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec) => (
                        <div style={{ position: "relative", display: "block", backgroundColor: (rec._id === selectedProject._id ? "darkturquoise" : "white"), borderRadius: "60px" }} onClick={() => { GetTasks(rec._id); setSelectedProject(rec) }}>
                            <div style={{ position: "relative" }} >
                                <svg class="card_bdr_client_dashboard">
                                    <rect id="card_bdr_client_dashboard" rx="26" ry="26" x="0" y="0" width="258" height="52" >
                                    </rect>
                                </svg>
                                <img id="profile_pfp_img_client_dashboa" src={
                                    rec.projectImageUrl && rec.projectImageUrl.length
                                        ? "https://drive.google.com/uc?export=view&id=" +
                                        rec.projectImageUrl.split('/')[5]
                                        :
                                        "default_image.png"
                                }
                                    style={{ borderRadius: "75px", border: "3px solid " + (rec.active === true ? "rgba(119,238,170,1)" : "White") }} />


                                <div id="Project_Name_client_dashboard">
                                    <span>{rec.projectName}</span>
                                </div>
                                <div id="Start_time_projpage_client_das">
                                    <span>{(rec.startDate).toString().slice(0, 10)}</span>
                                </div>
                                <div id="date_proj_page_client_dashboar">
                                    <span>{(rec.endDate).toString().slice(0, 10)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {(user && user.useraccess >= 3 && user.usertype === "staff") || (user && user.usertype === "client") ?

                    <button onClick={() => setAddProjectPopUp(true)} style={{ cursor: "pointer" }}>
                        <svg class="project_add_bdr_">
                            <rect id="project_add_bdr_" rx="3" ry="3" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>

                        <svg class="project_add_path_" viewBox="6.75 6.75 15 15">
                            <path id="project_add_path_" d="M 21.75 15.24998474121094 L 15.24998474121094 15.24998474121094 L 15.24998474121094 21.75 L 13.25001621246338 21.75 L 13.25001621246338 15.24998474121094 L 6.75 15.24998474121094 L 6.75 13.25001621246338 L 13.25001525878906 13.25001621246338 L 13.25001525878906 6.75 L 15.24998474121094 6.75 L 15.24998474121094 13.25001525878906 L 21.75 13.25001525878906 L 21.75 15.24998474121094 Z">
                            </path>
                        </svg>
                    </button>
                    : ""}
                <ProjectPopup isOpen={addProjectPopUp} closePopup3={(value) => setAddProjectPopUp(value)} selectProject={(value) => { setSelectedProject(value); GetTasks(value._id) }} />

            </div>
            <div id="assigned_staff_grp_btn_grp">
                <div id="Assigned_Staff_lbl_client_dash">
                    <span>Team</span>
                </div>
                {(user && user.useraccess >= 5 && user.usertype === "staff") || (user && user.useraccess >= 3 && user.usertype === "staff" && teamData.filter(rw => rw.staffUserId === user._id).length > 0) || (user && user.usertype === "client") ?

                    <button onClick={() => setDisplay("Add")} style={{ cursor: "pointer" }}>
                        <svg class="add_plus_bdr_assigned_staaff">
                            <rect id="add_plus_bdr_assigned_staaff" rx="3" ry="3" x="0" y="0" width="20" height="20">
                            </rect>
                        </svg>
                        <svg class="add_assigned_staff_plus" viewBox="6.75 6.75 15 15">
                            <path id="add_assigned_staff_plus" d="M 21.75 15.24998474121094 L 15.24998474121094 15.24998474121094 L 15.24998474121094 21.75 L 13.25001621246338 21.75 L 13.25001621246338 15.24998474121094 L 6.75 15.24998474121094 L 6.75 13.25001621246338 L 13.25001525878906 13.25001621246338 L 13.25001525878906 6.75 L 15.24998474121094 6.75 L 15.24998474121094 13.25001525878906 L 21.75 13.25001525878906 L 21.75 15.24998474121094 Z">
                            </path>
                        </svg>
                    </button>
                    : ""}
                
                {selectedProject && <Team display={display} selectedStaff={selectedStaff} staffData={staffData} project={selectedProject} closePopupTeam={() => setDisplay("none")} teamData={teamData} />}
            </div>

                       <div id="Scroll_staff_assigned_">
                {teamData.filter(rw=> (rw.staffName.toLowerCase().includes(props.searchText.toLowerCase()) )).map((rec) => (
                    <div id="card_1_assign_staff_grp" onClick={() => { setDisplay("Edit"); setSelectedStaff(rec) }}>
                        <svg class="card_overall_bdr_assign_">
                            <rect id="card_overall_bdr_assign_" rx="10" ry="10" x="0" y="0" width="285" height="84">
                            </rect>
                        </svg>
                        <img id="overall_pfp_bdr_assign_card" src="overall_pfp_bdr_assign_card.png" srcset="overall_pfp_bdr_assign_card.png 1x" />

                        <svg class="pfp_bdr_assign_card">
                            <ellipse id="pfp_bdr_assign_card" rx="37" ry="37" cx="37" cy="37" style={{ fill: rec.active === true ? "rgba(119,238,170,1)" : "White" }}>
                            </ellipse>
                        </svg>
                        <img id="profile_pic_img_assign_card" src={
                            rec.profilePicUrl && rec.profilePicUrl.length > 10
                                ? "https://drive.google.com/uc?export=view&id=" +
                                rec.profilePicUrl.split('/')[5]
                                :
                                "default_image.png"
                        }
                            style={{ borderRadius: "75px", border: "1px solid black" }} />


                        <div id="Mohammed_Affan_lbl_assign_card">
                            <span>{rec.staffName}</span>
                        </div>
                        <svg class="start_end_bdr_assign">
                            <rect id="start_end_bdr_assign" rx="5" ry="5" x="0" y="0" width="174" height="23">
                            </rect>
                        </svg>
                        <div id="start_end_date_assign_card">
                            <span>{rec.startDate.toString().slice(0, 10)}    {rec.endDate.toString().slice(0, 10)}</span>
                        </div>
                        <svg class="orange_bdr_assigned">
                            <rect id="orange_bdr_assigned" rx="0" ry="0" x="0" y="0" width="17" height="17">
                            </rect>
                        </svg>
                        <svg class="blue_bdr_assigned">
                            <rect id="blue_bdr_assigned" rx="0" ry="0" x="0" y="0" width="17" height="17">
                            </rect>
                        </svg>
                        <svg class="yellow_bdr_assigned">
                            <rect id="yellow_bdr_assigned" rx="0" ry="0" x="0" y="0" width="17" height="17">
                            </rect>
                        </svg>
                        <svg class="green_bdr_assigned">
                            <rect id="green_bdr_assigned" rx="0" ry="0" x="0" y="0" width="17" height="17">
                            </rect>
                        </svg>
                        <svg class="red_bdr_assigned">
                            <rect id="red_bdr_assigned" rx="0" ry="0" x="0" y="0" width="17" height="17">
                            </rect>
                        </svg>
                        <div id="yellow_11_assigned">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Assigned").length}</span>

                        </div>
                        <div id="blue_11_assigned">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Work in Progress").length}</span>

                        </div>
                        <div id="orange_11_assigned">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "On Hold").length}</span>

                        </div>
                        <div id="green_11_assigned">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Completed").length}</span>

                        </div>
                        <div id="red_11_assigned">
                            <span>{tasksData.filter(rew => rew.assignedToId === rec.staffUserId && rew.status === "Cancelled").length}</span>

                        </div>
                        <div id="Mohammed_Affan_lbl_assign_card_dn">
                            <span>{rec.role}</span>
                        </div>
                    </div>
                ))}
            </div>



            <div id="Total_Tasks_-_100">
                <span>Total Tasks - </span>
                <span style={{ fontStyle: "normal", fontWeight: "bold" }}>{tasksData.length}</span>
            </div>
        </div>
    )
}

export default ClientDashBoard