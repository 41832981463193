import React, { useState, useEffect,useContext } from 'react'
import "../../Styles/PC/client.css"
import PopupClient from './PopupClient';
import { UserContext } from '../UserContext';



const Client = (props) => {


    const [addClientPopup, setAddClientPopup] = useState(null);

    const[clientPopUP,setClientPopup] = useState(false) ;
    const[clientPopUPData,setClientPopupData] = useState({}) ;

	const { user, setUser } = useContext(UserContext);
    

    // const [client, setClient] = useState(UserContext)
    const [clientData, setClientData] = useState([])

    useEffect(() => {
        // if () {
            GetClient()
        // }
    }, [clientPopUP,addClientPopup ,props.isAddPopupVisible,user])
    // useEffect(()=>{
    //     GetClientStaff()
    // },[])

    const GetClient = () => {
        let apiURL = process.env.REACT_APP_API_URL + "/client" +((user && user.usertype ==="staff" && user.useraccess >=5) || (user && user.usertype ==="staff" && parseInt(user.useraccess) === 2) ?  "/getClient" : (user && user.usertype === "client") ? "/getCompaniesByUser/" +user._id:""  );
        let apiParams = {
            method: "GET",

        };

        fetch(apiURL, apiParams)
            .then((res) => (res.status === 200 ? res.json() : res.text()))
            .then((repos) => {
                if (repos.data) {

                    setClientData(repos.data)

                } else alert(repos.message || repos);
            })
            .catch((err) => alert(err));

    }

    



    return (
        <div>

            <div id="Client_page">
                <img id="over_all_bg_client_page" src="over_all_bg_client_page.png" srcset="over_all_bg_client_page.png 1x" />
            </div>


            <div id="client_scroll">
                {clientData.filter(rw=> (rw.companyName.toLowerCase().includes(props.searchText.toLowerCase()) || rw.companyInfo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.gstNo.toLowerCase().includes(props.searchText.toLowerCase()) || rw.country.toLowerCase().includes(props.searchText.toLowerCase()) ||rw.city.toLowerCase().includes(props.searchText.toLowerCase()) || rw.state.toLowerCase().includes(props.searchText.toLowerCase())  || rw.contactPersonName.toLowerCase().includes(props.searchText.toLowerCase()))).map((rec) => (
                    <div style={{ position: "relative", display: 'inline-flex' }}>

                        <div id="user_card_client_page"  onClick={() => {setClientPopup(true);setClientPopupData(rec)}}>
                            <svg class="card_over_all_bg_client_page">
                                <rect id="card_over_all_bg_client_page" rx="10" ry="10" x="0" y="0" width="174" height="140">
                                </rect>
                            </svg>
                            <img id="card_bg_design_client_page" src="card_bg_design_staff_page.png" srcset="card_bg_design_staff_page.png 1x" />

                            <div id="pfp_grp_client_page">
                                <svg class="pfp_behind_circle_client_page">
                                    <ellipse id="pfp_behind_circle_client_page" rx="22.5" ry="22.5" cx="22.5" cy="22.5"  style={{fill:rec.active===true?"rgba(119,238,170,1)":"White"}}>
                                    </ellipse>
                                </svg>
                                <img id="profile_pic_card_client_page" src={
									rec.profilePicUrl && rec.profilePicUrl.length
										? "https://drive.google.com/uc?export=view&id=" +
										rec.profilePicUrl.split('/')[5]
										:
										"default_image.png"
								} />

                            </div>
                            <img title="Compant Name" id="manager_lbl_client_page" src="manager_lbl_client_page.png" srcset="manager_lbl_client_page.png 1x" />

                            <div id="Admin_lbl_client_page">
                                <span>{rec.city},{rec.country}</span>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                            </div>
                            <div id="Mohammed_Affan_lbl_client_page">
                                <span>{rec.companyName}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <PopupClient isOpen={clientPopUP} data={clientPopUPData} closePopup4={(value) => setClientPopup(value)} />

            <PopupClient isOpen={props.isAddPopupVisible && addClientPopup===null} closePopup4={() => {props.closeAddPopup(false)}} />
         
            {/* <PopupClient isOpen={addClientPopup} closePopup4={(value) => setAddClientPopup(value)} /> */}
        </div>
    )
}

export default Client